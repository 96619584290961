import { useEffect, useState, useMemo } from "react";

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../../Environments';

import { ArrowDown } from "react-feather";

export default function Archive(props) {
  const [fetchedAssets, setFetchedAssets] = useState(false)
  const [assets, setAssets] = useState([])
  const [activeStage, setActiveStage] = useState(props.project.step)
  const [filterQuery, setFilterQuery] = useState('')

  const cookies = useMemo(() => new Cookies(), [])

  const stageNames = [
    'Unverbindlicher Austausch',
    'Informationsaustausch',
    'Due Diligence',
    'Signing & Closing'
  ]

  useEffect(() => {
    async function fetchAssets() {
      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          project: props.project._id
        },
        url: `${Env.url}/assets/search`
      })

      setAssets(response.data)
    }

    if (!fetchedAssets) {
      fetchAssets()
      setFetchedAssets(true)
    }
  }, [filterQuery, cookies, fetchedAssets, props.project._id])

  function updateFilterQuery(newValue) {
    setFilterQuery(newValue)
    setFetchedAssets(false)
  }

  let tableRows = [];
  for (let asset of assets) {
    let fileName = asset.name.slice(0, 25)
    if (fileName.length > 24) fileName += '...'

    let fileSize = (asset.size/1000).toFixed(1)
    if (fileSize > 1000) {
      fileSize = `${(fileSize/1000).toFixed(1)}Mb`
    } else {
      fileSize = `${fileSize}Kb`
    }

    const tableRow = (
      <tr>
        <td>{fileName}</td>
        <td><span className="text-muted">{asset.name.split('.').pop()}</span></td>
        <td><span className="text-muted">{fileSize}</span></td>
        <td>
          <span className={`badge ${asset.canRead.length ? 'badge-success' : ''}`}>
            {asset.canRead.length ? 'Freigegeben' : 'Ausstehend'}
          </span>
        </td>
        <td>{new Date(asset.createdAt).toLocaleDateString('ch-CH')}</td>
        <td>
          <div className="bullet-actions">
            <a href={asset.source} download className="bullet-action button circle secondary"><ArrowDown /></a>
          </div>
        </td>
      </tr>
    )

    const fileVisible = props.user.isAdmin || asset.owner === props.user._id || asset.canRead.includes(props.user._id)
    const fileInPhase = asset.path.endsWith(stageNames[activeStage])
    const filterQueryMatch = filterQuery ? (new RegExp(filterQuery, 'i')).test(asset.name) : true

    let fileInNoPhase = true;
    for (let sn of stageNames) {
      if (asset.path.endsWith(sn)) {
        fileInNoPhase = false
        break
      }
    }

    if (fileVisible && fileInPhase && filterQueryMatch) {
      tableRows.push(tableRow)
    } else if (fileVisible && filterQueryMatch && activeStage === -1 && fileInNoPhase) {
      tableRows.push(tableRow)
    }
  }

  return (
    <div className="Archive fade-in-up">
      <div className="TableHeader">
        <div className="TableControl">
          <div className="toggle-switch">
            <div
              className={`toggle-option ${activeStage === 0 ? 'active' : ''}`}
              onClick={() => setActiveStage(0)}
              >
                Phase 1
              </div>
            <div
              className={`toggle-option ${activeStage === 1 ? 'active' : ''}`}
              onClick={() => setActiveStage(1)}
              >
                Phase 2
              </div>
            <div
              className={`toggle-option ${activeStage === 2 ? 'active' : ''}`}
              onClick={() => setActiveStage(2)}
              >
                Phase 3
              </div>
            <div
              className={`toggle-option ${activeStage === 3 ? 'active' : ''}`}
              onClick={() => setActiveStage(3)}
              >
                Phase 4
              </div>
            <div
              className={`toggle-option ${activeStage === 4 ? 'active' : ''}`}
              onClick={() => setActiveStage(4)}
              >
                Phase 5
            </div>
            <div
              className={`toggle-option ${activeStage === -1 ? 'active' : ''}`}
              onClick={() => setActiveStage(-1)}
              >
                Sonstige
            </div>
          </div>
        </div>
        <div className="TableAction">
          <span className="TableAction-action">
            <input
              type="text"
              className="search"
              placeholder='Dateien filtern ...'
              value={filterQuery}
              onChange={e => updateFilterQuery(e.target.value)}
              />
          </span>
        </div>
      </div>

      <table className="DataTable DataTable-left-aligned">
        <thead>
          <tr>
            <th>Name</th>
            <th>Format</th>
            <th>Größe</th>
            <th>Freigabe</th>
            <th>Upload-Datum</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    </div>
  )
}