import './style.css';

export default function Tooltip(props) {
  return (
    <div className="tooltip-container">
      {props.children}

      <div className="tooltip-popup badge badge-rect">
        {props.text}
      </div>
    </div>
  )
}