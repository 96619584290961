import { useState, useEffect, useMemo } from "react";

import Cookies from 'universal-cookie';

import Makro from '../../Makro';
import MakroSelect from '../../MakroSelect';

export default function BuyerFeed(props) {
  const [showRequestAction, setShowRequestAction] = useState(false)
  const [activeSeller, setActiveSeller] = useState()

  const [actionFeed, setActionFeed] = useState([])

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    if ((props.user.isAdmin && props.buyer) || (props.buyer && props.buyer.user._id.toString() === props.user._id.toString())) {
      setActionFeed([
        <Makro
          iconAction
          label="Unternehmen bearbeiten"
          target={`/settings/buyers/${props.buyer._id}`}
          />,
        <Makro
          iconAction
          label="Projekte verwalten"
          target={`/buyer-projects`}
          />,
        <Makro
          iconAction
          label="Partner verwalten"
          target={`/settings/buyers/${props.buyer._id}#partner-settings`}
          />,
        <Makro
          actionLabel="Quick Search"
          label="Ähnliche Profile finden"
          target={`/search/buyers`}
          />
        // - TODO: browse competition
      ])
    } else if (props.buyer) {
      function updateActiveSeller(newSeller) {
        setActiveSeller(newSeller)
        setShowRequestAction(false)
      }

      let availableSellerProfiles = []
      for (let s of props.user.sellers) {
        // make sure to avoid sellers who already have a project with this buyer
        const commonProjects = s.projects.filter(p => props.buyer.projects.includes(p))

        if (!commonProjects.length) {
          availableSellerProfiles.push({ value: s, label: s.name })
        }
      }

      let projectRequestCard;
      let hasVerifiedSellers = false
      for (let s of props.user.sellers) {
        if (s.verified) {
          hasVerifiedSellers = true
          break
        }
      }

      if (hasVerifiedSellers && availableSellerProfiles.length) {
        projectRequestCard = (
          <MakroSelect
            actionLabel="Profil wählen"
            label="Projektanfrage senden"
            onSelect={v => updateActiveSeller(v)}
            options={availableSellerProfiles}
          />
        )
      }

      setActionFeed([
        <Makro
          iconAction
          label="Finde ähnliche Investoren"
          target='/search/buyers'
        />,
        projectRequestCard
        // - TODO: contact P4i
        // - TODO: claim profile
        // - TODO: report profile
      ])
    }
  }, [ props, cookies, activeSeller, showRequestAction ])

  return actionFeed;
}