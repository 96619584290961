import React, { useState, useEffect, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import CustomPhoneInput from '../../components/CustomPhoneInput';

import './style.css';

export default function Settings(props) {
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [company, setCompany] = useState('')
  const [phone1, setPhone1] = useState('')
  const [phone2, setPhone2] = useState('')

  const cookies = useMemo(() => new Cookies(), [])

  async function patchUser(data) {
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/users/${props.user._id}`
    })
  }

  function universalSetter(attr, attrSetter, value) {
    attrSetter(value)

    let patchData = {}
    patchData[attr] = value

    patchUser(patchData)
  }

  useEffect(() => {
    setFirstName(props.user.firstName)
    setLastName(props.user.lastName)
    setCompany(props.user.company || '')
    setPhone1(props.user.phone1)
    setPhone2(props.user.phone2)
  }, [props.user])

  let conditionalCompanySettings;
  let conditionalAccountDeletion;
  if (!props.user.isAdmin) {
    conditionalCompanySettings = (
      <div className="ActionCard">
        <h3 className="ActionCard-title">Firmenprofile</h3>
        <p className="ActionCard-body">Verwalte deine spezifischen Unternehmens- sowie Investorenprofile.</p>
        <div className="ActionCard-actions">
          <button className="ActionCard-action secondary small" onClick={() => document.location = '/seller-profiles'}>Unternehmen</button>
          <button className="ActionCard-action secondary small" onClick={() => document.location = '/buyer-profiles'}>Investoren</button>
        </div>
      </div>
    )

    conditionalAccountDeletion = (
      <div className="ActionCard">
        <h3 className="ActionCard-title">Account löschen</h3>
        <p className="ActionCard-body">Sende uns eine E-Mail und wir veranlassen die Löschung all deiner Daten.</p>
        <div className="ActionCard-actions">
          <a className="ActionCard-action button danger small" href="mailto:support@p4i-partners.com">Löschung beantragen</a>
        </div>
      </div>
    )
  }

  return (
    <div className="Settings">
      <Aside darkMode={props.darkMode} user={props.user} scope="settings" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title"><strong>Account</strong> Einstellungen</h1>
              <h5 className="PanelHeader-subline">Details, Rechnungsinformationen & mehr</h5>
            </div>

            <div className="Settings-grid">
              <div className="Settings-grid-card Settings-grid-card-primary">
                <h3 className="Settings-grid-card-title">Kontaktinformationen</h3>
                <p className="Settings-grid-card-body">Hinterlege Kontaktinformationen, über die wir dich kontaktieren können. Diese Informationen werden streng vertraulich behandelt.</p>

                <div className="Settings-fields">
                  <div className="form-field">
                    <label>Vorname</label>
                    <input
                      type="text"
                      value={firstName}
                      onChange={e => universalSetter('firstName', setFirstName, e.target.value)}
                      />
                  </div>

                  <div className="form-field">
                    <label>Nachname</label>
                    <input
                      type="text"
                      value={lastName}
                      onChange={e => universalSetter('lastName', setLastName, e.target.value)}
                      />
                  </div>

                  <div className="form-field"></div>

                  <div className="form-field">
                    <label>E-Mail Adresse</label>
                    <input
                      disabled
                      type="text"
                      value={props.user.username}
                      />
                  </div>

                  <div className="form-field">
                    <label>Unternehmen</label>
                    <input
                      type="text"
                      value={company}
                      onChange={e => universalSetter('company', setCompany, e.target.value)}
                      />
                  </div>

                  <div className="form-field"></div>

                  <div className="form-field">
                    <label>Telefon (primär)</label>
                    <CustomPhoneInput
                      country='ch'
                      value={phone1}
                      onChange={v => universalSetter('phone1', setPhone1, v)}
                      />
                  </div>

                  <div className="form-field">
                    <label>Telefon (sekundär)</label>
                    <CustomPhoneInput
                      country='ch'
                      value={phone2}
                      onChange={v => universalSetter('phone2', setPhone2, v)}
                      />
                  </div>

                  <div className="form-field"></div>
                </div>
              </div>

              {conditionalCompanySettings}
              {conditionalAccountDeletion}
            </div>
          </div>
        </div>
      </Aside>
    </div>
  )
}