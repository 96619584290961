import React, { useState, useEffect, useCallback } from 'react';

import Accordion from '../../../components/Accordion';
import CustomCreatable from '../../../components/CustomCreatable';
import CustomSelectable from '../../../components/CustomSelectable';

import optionService from '../../../services/OptionService';

export default function QuickFacts(props) {
  const [score, setScore] = useState(0)
  const [tags, setTags] = useState([])
  const [sectors, setSectors] = useState([])
  const [locations, setLocations] = useState([])
  const [regions, setRegions] = useState([])
  const [foundingYear, setFoundingYear] = useState()
  const [employees, setEmployees] = useState()
  const [investmentStatus, setInvestmentStatus] = useState()
  const [investmentHorizon, setInvestmentHorizon] = useState()
  const [shareModel, setShareModel] = useState()
  const [transactionType, setTransactionType] = useState()
  const [propsInit, setPropsInit] = useState(false)

  const sectorOptions = optionService.sectors
  const countryOptions = optionService.countries
  const regionOptions = optionService.regions

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [tags, sectors, locations, regions, foundingYear, employees, investmentStatus, investmentHorizon, shareModel, transactionType]
    properties.forEach(p => {
      if (p && p !== '') newScore += 100/properties.length
    })

    setScore(Math.round(newScore))
  }, [tags, sectors, locations, regions, foundingYear, employees, investmentStatus, investmentHorizon, shareModel, transactionType])

  useEffect(() => {
    if (props.buyer._id && !propsInit) {
      setTags(props.buyer.tags.map(t => ({ label: t, value: t })))
      setSectors(props.buyer.sectors.map(t => ({ label: t, value: t })))
      setLocations(props.buyer.locations.map(t => ({ label: t, value: t })))
      setRegions(props.buyer.regions.map(t => ({ label: t, value: t })))
      setFoundingYear(props.buyer.foundingYear)
      setEmployees(props.buyer.employees)
      setInvestmentStatus({ label: props.buyer.investmentStatus, value: props.buyer.investmentStatus })
      setInvestmentHorizon({ label: props.buyer.investmentHorizon, value: props.buyer.investmentHorizon })
      setShareModel(props.buyer.shareModel.map(t => ({ label: t, value: t })))
      setTransactionType(props.buyer.transactionType.map(t => ({ label: t, value: t })))

      setPropsInit(true)
    }

    updateScore()
  }, [props.buyer, updateScore, tags.length, propsInit])

  function universalSetter(property, setter, newValue) {
    let updatePayload = {}
    updatePayload[property] = newValue

    props.patchBuyer(updatePayload)

    updateScore()

    setter(newValue)
  }

  function universalSelectSetter(property, setter, newValue) {
    let updatePayload = {}
    updatePayload[property] = newValue.map(v => v.value)

    props.patchBuyer(updatePayload)

    updateScore()

    setter(newValue)
  }

  function universalSelectPicker(property, setter, newValue) {
    let updatePayload = {}
    updatePayload[property] = newValue.value

    props.patchBuyer(updatePayload)

    updateScore()

    setter(newValue)
  }

  return (
    <Accordion
      title="Quickfacts"
      toggleState={props.toggleState}
      body="Bitte gib wichtige Informationen zu deinem Unternehmen an. Diese sensiblen Daten werden von uns absolut diskret behandelt."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
    >
      <div className="Settings-fields Settings-fields-c4">
        <div className="form-field">
          <label>Gründungsjahr</label>
          <input
            type="number"
            value={foundingYear}
            onChange={e => universalSetter('foundingYear', setFoundingYear, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Mitarbeiter</label>
          <input
            type="number"
            value={employees}
            onChange={e => universalSetter('employees', setEmployees, e.target.value)}
            />
        </div>

        <div className="form-field form-field">
          <label>Investitions-Status</label>
          <CustomSelectable
            placeholder='Bitte wählen ...'
            classNamePrefix="react-select"
            options={[
              { label: 'Aktiv', value: 'Aktiv' },
              { label: 'On Hold', value: 'On Hold' },
              { label: 'Inaktiv', value: 'Inaktiv' }
            ]}
            value={investmentStatus}
            onChange={v => universalSelectPicker('investmentStatus', setInvestmentStatus, v)}
            />
        </div>

        <div className="form-field form-field">
          <label>Investmenthorizont</label>
          <CustomSelectable
            placeholder='Bitte wählen ...'
            classNamePrefix="react-select"
            options={[
              { label: 'bis 5 Jahre', value: 'bis 5 Jahre' },
              { label: 'bis 10 Jahre', value: 'bis 10 Jahre' },
              { label: '>10 Jahre', value: '>10 Jahre' },
              { label: 'unbeschränkt', value: 'unbeschränkt' }
            ]}
            value={investmentHorizon}
            onChange={v => universalSelectPicker('investmentHorizon', setInvestmentHorizon, v)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c1">
        <div className="form-field">
          <label>Investmentfokus <span className="text-muted">(eigene Definition möglich)</span></label>
          <CustomCreatable
            isMulti
            placeholder={sectorOptions[0]}
            classNamePrefix="react-select"
            options={sectorOptions.map(o => ({ label: o, value: o }))}
            value={sectors}
            onChange={v => universalSelectSetter('sectors', setSectors, v)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c1">
        <div className="form-field">
          <label>Geographischer Fokus</label>
          <CustomSelectable
            isMulti
            placeholder='Bitte wählen ...'
            classNamePrefix="react-select"
            options={countryOptions.map(o => ({ label: o, value: o }))}
            value={locations}
            onChange={v => universalSelectSetter('locations', setLocations, v)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c1">
        <div className="form-field">
          <label>Regionaler Fokus <span className="text-muted">- optional</span></label>
          <CustomSelectable
            isMulti
            placeholder='Bitte wählen ...'
            classNamePrefix="react-select"
            options={locations.map(c => ({
              label: c.label,
              options: regionOptions[c.label].map(e => ({ label: e, value: e }))
            }))}
            value={regions}
            onChange={v => universalSelectSetter('regions', setRegions, v)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c2">
        <div className="form-field">
          <label>Beteiligungsarten <span className="text-muted">(eigene Definition möglich)</span></label>
          <CustomCreatable
            isMulti
            placeholder='"Minderheitbeteiligung"'
            classNamePrefix="react-select"
            options={[
              { label: 'Minderheitbeteiligung', value: 'Minderheitbeteiligung' },
              { label: 'Mehrheitsbeteiligung', value: 'Mehrheitsbeteiligung' },
              { label: 'Private Debt  / Darlehen', value: 'Private Debt  / Darlehen' },
              { label: 'Mezzanine-Kapital', value: 'Mezzanine-Kapital' },
              { label: 'Kapitalerhöhung', value: 'Kapitalerhöhung' },
              { label: 'Andere Beteiligungsarten', value: 'Andere Beteiligungsarten' }
            ]}
            value={shareModel}
            onChange={v => universalSelectSetter('shareModel', setShareModel, v)}
            />
        </div>

        <div className="form-field">
          <label>Klassifizierung <span className="text-muted">(eigene Definition möglich)</span></label>
          <CustomCreatable
            isMulti
            placeholder='"Private Debt Fund"'
            classNamePrefix="react-select"
            options={[
              { label: 'Strategischer Investor / Unternehmen', value: 'Strategischer Investor / Unternehmen' },
              { label: 'Unabhängige Beteiligungsgesellschaft', value: 'Unabhängige Beteiligungsgesellschaft' },
              { label: 'Privater Investor', value: 'Privater Investor' },
              { label: 'Single/Multi Family Office', value: 'Single/Multi Family Office' },
              { label: 'Öffentliche Hand', value: 'Öffentliche Hand' },
              { label: 'Private Debt Fund', value: 'Private Debt Fund' },
              { label: 'Andere Investoren', value: 'Andere Investoren' }
            ]}
            value={transactionType}
            onChange={v => universalSelectSetter('transactionType', setTransactionType, v)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c1">
        <div className="form-field">
          <label>Tags</label>
          <CustomCreatable
            isMulti
            placeholder='"Cloud Infrastructure"'
            classNamePrefix="react-select"
            noOptionsMessage={() => 'Definiere eigene Tags und bestätige diese mit Enter'}
            value={tags}
            onChange={v => universalSelectSetter('tags', setTags, v)}
            />
          <small className="form-field-info">
          Tags helfen der Speer Technologie, neue und passende Opportunitäten anhand von inhaltlichen Analysen für dich zu identifizieren. Bitte gib hier individuelle "Tags" an, unter welchen du in der Hub-Suche von Unternehmern und Investoren gefunden werden willst.
          </small>
        </div>
      </div>
    </Accordion>
  )
}