import { useState, useEffect, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../../Environments';

import { TrendingUp, TrendingDown, Activity } from 'react-feather';

export default function DashboardStats(props) {
  const [stats, setStats] = useState({})
  const [statsInit, setStatsInit] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function initStats() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/events/stats`
      })

      setStats(response.data)
    }

    if (props.user._id && !statsInit) {
      initStats()
      setStatsInit(true)
    }
  }, [props.user, statsInit, stats, cookies])

  if (Object.values(stats).length) {
    const s0 = stats.profileViews.value
    const c0 = s0 - stats.profileViews.comparison
    const i0 = c0 && c0 > 0 ? <TrendingUp size={10} /> : (c0 === 0 ? <Activity size={10} /> : <TrendingDown size={10} />)

    const s1 = stats.observers.value
    const c1 = s1 - stats.observers.comparison
    const i1 = c1 && c1 > 0 ? <TrendingUp size={10} /> : (c1 === 0 ? <Activity size={10} /> : <TrendingDown size={10} />)

    const s2 = stats.leads.value
    const c2 = s2 - stats.leads.comparison
    const i2 = c2 && c2 > 0 ? <TrendingUp size={10} /> : (c2 === 0 ? <Activity size={10} /> : <TrendingDown size={10} />)

    const s3 = stats.projectRequests.value
    const c3 = s3 - stats.projectRequests.comparison
    const i3 = c3 && c3 > 0 ? <TrendingUp size={10} /> : (c3 === 0 ? <Activity size={10} /> : <TrendingDown size={10} />)

    return (
      <div className="Dashboard-stats">
        <div className="Dashboard-stat fade-in-up">
          <div className="stat-headline">Profilaufrufe</div>
          <div className="stat-value">
            {s0 || 'N/A'}
            <span className={`badge ${(c0 && c0 > 0) ? 'badge-success' : (c0 < 0 ? 'badge-danger' : '')}`}>
              <span>{c0}</span>
              {i0}
            </span>
          </div>
          <div className="stat-subline">letzte 28 Tage</div>
        </div>
  
        <div className="Dashboard-stat fade-in-up delay-100">
          <div className="stat-headline">Beobachter</div>
          <div className="stat-value">
            {s1 || 'N/A'}
            <span className={`badge ${(c1 && c1 > 0) ? 'badge-success' : (c1 < 0 ? 'badge-danger' : '')}`}>
              <span>{c1}</span>
              {i1}
            </span>
          </div>
          <div className="stat-subline">auf allen Accounts</div>
        </div>
  
        <div className="Dashboard-stat fade-in-up delay-200">
          <div className="stat-headline">Potenzielle Leads</div>
          <div className="stat-value">
            {s2 || 'N/A'}
            <span className={`badge ${(c2 && c2 > 0) ? 'badge-success' : (c2 < 0 ? 'badge-danger' : '')}`}>
              <span>{c2}</span>
              {i2}
            </span>
          </div>
          <div className="stat-subline">in allen Speer Scouts™</div>
        </div>
  
        <div className="Dashboard-stat fade-in-up delay-300">
          <div className="stat-headline">Projektanfragen</div>
          <div className="stat-value">
            {s3 || 'N/A'}
            <span className={`badge ${(c3 && c3 > 0) ? 'badge-success' : (c3 < 0 ? 'badge-danger' : '')}`}>
              <span>{c3}</span>
              {i3}
            </span>
          </div>
          <div className="stat-subline">letzte 28 Tage</div>
        </div>
      </div>
    )
  } else {
    return <div className="Dashboard-stats"></div>
  }
}