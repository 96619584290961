import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import faker from 'faker';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import WidgetFeed from '../../components/WidgetFeed';
import FileCard from '../../components/FileCard';

import { ArrowLeft, ChevronDown, ArrowRight } from 'react-feather';

import EventLogger from '../../services/EventLogger';

import './style.css';

export default function BuyerProfile(props) {
  const [buyer, setBuyer] = useState()
  const [fetchedBuyer, setFetchedBuyer] = useState(false)
  const [assets, setAssets] = useState([])
  const [isOwner, setIsOwner] = useState(false)
  const [commonProjects, setCommonProjects] = useState([])
  const [showRequestAction, setShowRequestAction] = useState(false)
  const [activeSeller, setActiveSeller] = useState()
  const [trackedVisit, setTrackedVisit] = useState(false)

  const { id } = useParams()

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchBuyer() {
      const r1 = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/buyers/${id}`
      })

      setBuyer(r1.data)

      const thisIsMe = (r1.data.user._id === props.user._id) || props.user.isAdmin
      setIsOwner(thisIsMe)

      if (!thisIsMe && !trackedVisit) {
        await EventLogger.log(cookies.get('p4itoken'), {
          name: 'Profilaufruf',
          actor: props.user._id,
          onSeller: r1.data._id
        })

        setTrackedVisit(true)
      }

      if (!thisIsMe) {
        let buyerProjects = r1.data.projects
        let projects = []
        let projectsData = []

        for (let seller of props.user.sellers) {
          for (let pid of seller.projects) {
            if (buyerProjects.includes(pid)) projects.push(pid)
          }
        }

        if (projects.length) {
          // fetch common projects
          for (let pid of projects) {
            const r = await axios({
              method: 'GET',
              headers: {
                'content-type': 'application/json',
                'Authorization': cookies.get('p4itoken')
              },
              url: `${Env.url}/projects/${pid}`
            })

            if (!r.data.archived) projectsData.push(r.data)
          }

          setCommonProjects(projectsData)
        }
      }

      const r2 = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          owner: r1.data.user._id,
          path: { $regex : "presentation" }
        },
        url: `${Env.url}/assets/search`
      })

      let profileAssets = []
      for (let asset of r2.data) {
        if (asset.path.includes(r1.data._id.toString())) profileAssets.push(asset)
      }

      setAssets(profileAssets)
    }

    if (!fetchedBuyer) {
      fetchBuyer()
      setFetchedBuyer(true)
    }
  }, [fetchedBuyer, cookies, id, props.user, trackedVisit])

  function redactedKpi(value) {
    if (value === undefined || value === '' || (Array.isArray(value) && !value.length)) {
      return 'N/A'
    } else {
      if (Array.isArray(value)) {
        return value.join(', ')
      } else {
        return value
      }
    }
  }

  async function toggleVerification() {
    const r1 = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: { verified: !buyer.verified },
      url: `${Env.url}/buyers/${id}`
    })

    setBuyer(r1.data)
  }

  function updateActiveSeller(newSeller) {
    setActiveSeller(newSeller)
    setShowRequestAction(false)
  }

  async function sendRequest() {
    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: {
        buyer: buyer._id,
        seller: activeSeller._id,
        status: 'initiated',
        step: 0,
        name: faker.name.firstName(),
        documentRequirements: activeSeller.documentRequirements || []
      },
      url: `${Env.url}/projects`
    })

    document.location = `/projects/${response.data._id}`
  }

  if (buyer) {
    let sectorTags = []
    for (let sector of buyer.sectors) {
      sectorTags.push(
        <div className="ProfileCard-tag">{sector}</div>
      )
    }

    let regionTags = []
    for (let location of buyer.locations) {
      regionTags.push(
        <div className="ProfileCard-tag">{location}</div>
      )
    }

    let fileCards = []
    for (let asset of assets) {
      fileCards.push(
        <FileCard
          user={props.user}
          asset={asset}
          />
      )
    }

    let adminCards = []
    if (props.user.isAdmin) {
      let verificationAction;
      if (buyer.verified) {
        verificationAction = (
          <button className="button small danger" onClick={() => toggleVerification()}>Verifikation entziehen</button>
        )
      } else {
        verificationAction = (
          <button className="button small primary" onClick={() => toggleVerification()}>Unternehmen verifizieren</button>
        )
      }
      adminCards = [
        <div className="ProjectCard ActionCard fade-in-up delay-200">
          <h3 className="ActionCard-title">Verifikation</h3>
          <p className="ActionCard-body">Nur verifizierte Unternehmen haben Vollzugriff auf den Hub. Nach erfolgreicher Prüfung sollten alle validen Unternehmen verifiziert werden.</p>
          <div className="ActionCard-actions">
            {verificationAction}
          </div>
        </div>
      ]
    }

    let sellerCards = []
    if (!props.user.isAdmin && !isOwner && props.user.sellers.length) {
      if (commonProjects.length) {
        sellerCards = [
          <div className="ProjectCard ActionCard fade-in-up delay-400">
            <h3 className="ActionCard-title">Aktives Projekt</h3>
            <p className="ActionCard-body">Du bist bereits in ein aktives Projekt mit diesem Unternehmen eingebunden.</p>
            <div className="ActionCard-actions">
              <a className="button small secondary" href={`/projects/${commonProjects[0]._id}`}>Zum Projekt {commonProjects[0].name}</a>
            </div>
          </div>
        ]
      } else {
        let availableSellers = []
        for (let b of props.user.sellers) {
          availableSellers.push(
            <div
              className={`Dropdown-menu-item ${activeSeller === b ? 'active' : ''}`}
              onClick={() => updateActiveSeller(b)}
              >
              {b.name}
            </div>
          )
        }

        let sellerDropdown = (
          <div className={`Dropdown ${showRequestAction ? 'active' : ''}`}>
            <button className="Dropdown-trigger" onClick={() => setShowRequestAction(!showRequestAction)}>
              <span className="Dropdown-trigger-label">
                {(activeSeller || { name: 'Wähle Profil ...' }).name}
              </span>
              <ChevronDown size={18} />
            </button>
            <div className={`Dropdown-menu Dropdown-menu-top ${showRequestAction ? '' : 'hidden'}`}>
              {availableSellers}
            </div>
          </div>
        )

        let requestAction;
        if (activeSeller) {
          requestAction = (
            <button className="button primary circle margin-left" onClick={() => sendRequest()}>
              <ArrowRight size={18} color='white' />
            </button>
          )
        }

        let hasVerifiedSellers = false
        for (let s of props.user.sellers) {
          if (s.verified) {
            hasVerifiedSellers = true
            break
          }
        }

        const profileUserName = buyer.blurred ? `${faker.name.firstName()} ${faker.name.lastName()}` : `${buyer.contactFirstName} ${buyer.contactLastName}`
        const profileUserEmail = buyer.blurred ? faker.internet.email() : <a href={`mailto:${buyer.contactEmail}`}>{buyer.contactEmail}</a>
        let profileUsers = [
          <div className="ProfileCard-list-item">
            <span className={`%{ProfileCard-list-item-label} ${buyer.blurred ? 'blurred' : ''}`}>
              {profileUserName}
            </span>
            <span className={`%{ProfileCard-list-item-value} ${buyer.blurred ? 'blurred' : ''}`}>
              {profileUserEmail}
            </span>
          </div>
        ]

        for (let ivt of buyer.invites) {
          const ivtName = buyer.blurred ? `${faker.name.firstName()} ${faker.name.lastName()}` : `${ivt.firstName} ${ivt.lastName}`
          const ivtEmail = buyer.blurred ? faker.internet.email() : <a href={`mailto:${ivt.email}`}>{ivt.email}</a>
          profileUsers.push(
            <div className="ProfileCard-list-item">
              <span className={`%{ProfileCard-list-item-label} ${buyer.blurred ? 'blurred' : ''}`}>{ivtName}</span>
              <span className={`%{ProfileCard-list-item-value} ${buyer.blurred ? 'blurred' : ''}`}>{ivtEmail}</span>
            </div>
          )
        }

        if (hasVerifiedSellers && availableSellers.length) {
          sellerCards = [
            <div className="ProjectCard ActionCard fade-in-up delay-400">
              <h3 className="ActionCard-title">Ansprechpartner</h3>
              <p className="ActionCard-body">Ihre Ansprechpartner im Überblick</p>
              <div className="ProfileCard-list">{profileUsers}</div>
            </div>,
            <div className="ProjectCard ActionCard fade-in-up delay-400">
              <h3 className="ActionCard-title">Projektanfrage</h3>
              <p className="ActionCard-body">Sieht dieses Unternehmen spannend aus? Sende dem Inhaber  eine Projektanfrage um zu erfahren, ob gemeinsames Interesse besteht.</p>
              <div className="ActionCard-actions">
                {sellerDropdown}
                {requestAction}
              </div>
            </div>
          ]
        }
      }
    }

    let conditionalSectorTags;
    if (sectorTags.length) {
      conditionalSectorTags = (
        <div className="ActionCard ProfileGrid-card fade-in-up delay-200">
          <h3 className="ActionCard-title">Investmentfokus</h3>
          <p className="ActionCard-body">Spezifische Interessensgebiete des Investors</p>

          <div className="ProfileCard-tags">{sectorTags}</div>
        </div>
      )
    }

    let conditionalRegionTags;
    if (regionTags.length) {
      conditionalRegionTags = (
        <div className="ActionCard ProfileGrid-card fade-in-up delay-200">
          <h3 className="ActionCard-title">Geografischer Fokus</h3>
          <p className="ActionCard-body">Regionale Investitionsschwerpunkte des Investors</p>

          <div className="ProfileCard-tags">{regionTags}</div>
        </div>
      )
    }

    let conditionalAssetsCard;
    if (fileCards.length) {
      conditionalAssetsCard = (
        <div className="SplitCard ProfileGrid-card fade-in-up delay-400">
          <div className="SplitCard-left">
            <h3 className="SplitCard-title">Dokumente & Downloads</h3>
            <p className="SplitCard-body">Öffentliche Dokumente sind im Sinne des von Ihnen unterzeichneten Speer NDAs vertraulich zu behandeln.</p>
          </div>
          <div className="SplitCard-right">
            {fileCards}
          </div>
        </div>
      )
    }

    let websiteLink;
    if (buyer.website) {
      if (buyer.website.startsWith('http')) {
        websiteLink = buyer.website
      } else {
        websiteLink = `http://${buyer.website}`
      }
    }

    return (
      <div className="BuyerProfile">
        <Aside darkMode={props.darkMode} user={props.user} scope="buyer-profile" signOutUser={() => props.signOutUser()}>
          <div className="ContentPanel">
            <div className="ContentPanel-main">
              <div className="PanelHeader">
                <h1 className="PanelHeader-title">
                  <strong>Investorenprofil</strong> <span className={buyer.blurred ? 'blurred blurred-more' : ''}>{buyer.name} {buyer.type}</span>
                </h1>
                <h5 className="PanelHeader-subline">{buyer.transactionType} aus {buyer.country}</h5>
              </div>
  
              <div className="Breadcrumbs">
                <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                  <ArrowLeft size={18} color='#6B6E97' />
                </div>
  
                <div className="Breadcrumbs-path">
                  <a href="/">Dashboard</a>
                  <span>/</span>
                  <a href="/buyer-profiles">Investorenprofile</a>
                  <span>/</span>
                  <a href="/" className={buyer.blurred ? 'active blurred' : 'active '}>{buyer.name} {buyer.type}</a>
                </div>
              </div>
  
              <div className="ProfileGrid">
                <div className="ActionCard ProfileGrid-card fade-in-up">
                  <h3 className="ActionCard-title">Quickfacts</h3>
                  <p className="ActionCard-body">Die wichtigsten Informationen auf einen Blick</p>
  
                  <div className="ProfileCard-list">
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Gründungsjahr</span>
                      <span className="ProfileCard-list-item-value">{redactedKpi(buyer.foundingYear)}</span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Mitarbeiter</span>
                      <span className="ProfileCard-list-item-value">{redactedKpi(buyer.employees)}</span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Website</span>
                      <span className={`ProfileCard-list-item-value ${buyer.blurred ? 'blurred' : ''}`}>
                        <a href={websiteLink} target="_blank" rel="noreferrer">{redactedKpi(buyer.website)}</a>
                      </span>
                    </div>
                  </div>
                </div>
  
                <div className="ActionCard ProfileGrid-card fade-in-up">
                  <h3 className="ActionCard-title">Investment Statistik</h3>
                  <p className="ActionCard-body">Hilfreiche Eck-Daten zur Einordnung des Investors</p>
  
                  <div className="ProfileCard-list">
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Investmentfokus</span>
                      <span className="ProfileCard-list-item-value">{redactedKpi(buyer.transactionCause)}</span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Klassifizierung</span>
                      <span className="ProfileCard-list-item-value">{redactedKpi(buyer.transactionType)}</span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Investitionshorizont</span>
                      <span className="ProfileCard-list-item-value">{redactedKpi(buyer.investmentHorizon)}</span>
                    </div>
                  </div>
                </div>
  
                {conditionalSectorTags}
                {conditionalRegionTags}
                {conditionalAssetsCard}

                {adminCards}
                {sellerCards}
              </div>
            </div>
  
            <div className="ContentPanel-sidebar">
              <WidgetFeed scope="buyer" buyer={buyer} user={props.user} patchUser={v => props.patchUser(v)} />
            </div>
          </div>
        </Aside>
      </div>
    )
  } else {
    return (
      <div className="BuyerProfile">
        <Aside darkMode={props.darkMode} user={props.user} scope="buyer-profile" signOutUser={() => props.signOutUser()}>
          <div className="ContentPanel">
            <div className="ContentPanel-main">
              <div className="PanelHeader">
                <h1 className="PanelHeader-title"><strong>Investorenprofil</strong> ...</h1>
                <h5 className="PanelHeader-subline">Lade Daten ...</h5>
              </div>
  
              <div className="Breadcrumbs">
                <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                  <ArrowLeft size={18} color='#6B6E97' />
                </div>
  
                <div className="Breadcrumbs-path">
                  <a href="/">Dashboard</a>
                  <span>/</span>
                  <a href="/buyer-profiles">Investorenprofile</a>
                  <span>/</span>
                  <a href="/" className="active">...</a>
                </div>
              </div>

              <div className="ProfileGrid">
              </div>
            </div>

            <div className="ContentPanel-sidebar">
              <WidgetFeed scope="buyer" buyer={buyer} user={props.user} patchUser={v => props.patchUser(v)} />
            </div>
          </div>
        </Aside>
      </div>
    )
  }
}