import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import EventScheduler from '../../components/EventScheduler';
import WidgetFeed from '../../components/WidgetFeed';

import FileQuestion from './components/FileQuestion';
import MultipleChoiceQuestion from './components/MultipleChoiceQuestion';
import TextQuestion from './components/TextQuestion';
import QuestionForm from './components/QuestionForm';

import { ArrowLeft } from 'react-feather';

import './style.css';

export default function Assessment(props) {
  const [assessment, setAssessment] = useState({})
  const [project, setProject] = useState({})
  const [fetchedProject, setFetchedProject] = useState(false)
  const [isBuyer, setIsBuyer] = useState(false)
  const [isSeller, setIsSeller] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [showQuestionOverlay, setShowQuestionOverlay] = useState(false)

  const { aid } = useParams()

  const cookies = useMemo(() => new Cookies(), [])

  const phaseNames = [
    'Unverbindlicher Austausch',
    'Informationsaustausch',
    'Due Diligence',
    'Signing & Closing'
  ]

  useEffect(() => {
    async function fetchProject() {
      const r1 = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assessments/${aid}`
      })

      setAssessment(r1.data)

      const r2 = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/projects/${r1.data.project}`
      })

      setProject(r2.data)

      const buyerIds = props.user.buyers.map(b => b._id)
      const sellerIds = props.user.sellers.map(s => s._id)

      if (buyerIds.indexOf(r2.data.buyer._id.toString()) !== -1) setIsBuyer(true)
      if (sellerIds.indexOf(r2.data.seller._id.toString()) !== -1) setIsSeller(true)
      if (props.user.isAdmin) setIsAdmin(true)
    }

    if (!fetchedProject) {
      fetchProject()
      setFetchedProject(true)
    }
  }, [fetchedProject, cookies, aid, props.user.buyers, props.user.sellers, props.user.isAdmin])

  async function patchAssessment(newData) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: newData,
      url: `${Env.url}/assessments/${aid}`
    })

    setAssessment(response.data)
  }

  async function updateQuestion(question, newData) {
    await patchAssessment({
      questions: assessment.questions.map(q => q === question ? newData : q)
    })
  }

  async function deleteQuestion(question) {
    await patchAssessment({
      questions: assessment.questions.filter(q => q !== question)
    })
  }

  async function submitNewQuestion(questionData) {
    let newQuestion = questionData

    await patchAssessment({ questions: [...assessment.questions, newQuestion] })

    setShowQuestionOverlay(false)
  }

  function determineQuestionType(question, i, isSubquestion = false) {
    switch (question.questionType) {
      case 'file':
        return (
          <FileQuestion
            subQuestion={isSubquestion}
            idx={i}
            user={props.user}
            project={project}
            question={question}
            assessment={assessment}
            phaseNames={phaseNames}
            isSeller={isSeller}
            isBuyer={isBuyer}
            isAdmin={isAdmin}
            updateQuestion={newData => updateQuestion(question, newData)}
            deleteQuestion={() => deleteQuestion(question)}
            patchAssessment={v => patchAssessment(v)}
            />
        )
      case 'multiple-choice':
        return (
          <MultipleChoiceQuestion
            subQuestion={isSubquestion}
            idx={i}
            user={props.user}
            project={project}
            question={question}
            assessment={assessment}
            phaseNames={phaseNames}
            isSeller={isSeller}
            isBuyer={isBuyer}
            isAdmin={isAdmin}
            updateQuestion={newData => updateQuestion(question, newData)}
            deleteQuestion={() => deleteQuestion(question)}
            patchAssessment={v => patchAssessment(v)}
            />
        )
      default:
        return (
          <TextQuestion
            subQuestion={isSubquestion}
            idx={i}
            user={props.user}
            project={project}
            question={question}
            assessment={assessment}
            phaseNames={phaseNames}
            isSeller={isSeller}
            isBuyer={isBuyer}
            isAdmin={isAdmin}
            updateQuestion={newData => updateQuestion(question, newData)}
            deleteQuestion={() => deleteQuestion(question)}
            patchAssessment={v => patchAssessment(v)}
            />
        )
    }
  }

  async function patchProject(data) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/projects/${project._id}`
    })

    setProject(response.data)
  }

  async function addAssessmentMeeting(i) {
    await patchProject({
      meetingRequests: [...project.meetingRequests, {
        reason: `Assessment ${assessment.name} #${i+2}`,
        author: props.user._id
      }]
    })
  }

  let assessmentItems = [];
  if (project._id && assessment.questions) {
    for (let i = 0; i < assessment.questions.length; i++) {
      const question = assessment.questions[i]
      assessmentItems.push(determineQuestionType(question, `${i+1}.`))

      if (question.questions.length) {
        let assessmentSubItems = [];
        for (let j = 0; j < question.questions.length; j++) {
          const subquestion = question.questions[j]
          assessmentSubItems.push(determineQuestionType(subquestion, `${i+1}.${j+1}`))

          if (subquestion.questions.length) {
            let assessmentSubSubItems = [];
            for (let k = 0; k < subquestion.questions.length; k++) {
              const subsubquestion = subquestion.questions[k]
              assessmentSubSubItems.push(determineQuestionType(subsubquestion, `${i+1}.${j+1}.${k+1}`, true))
            }

            assessmentSubItems.push(
              <div className="AssessmentSubSubItems">{assessmentSubSubItems}</div>
            )
          }
        }

        assessmentItems.push(
          <div className="AssessmentSubItems">{assessmentSubItems}</div>
        )
      }
    }
  }

  let qualifiedAssessment = [
    'Vollzugsbedingungen', 'Transaktionsnachweise'
  ].includes(assessment.name)

  let conditionalPlaceholder;
  if (!isSeller || qualifiedAssessment) {
    conditionalPlaceholder = (
      <div className="placeholder-block fade-in-up">
        <div className="placeholder-inner">
          <h3 className="placeholder-title">Neue Anfrage hinzufügen</h3>
          <p className="placeholder-body">Füge beliebig viele weitere Anfragen und Anforderungen zur dieser Liste hinzu.</p>
          <button className="button primary small" onClick={() => setShowQuestionOverlay(true)}>Neue Anfrage erstellen</button>
        </div>
      </div>
    )
  }

  let scoreTotal = ''
  let scoreRelative = ''
  if (assessment.questions) {
    const completed = assessment.questions.filter(q => q.completed).length
    const total = assessment.questions.length
    scoreTotal = `${completed} von ${total}`
    scoreRelative = total ? Math.round((completed / total) * 100) : 0
  }

  let questionOverlay;
  if (showQuestionOverlay) {
    questionOverlay = (
      <QuestionForm
        setShowQuestionOverlay={() => setShowQuestionOverlay(false)}
        submitNewQuestion={questionData => submitNewQuestion(questionData)}
        />
    )
  }

  let conditionalEventScheduler;
  if (project._id) {
    conditionalEventScheduler = [
      <div className="margin-bottom">
        <EventScheduler
          title={`Termin: ${assessment.name}`}
          description="Stimme bei Bedarf einen Termin zu dieser Anfragenliste ab, um etwaige Fragen zu klären."
          meetingName={`Assessment ${assessment.name}`}
          user={props.user}
          project={project}
          isSeller={isSeller}
          patchProject={d => patchProject(d)}
        />
      </div>
    ]

    const assessmentMeetings = project.meetingRequests.filter(mr => new RegExp(assessment.name).test(mr.reason) && mr.reason !== `Termin zum Thema ${assessment.name}`)

    for (let i = 0; i < assessmentMeetings.length; i++) {
      conditionalEventScheduler.push(
        <div className="margin-bottom">
          <EventScheduler
            title={`Termin: ${assessment.name} #${i+2}`}
            description="Stimme bei Bedarf einen Termin zu dieser Anfragenliste ab, um etwaige Fragen zu klären."
            meetingName={`Assessment ${assessment.name} #${i+2}`}
            user={props.user}
            project={project}
            isSeller={isSeller}
            patchProject={d => patchProject(d)}
          />
        </div>
      )
    }

    conditionalEventScheduler.push(
      <div className="ActionCard margin-bottom">
        <h3 className="ActionCard-title">Weitere Termine?</h3>
        <p className="ActionCard-body">Falls du zu weiteren Punkten Gesprächsbedarf siehst, kannst du hier weitere Termine anlegen und verwalten.</p>
        <p className="ActionCard-actions no-margin">
          <button className="button primary" onClick={() => addAssessmentMeeting(assessmentMeetings.length)}>Neuer Termin</button>
        </p>
      </div>
    )
  }

  return (
    <div className="Project">
      <Aside darkMode={props.darkMode} user={props.user} scope={isSeller ? 'seller-projects' : 'buyer-projects'} signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title">{assessment.name}</h1>
              <h5 className="PanelHeader-subline">
                <strong>{scoreTotal}</strong> Anfragen erfüllt ({scoreRelative}%)</h5>
            </div>

            <div className="Project-header">
              <div className="Breadcrumbs">
                <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                  <ArrowLeft size={18} color='#6B6E97' />
                </div>

                <div className="Breadcrumbs-path">
                  <a href="/">Dashboard</a>
                  <span>/</span>
                  <a href={`/projects/${project._id}`}>Projekt {project.name}</a>
                  <span>/</span>
                  <a href='/' className="active">{assessment.name}</a>
                </div>
              </div>
            </div>

            {conditionalEventScheduler}

            {assessmentItems}

            {conditionalPlaceholder}
          </div>

          <div className="ContentPanel-sidebar">
            <WidgetFeed scope="assessment" />
          </div>
        </div>
      </Aside>

      {questionOverlay}
    </div>
  )
}