import Accordion from '../../../components/Accordion';

export default function AdminActions(props) {
  let verificationState;
  if (props.seller.verified) {
    verificationState = (
      <div className="ProjectCard ActionCard ActionCard--white">
        <h3 className="ActionCard-title">Verifikation entziehen</h3>
        <p className="ActionCard-body">
          <small className="text-muted">Verifizierte Profile können die volle Funktion des Speers nutzen und sind in der Suche sichtbar.</small>
        </p>

        <div className="ActionCard-actions">
          <button className="button danger small" onClick={() => props.patchSeller({ verified: false })}>Verifikation entziehen</button>
        </div>
      </div>
    )
  } else {
    verificationState = (
      <div className="ProjectCard ActionCard ActionCard--white">
        <h3 className="ActionCard-title">Profil verifizieren</h3>
        <p className="ActionCard-body">
          <small className="text-muted">Verifizierte Profile können die volle Funktion des Speers nutzen und sind in der Suche sichtbar.</small>
        </p>

        <div className="ActionCard-actions">
          <button className="button primary small" onClick={() => props.patchSeller({ verified: true })}>Profil verifizieren</button>
        </div>
      </div>
    )
  }

  return (
    <>
      <Accordion
        title="Administration"
        toggleState={props.toggleState}
        body="Als Administrator kannst du die globale Darstellung und Konfiguration dieses Profils modifizieren."
        badgeLabel='Admin'
        badgeStyle='badge-dark'
      >
        <div className="Settings-fields Settings-fields-c2">
          {verificationState}
        </div>

      </Accordion>
    </>
  )
}