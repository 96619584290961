import { useState, useEffect } from "react";

import Makro from "../../Makro";

export default function ProjectSellerFeed(props) {
  const [actionFeed, setActionFeed] = useState([])

  useEffect(() => {
    let newActionFeed = []

    switch (props.project.step) {
      case 0:
        if (props.project.sellerOptOut || props.project.sellerOptOut === undefined) {
          newActionFeed.push(
            <Makro
              actionLabel="Ja, bin ich!"
              label="Bist du interessiert?"
              targetAction={() => props.patchProject({ sellerOptOut: false })}
            />
          )
        }

        newActionFeed.push(
          <Makro
            iconAction
            label="Investorenprofil"
            target={`/buyer-profile/${props.project.buyer._id}`}
            />
        )
        break;
      case 1:
        newActionFeed.push(
          <Makro
            iconAction
            label="Investorenprofil"
            target={`/buyer-profile/${props.project.buyer._id}`}
            />
        )
        break;
      case 2:
        newActionFeed.push(
          <Makro
            iconAction
            label="Contact Sheet"
            targetAction={() => props.updateActiveScope('Partner')}
            />
        )
        break;
      default:
        break;
    }

    if (!props.project.approvedBySeller && props.project.step > 0 && props.project.step < 3) {
      newActionFeed.push(
        <Makro
          iconAction
          label="Fortschritt freigeben"
          targetAction={() => props.patchProject({ approvedBySeller: !props.project.approvedBySeller })}
          />
      )
    }

    if (!props.project.approvedByBuyer && props.project.step >= 3) {
      newActionFeed.push(
        <Makro
          iconAction
          label="Projekt archivieren"
          targetAction={() => props.patchProject({ approvedBySeller: !props.project.approvedBySeller })}
          />
      )
    }

    if (newActionFeed.length) setActionFeed(newActionFeed)
  }, [ props ])

  return actionFeed;
}