import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import Accordion from '../../../components/Accordion';
import FileCard from '../../../components/FileCard';

import axios from 'axios';
import Cookies from 'universal-cookie'

import Env from '../../../Environments';

export default function NDASettings(props) {
  const [score, setScore] = useState(0)
  const [fieldsSet, setFieldsSet] = useState(false)
  const [assets, setAssets] = useState([])
  const [menuTarget, setMenuTarget] = useState()

  const cookies = useMemo(() => new Cookies(), [])

  const fileUpload = useRef();

  const updateScore = useCallback(() => {
    let newScore = 0

    if (assets && assets.length) {
      for (let asset of assets) {
        if (asset.path.endsWith('buyerNDA')) newScore = 100
      }
    } else {
      setScore(0)
    }

    setScore(Math.round(newScore))
  }, [assets])

  useEffect(() => {
    async function fetchAssets() {
      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: { owner: props.buyer.user },
        url: `${Env.url}/assets/search`
      })

      setAssets(response.data)
    }

    if (props.buyer._id && !fieldsSet) {
      fetchAssets()

      setFieldsSet(true)
    }

    updateScore()
  }, [props.buyer, fieldsSet, updateScore, cookies])

  async function handleFileUpload(e) {
    const fileList = Array.from(e.target.files)

    const data = new FormData()
    data.append('vfile', fileList[0])
    data.append('path', `users/${props.user._id}/${props.buyer._id}/buyerNDA`)
    data.append('owner', props.user._id)

    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/assets`
    })

    setAssets([...assets, response.data])
  }

  async function patchFile(a, newData) {
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: newData,
      url: `${Env.url}/assets/${a._id}`
    })

    setAssets()
    setFieldsSet(false)
  }

  let fileCards = [];
  if (assets && assets.length) {
    for (let asset of assets) {
      if (asset.path.endsWith('buyerNDA')) {
        fileCards.push(
          <FileCard
            user={props.user}
            asset={asset}
            menuTarget={menuTarget}
            setMenuTarget={setMenuTarget}
            patchFile={newData => patchFile(asset, newData)}
            />
        )
      }
    }
  }

  let p4iNDALink;
  if (assets && assets.length) {
    for (let a of assets) {
      if (a.path.endsWith('p4inda')) {
        p4iNDALink = <div className="text-muted"><small>Das unterschriebene NDA zwischendirund Speer kannst du jederzeit <a href={a.source} download>hier einsehen</a>.</small></div>
      }
    }
  }

  return (
    <Accordion
      title="Standard-NDA"
      toggleState={props.toggleState}
      body="Alle Transaktionen in Speer sind streng vertraulich. Um das zu gewährleisten, sind alle Partner zu Beginn einer Transaktion verpflichtet, eine Vertraulichkeitsvereinbarung zu unterzeichnen. Sofern du spezifische Anfroderungen an diese hast, lade deine Vorlage hier hoch. Achte darauf, dass das Dokument problemlos unterschrieben werden kann (z.B. als PDF mit gekennzeichneten Feldern)."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
    >
      <button className="button secondary small" style={{ marginTop: '12px' }} onClick={() => fileUpload.current.click()}>NDA hochladen</button>
      <input type="file" ref={fileUpload} className="invisible" onChange={e => handleFileUpload(e)} />

      {p4iNDALink}

      <div className="settings-subsection">
        <div className="Settings-fields Settings-fields-c2">
          {fileCards}
        </div>
      </div>
    </Accordion>
  )
}