import { useEffect, useState, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import './style.css';

import Aside from '../../components/Aside';
import CustomSelectable from '../../components/CustomSelectable';

import ReactCountryFlag from 'react-country-flag';

import { Tool, ArrowLeft, X } from 'react-feather';

import optionService from '../../services/OptionService';

export default function BuyerProfiles(props) {
  const PAGESIZE = 25

  const [buyers, setBuyers] = useState([])
  const [buyersCount, setBuyersCount] = useState(0)
  const [fetchedBuyers, setFetchedBuyers] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')
  const [showNewBuyerModal, setShowNewBuyerModal] = useState(false)

  const [companyName, setCompanyName] = useState('')
  const [currency, setCurrency] = useState('')
  const [companyType, setCompanyType] = useState('')
  const [address, setAddress] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')

  const cookies = useMemo(() => new Cookies(), [])

  const countryOptions = optionService.countryCodes
  const fullCountryOptions = optionService.countries
  const companyOptions = optionService.companyTypes

  useEffect(() => {
    async function fetchBuyers() {
      let searchData = {}
      if (!props.user.isAdmin) {
        searchData.user = props.user._id
      }

      if (filterQuery) {
        searchData.name = { $regex: filterQuery }
      }

      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          filters: searchData,
          options: {
            limit: PAGESIZE,
            skip: buyers.length
          }
        },
        url: `${Env.url}/buyers/search`
      })

      setBuyers([...buyers, ...(response.data.records || [])])
      setBuyersCount(response.data.count)
    }

    if (props.user && props.user.isAdmin) {
      if (!fetchedBuyers) {
        fetchBuyers()
        setFetchedBuyers(true)
      }
    } else {
      if (filterQuery !== '') {
        const allBuyers = props.user.buyers
        const regex = new RegExp(filterQuery, 'i')
        setBuyers(allBuyers.filter(s => regex.test(s.name)))
      } else {
        setBuyers(props.user.buyers)
      }
    }
  }, [filterQuery, cookies, fetchedBuyers, props.user, buyers])

  function updateFilterQuery(newValue) {
    setFilterQuery(newValue)
    setFetchedBuyers(false)
    setBuyers([])
  }

  async function createBuyer(evt) {
    evt.preventDefault();
    setShowNewBuyerModal(false)

    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: {
        user: { _id: props.user._id },
        companyName: companyName,
        address: address,
        zip: zip,
        city: city,
        country: country.value,
        locations: country.value ? [country.value] : [],
        type: companyType.value,
        name: companyName,
        contactFirstName: props.user.firstName,
        contactLastName: props.user.lastName,
        contactEmail: props.user.username,
        contactAddress1: address,
        contactZip: zip,
        contactCity: city,
        contactCountry: country.value,
        contactPhone: props.user.phone,
        contactPhone2: props.user.phone2,
        currency: currency.value
      },
      url: `${Env.url}/buyers`
    })

    document.location.reload()
  }

  let profileRows = [];
  for (let profile of buyers) {
    let activeState;
    if (profile.investmentStatus === 'Aktiv') {
      activeState = <span className="badge badge-success badge-bullet">Aktiv</span>
    } else {
      activeState = <span className="badge badge-bullet">Inaktiv</span>
    }

    let verificationState;
    if (profile.verified) {
      verificationState = <span className="badge badge-success badge-bullet">Bestätigt</span>
    } else {
      verificationState = <span className="badge badge-bullet">Ausstehend</span>
    }

    profileRows.push(
      <tr key={profile._id}>
        <td>
          <ReactCountryFlag
            countryCode={countryOptions[profile.country]}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={countryOptions[profile.country]}
            style={{ width: '1em', height: '1em', }}
            />
        </td>
        <td>{profile.name} {profile.type}</td>
        <td>{activeState}</td>
        <td><a href={`/buyer-profile/${profile._id}`}>Profil ansehen</a></td>
        <td>{new Date(profile.createdAt).toLocaleDateString()}</td>
        <td>{new Date(profile.updatedAt).toLocaleDateString()}</td>
        <td>{verificationState}</td>
        <td><button className="circle secondary" onClick={() => document.location = `/settings/buyers/${profile._id}`}>
          <Tool size={16} color='#6877B2' /></button>
        </td>
      </tr>
    )
  }

  let conditionalDataTable;
  if (buyers.length) {
    conditionalDataTable = (
      <table className="DataTable">
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th>Status</th>
            <th>Profil</th>
            <th>Erstellt</th>
            <th>Aktualisiert</th>
            <th>Verifikation</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {profileRows}
        </tbody>
      </table>
    )
  } else {
    if (props.user.isAdmin) {
      conditionalDataTable = (
        <div className="placeholder-block placeholder-top-margin fade-in-up">
          <div className="placeholder-inner">
            <h3 className="placeholder-title">Keine Investorenprofile</h3>
            <p className="placeholder-body">Es konnten keine passenden Investorenprofile gefunden werden.</p>
          </div>
        </div>
      )
    } else {
      conditionalDataTable = (
        <div className="placeholder-block placeholder-top-margin fade-in-up">
          <div className="placeholder-inner">
            <h3 className="placeholder-title">Keine Investorenprofile</h3>
            <p className="placeholder-body">Du hast keine Investorenprofile in deinem Account hinterlegt.</p>
            <button className="button primary small" onClick={() => setShowNewBuyerModal(true)}>Investorenprofil anlegen</button>
          </div>
        </div>
      )
    }
  }

  let conditionalPlaceholder;
  if (!props.user.isAdmin && buyers.length) {
    conditionalPlaceholder = (
      <div className="placeholder-block placeholder-top-margin fade-in-up">
        <div className="placeholder-inner">
          <h3 className="placeholder-title">Weitere Investorenprofile?</h3>
          <p className="placeholder-body">Du kannst jederzeit weitere Investorenprofile in deinem Account hinterlegen.</p>
          <button className="button primary small" onClick={() => setShowNewBuyerModal(true)}>Investorenprofil anlegen</button>
        </div>
      </div>
    )
  }

  let conditionalBuyerModal;
  if (showNewBuyerModal) {
    conditionalBuyerModal = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowNewBuyerModal(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Neues Investorenprofil</h3>
              <p className="overlay-intro">
                Richte ein neues Profil ein um als Investor aufzutreten.
              </p>
            </div>

            <form className="overlay-body" onSubmit={e => createBuyer(e)}>
              <div className="form-field">
                <label>Name des Unternehmens</label>
                <input type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} />
              </div>

              <div className="Settings-fields Settings-fields-c2">
                <div className="form-field">
                  <label>Währung</label>
                  <CustomSelectable
                    placeholder=''
                    classNamePrefix="react-select"
                    options={[
                      { label: 'EUR', value: 'EUR' },
                      { label: 'CHF', value: 'CHF' },
                      { label: 'USD', value: 'USD' }
                    ]}
                    value={currency}
                    onChange={v => setCurrency(v)}
                    />
                </div>

                <div className="form-field">
                  <label>Gesellschaftsform</label>
                  <CustomSelectable
                    placeholder=''
                    classNamePrefix="react-select"
                    options={companyOptions.map(o => ({ label: o, value: o }))}
                    value={companyType}
                    onChange={v => setCompanyType(v)}
                    />
                </div>
              </div>

              <div className="form-field">
                <label>Straße & Hausnummer</label>
                <input type="text" value={address} onChange={e => setAddress(e.target.value)} />
              </div>

              <div className="Settings-fields Settings-fields-c2">
                <div className="form-field">
                  <label>Postleitzahl</label>
                  <input type="text" value={zip} onChange={e => setZip(e.target.value)} />
                </div>

                <div className="form-field">
                  <label>Stadt</label>
                  <input type="text" value={city} onChange={e => setCity(e.target.value)} />
                </div>
              </div>

              <div className="form-field">
                <label>Land</label>
                <CustomSelectable
                  placeholder=''
                  classNamePrefix="react-select"
                  options={fullCountryOptions.map(o => ({ label: o, value: o }))}
                  value={country}
                  onChange={v => setCountry(v)}
                  />
              </div>

              <button type="submit" className="button primary small full-width">Speichern</button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  let searchFilter;
  if (props.user.isAdmin) {
    searchFilter = (
      <div className="TableAction">
        <span className="TableAction-action">
          <input
            type="text"
            className="search"
            placeholder='Investoren filtern ...'
            value={filterQuery}
            onChange={e => updateFilterQuery(e.target.value)}
            />
        </span>
      </div>
    )
  }

  let conditionalLoadMore;
  if (buyersCount > buyers.length) {
    conditionalLoadMore = (
      <div className="load-more">
        <button className="button primary ghost small" onClick={() => setFetchedBuyers(false)}>Weitere laden ...</button>
      </div>
    )
  }

  return (
    <div className="BuyerProfiles">
      <Aside darkMode={props.darkMode} user={props.user} scope="buyer-profiles" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title"><strong>Firmenprofile</strong> als Investor</h1>
              <h5 className="PanelHeader-subline">
                {buyers.length} konfigurierte{buyers.length === 1 ? 's' : ''} Investorenprofil{buyers.length === 1 ? '' : 'e'}
              </h5>
            </div>

            <div className="Breadcrumbs">
              <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                <ArrowLeft size={18} color='#6B6E97' />
              </div>

              <div className="Breadcrumbs-path">
                <a href="/">Dashboard</a>
                <span>/</span>
                <a href="/buyer-profiles" className="active">Investorenprofile</a>
              </div>

              {searchFilter}
            </div>

            {conditionalDataTable}

            {conditionalLoadMore}

            {conditionalPlaceholder}
          </div>
        </div>
      </Aside>

      {conditionalBuyerModal}
    </div>
  )
}