import ReactCountryFlag from 'react-country-flag';

import optionService from '../../services/OptionService';
import Tooltip from '../../components/Tooltip';

import { HelpCircle } from 'react-feather';

import './style.css';

export default function ProfileRow(props) {
  const countryOptions = optionService.countryCodes

  function valueOrPlaceholder(v) {
    if (!!v) {
      if (Array.isArray(v)) {
        if (v.length) {
          let showTooltip = false
          let conditionalBadge;

          if (v.length > 1) {
            showTooltip = true
            conditionalBadge = <span className="badge small">+{v.length-1}</span>
          }

          let firstValue = v[0]
          if (firstValue.length > 20) {
            showTooltip = true
            firstValue = `${firstValue.slice(0, 20)}...`
          }

          if (showTooltip) {
            return (
              <Tooltip text={v.join(', ')}>
                <span>{firstValue} {conditionalBadge}</span>
              </Tooltip>
            )
          } else {
            return <span>{firstValue} {conditionalBadge}</span>
          }
        } else {
          return <span className="text-muted">N/A</span>
        }
      } else {
        return v
      }
    } else {
      return <span className="text-muted">N/A</span>
    }
  }

  function censorValue(v) {
    let adjustedValue;
    if (!isNaN(v)) {
      if (v >= 1000) adjustedValue = `${Math.round(v/1000)}K`
      if (v >= 1000000) adjustedValue = `${(v/1000000.0).toFixed(1)}M`

      if (props.rec.blurred) {
        const thresholds = optionService.thresholds

        let min = 0;
        let max = 0;
        for (let t of thresholds) {
          if (t > v && (t < max || max === min)) max = t
          if (t < v && t > min) { min = t; max = t; }
        }

        if (max >= 1000000) max = `${max/1000000.0}M`
        if (max >= 1000) max = `${Math.round(max/1000)}K`

        if (min >= 1000000) min = `${min/1000000.0}M`
        if (min >= 1000) min = `${Math.round(min/1000)}K`

        adjustedValue = `${min}-${max}`
      }
    } else {
      adjustedValue = 'N/A'
    }

    return adjustedValue
  }

  if (props.entity === 'buyers') {
    let recName = `${props.rec.name} ${props.rec.type}`
    if (recName.length > 15) recName = `${recName.slice(0, 15)}...`

    let conditionalFlag;
    if (props.rec.country) {
      conditionalFlag = (
        <ReactCountryFlag
          countryCode={countryOptions[props.rec.country]}
          svg
          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
          cdnSuffix="svg"
          title={countryOptions[props.rec.country]}
          style={{ width: '1em', height: '1em', }}
          />
      )
    } else {
      conditionalFlag = <div className="icon muted"><HelpCircle size={16} /></div>
    }

    let horizon;
    switch (props.rec.investmentHorizon) {
      case 'bis 5 Jahre':
        horizon = '5 Jahre'
        break;
      case 'bis 10 Jahre':
        horizon = '10 Jahre'
        break;
      case '>10 Jahre':
        horizon = '+10 Jahre'
        break;
      case 'unbeschränkt':
        horizon = 'unbeschränkt'
        break;
      default:
        horizon = 'N/A'
        break;
    }
    return (
      <tr className="ProfileRow">
        <td className="xs">{conditionalFlag}</td>
        <td className={props.rec.blurred ? 'blurred xl' : 'xl'}><a href={`/buyer-profile/${props.rec._id}`}>{recName}</a></td>
        <td className="md">{valueOrPlaceholder(props.rec.transactionType)}</td>
        <td className="md"><span className='td-badge'>{horizon}</span></td>
        <td className="xl">{valueOrPlaceholder(props.rec.sectors)}</td>
        <td><span className={`badge ${props.rec.investmentStatus === 'Aktiv' ? 'badge-success badge-bullet' : ''}`}>{props.rec.investmentStatus || 'N/A'}</span></td>
      </tr>
    )
  } else {
    let sellerName = props.rec.name;
    if (sellerName && sellerName.length > 20) {
      sellerName = sellerName.slice(0, 20)+'...'
    } else {
      sellerName = `${props.rec.name} ${props.rec.type}`;
    }

    return (
      <tr className="ProfileRow">
        <td className="xs">
          <ReactCountryFlag
            countryCode={countryOptions[props.rec.country]}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={countryOptions[props.rec.country]}
            style={{ width: '1em', height: '1em', }}
            />
        </td>
        <td className={props.rec.blurred ? 'blurred xl' : ' xl'}><a href={`/seller-profile/${props.rec._id}`}>{sellerName}</a></td>
        <td className="md">{valueOrPlaceholder(props.rec.sectors)}</td>
        <td className="sm"><span className={`td-badge ${props.rec.blurred ? 'censored' : ''}`}>{censorValue(props.rec.currentRevenue)}</span></td>
        <td className="sm"><span className={`td-badge ${props.rec.blurred ? 'censored' : ''}`}>{censorValue(props.rec.ebitda)}</span></td>
        <td className="sm"><span className={`td-badge ${props.rec.blurred ? 'censored' : ''}`}>{censorValue(props.rec.ebit)}</span></td>
      </tr>
    )
  }
}