import axios from 'axios';

import Env from '../Environments';

const eventLogger = {
  log: async (authorization, data) => {
    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': authorization
      },
      data: data,
      url: `${Env.url}/events`
    })
  }
}

export default eventLogger;
