import { MoreHorizontal } from "react-feather";

export default function Partners(props) {
  let tableRows = []

  let buyerName = 'N/A'
  if (props.project.buyer.contactFirstName && props.project.buyer.contactLastName) {
    buyerName = `${props.project.buyer.contactFirstName} ${props.project.buyer.contactLastName}`
  }

  let buyerMail = 'N/A'
  let buyerMailLink;
  if (props.project.buyer.contactEmail) {
    buyerMail = `${props.project.buyer.contactEmail}`
    buyerMailLink = `mailto:${props.project.buyer.contactEmail}`
  }

  let buyerPhone = 'N/A'
  let buyerPhoneLink;
  if (props.project.buyer.contactPhone) {
    buyerPhone = `${props.project.buyer.contactPhone}`
    buyerPhoneLink = `tel:${props.project.buyer.contactPhone}`
  }

  const buyerRow = (
    <tr>
      <td>{buyerName}</td>
      <td><a href={buyerMailLink}>{buyerMail}</a></td>
      <td><a href={buyerPhoneLink}>{buyerPhone}</a></td>
      <td>Investor</td>
      <td><span className="badge badge-success">Aktiv</span></td>
      <td></td>
    </tr>
  )

  let sellerName = 'N/A'
  if (props.project.seller.contactFirstName && props.project.seller.contactLastName) {
    sellerName = `${props.project.seller.contactFirstName} ${props.project.seller.contactLastName}`
  }

  let sellerMail = 'N/A'
  let sellerMailLink;
  if (props.project.seller.contactEmail) {
    sellerMail = `${props.project.seller.contactEmail}`
    sellerMailLink = `mailto:${props.project.seller.contactEmail}`
  }

  let sellerPhone = 'N/A'
  let sellerPhoneLink;
  if (props.project.seller.contactPhone) {
    sellerPhone = `${props.project.seller.contactPhone}`
    sellerPhoneLink = `tel:${props.project.seller.contactPhone}`
  }

  const sellerRow = (
    <tr>
      <td>{sellerName}</td>
      <td><a href={sellerMailLink}>{sellerMail}</a></td>
      <td><a href={sellerPhoneLink}>{sellerPhone}</a></td>
      <td>Ansprechpartner</td>
      <td><span className="badge badge-success">Aktiv</span></td>
      <td></td>
    </tr>
  )

  if (props.user._id.toString() === props.project.seller.user.toString()) {
    tableRows.push(sellerRow)
    if (props.user.isAdmin || props.project.step > 0) tableRows.push(buyerRow)
  } else {
    tableRows.push(buyerRow)
    if (props.user.isAdmin || props.project.step > 0) tableRows.push(sellerRow)
  }

  for (let invite of props.project.invites) {
    if (props.project.step > 0 || invite.from === props.user || props.user.isAdmin) {
      let partnerName = 'N/A'
      if (invite.firstName && invite.firstName) partnerName = `${invite.firstName} ${invite.lastName}`

      let partnerMail = 'N/A'
      let partnerMailLink;
      if (invite.email) {
        partnerMail = `${invite.email}`
        partnerMailLink = `mailto:${invite.email}`
      }

      let partnerPhone = 'N/A'
      let partnerPhoneLink;
      if (invite.email) {
        partnerPhone = `${invite.phone}`
        partnerPhoneLink = `tel:${invite.phone}`
      }

      tableRows.push(
        <tr>
          <td>{partnerName}</td>
          <td><a href={partnerMailLink}>{partnerMail}</a></td>
          <td><a href={partnerPhoneLink}>{partnerPhone}</a></td>
          <td>{invite.focus || 'N/A'}</td>
          <td><span className={`badge ${invite.active ? 'badge-success' : ''}`}>{invite.active ? 'Aktiv' : 'Inaktiv'}</span></td>
          <td>
            <div className="bullet-actions">
              <button
                className="bullet-action button circle secondary"
                onClick={() => props.setMenuTarget(props.menuTarget === invite ? undefined : invite)}
                >
                <MoreHorizontal />
              </button>

              <div className={`popover-container extra-offset ${props.menuTarget === invite ? '' : 'hidden'}`}>
                <div className="popover">
                  <div className="popover-menu">
                    <div className="popover-action danger" onClick={() => props.deleteInvite(invite)}>
                      <span className="popover-action-label">Einladung terminieren</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )
    }
  }

  return (
    <div className="Partners fade-in-up">
      <table className="DataTable DataTable-left-aligned">
        <thead>
          <tr>
            <th>Name</th>
            <th>E-Mail</th>
            <th>Telefon</th>
            <th>Rolle</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    </div>
  )
}