import { useState } from 'react';

import QuestionForm from './QuestionForm';

import { ChevronUp, Trash, X } from 'react-feather';

export default function TextQuestion(props) {
  const [showBody, setShowBody] = useState(false)
  const [textAnswer, setTextAnswer] = useState(props.question.textAnswer)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [showQuestionForm, setShowQuestionForm] = useState(false)

  let completeCTA;
  if (!props.question.completed && !props.isSeller) {
    completeCTA = (
      <button className='button small secondary' onClick={() => toggleCompletion()}>
        Anfrage abschließen
      </button>
    )
  }

  let adminActions;
  let buyerActions;
  if (!props.isSeller) {
    adminActions = [
      <button className="button danger circle small" onClick={() => setShowDeletionConfirmation(!showDeletionConfirmation)}><Trash size={12} /></button>
    ]

    if (!props.subQuestion) {
      buyerActions = [
        <button className="button link small" onClick={() => setShowQuestionForm(true)}>Nachfrage stellen</button>
      ]
    }
  }

  let conditionalInfoBody;
  let conditionalContentBody;
  if (showBody) {
    conditionalInfoBody = (
      <div className="AssessmentItem-info-body">
        <p className="AssessmentItem-intro">{props.question.description}</p>
        <div className="AssessmentItem-actions">
          {completeCTA}
          {buyerActions}
        </div>
      </div>
    )

    conditionalContentBody = (
      <div className="AssessmentItem-content-body form-field">
        <textarea value={textAnswer} onChange={e => updateAnswer(e.target.value)} />
      </div>
    )
  }

  async function updateAnswer(newValue) {
    setTextAnswer(newValue)
    let newQuestion = Object.assign(props.question)
    newQuestion.textAnswer = newValue
    await props.updateQuestion(newQuestion)
  }

  async function toggleCompletion() {
    let newQuestion = Object.assign(props.question)
    newQuestion.completed = !props.question.completed

    await props.updateQuestion(newQuestion)
  }

  async function submitNewQuestion(questionData) {
    let newQuestions = []

    for (let q1 of props.assessment.questions) {
      let parentQuestion = Object.assign(q1)

      if (q1 === props.question) {
        parentQuestion.questions = [...parentQuestion.questions, questionData]
      } else {
        let newSubQuestions = []
        for (let q2 of q1.questions) {
          if (q2 === props.question) {
            let parentSubQuestion = Object.assign(q2)
            parentSubQuestion.questions = [...parentSubQuestion.questions, questionData]
            newSubQuestions.push(parentSubQuestion)
          } else {
            newSubQuestions.push(q2)
          }
        }

        parentQuestion.questions = newSubQuestions
      }
      newQuestions.push(parentQuestion)
    }

    await props.patchAssessment({ questions: newQuestions })

    setShowQuestionForm(false)
  }

  let conditionalQuestionForm;
  if (showQuestionForm) {
    conditionalQuestionForm = (
      <QuestionForm
        setShowQuestionOverlay={() => setShowQuestionForm(false)}
        submitNewQuestion={questionData => submitNewQuestion(questionData)}
        />
    )
  }

  async function deleteQuestion() {
    await props.deleteQuestion()
    setShowDeletionConfirmation(false)
  }

  let deletionConfirmation;
  if (showDeletionConfirmation) {
    deletionConfirmation = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowDeletionConfirmation(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Bist du sicher?</h3>
              <p className="overlay-intro">
                Wenn du die Anfrage löschst, wird sie für alle Parteien dieser Transaktion entfernt. Diese Aktion kann nicht rückgängig gemacht werden.
              </p>
            </div>

            <div className="overlay-body">
              <div className="overlay-actions">
                <button style={{ marginTop: '12px' }} className="button small danger" onClick={e => deleteQuestion()}>
                  Löschen
                </button>

                <button style={{ marginTop: '12px' }} className="button small secondary" onClick={e => setShowDeletionConfirmation(false)}>
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="AssessmentItem">
      <div className="AssessmentItem-info">
        <div className="AssessmentItem-info-header">
          <h4 className="AssessmentItem-title">
            <span className="AssessmentItem-number">{props.idx}</span> {props.question.question}
          </h4>
          <div className="AssessmentItem-header-actions">
            {adminActions}
            <ChevronUp color="#959ABA" onClick={() => setShowBody(!showBody)} className={showBody ? 'AssessmentItem--header-action' : 'AssessmentItem--header-action inverted'} />
          </div>
        </div>
        {conditionalInfoBody}
      </div>
      <div className="AssessmentItem-content">
        <div className="AssessmentItem-content-header">
          <span className="AssessmentItem-subtitle">
            {props.question.textAnswer ? 'Antwort vorhanden' : 'Bisher keine Antwort'}
          </span>
          <span className={`badge ${props.question.completed ? 'badge-success' : ''}`}>
            {props.question.completed ? 'Erledigt' : (props.question.textAnswer ? 'In Review' : 'Todo')}
          </span>
        </div>
        {conditionalContentBody}
      </div>

      {conditionalQuestionForm}
      {deletionConfirmation}
    </div>
  )
}