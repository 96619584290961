import React, { useEffect, useState, useCallback } from 'react';

import Accordion from '../../../../components/Accordion';

import ApRecordRow from './components/ApRecordRow';

export default function AdjustmentSettings(props) {
  const [editMode, setEditMode] = useState()
  const [editName, setEditName] = useState()
  const [editValue, setEditValue] = useState()
  const [menuTarget, setMenuTarget] = useState()
  const [score, setScore] = useState(0)
  const [adjustmentPositionsFC, setAdjustmentPositionsFC] = useState()
  const [adjustmentPositions, setAdjustmentPositions] = useState()
  const [adjustmentPositionsX1, setAdjustmentPositionsX1] = useState()
  const [adjustmentPositionsX2, setAdjustmentPositionsX2] = useState()
  const [adjustmentPositionsX3, setAdjustmentPositionsX3] = useState()
  const [fieldsSet, setFieldsSet] = useState(false)

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [adjustmentPositionsFC, adjustmentPositions, adjustmentPositionsX1, adjustmentPositionsX2, adjustmentPositionsX3]
    properties.forEach(p => {
      if (p && p.length) newScore += 100/properties.length
    })
  
    setScore(Math.round(newScore))
  }, [adjustmentPositionsFC, adjustmentPositions, adjustmentPositionsX1, adjustmentPositionsX2, adjustmentPositionsX3])

  useEffect(() => {
    if (props.seller._id && !fieldsSet) {
      setAdjustmentPositionsFC(props.seller.adjustmentPositionsFC)
      setAdjustmentPositions(props.seller.adjustmentPositions)
      setAdjustmentPositionsX1(props.seller.adjustmentPositionsX1)
      setAdjustmentPositionsX2(props.seller.adjustmentPositionsX2)
      setAdjustmentPositionsX3(props.seller.adjustmentPositionsX3)

      setFieldsSet(true)
    }

    updateScore()
  }, [props.seller, fieldsSet, updateScore])

  function saveAndLeaveEditMode(ipt) {
    setEditMode()
    setEditName()
    setEditValue()
    setMenuTarget()

    let parent;
    // find the adjustment record
    const parents = [adjustmentPositionsFC, adjustmentPositions, adjustmentPositionsX1, adjustmentPositionsX2, adjustmentPositionsX3]
    for (let p of parents) {
      if (parent) break;

      for (let ap of p) {
        if (ap.name === editMode.name && ap.amount === editMode.amount) {
          parent = p
          break;
        }
      }
    }

    let attr;
    let setter;
    switch (parent) {
      case adjustmentPositionsFC:
        attr = 'adjustmentPositionsFC'
        setter = v => setAdjustmentPositionsFC(v)
        break;
      case adjustmentPositions:
        attr = 'adjustmentPositions'
        setter = v => setAdjustmentPositions(v)
        break;
      case adjustmentPositionsX1:
        attr = 'adjustmentPositionsX1'
        setter = v => setAdjustmentPositionsX1(v)
        break;
      case adjustmentPositionsX2:
        attr = 'adjustmentPositionsX2'
        setter = v => setAdjustmentPositionsX2(v)
        break;
      case adjustmentPositionsX3:
        attr = 'adjustmentPositionsX3'
        setter = v => setAdjustmentPositionsX3(v)
        break;
      default:
        break;
    }

    let newValue = []
    for (let ap of parent) {
      if (ap.name === editMode.name && ap.amount === editMode.amount) {
        newValue.push({ name: editName, amount: editValue })
      } else {
        newValue.push(ap)
      }
    }

    setter(newValue)

    updateScore()

    let patchData = {}
    patchData[attr] = newValue

    props.patchSeller(patchData)
  }

  function updateMenuTarget(newValue) {
    if (menuTarget === newValue) {
      setMenuTarget()
    } else {
      setMenuTarget(newValue)
    }
  }

  async function addAdjustmentRecord(attr, currentValue, setter) {
    // wipe all empty records first
    const recList = [
      props.seller.adjustmentPositionsFC.filter(r => r.name !== '' || r.amount !== 0),
      props.seller.adjustmentPositions.filter(r => r.name !== '' || r.amount !== 0),
      props.seller.adjustmentPositionsX1.filter(r => r.name !== '' || r.amount !== 0),
      props.seller.adjustmentPositionsX2.filter(r => r.name !== '' || r.amount !== 0),
      props.seller.adjustmentPositionsX3.filter(r => r.name !== '' || r.amount !== 0)
    ]

    let patchData = {
      adjustmentPositionsFC: recList[0],
      adjustmentPositions: recList[1],
      adjustmentPositionsX1: recList[2],
      adjustmentPositionsX2: recList[3],
      adjustmentPositionsX3: recList[4]
    }

    // then, proceed with the creation
    const newValue = [...patchData[attr], { name: '', amount: 0 }]

    setAdjustmentPositionsFC(recList[0])
    setAdjustmentPositions(recList[1])
    setAdjustmentPositionsX1(recList[2])
    setAdjustmentPositionsX2(recList[3])
    setAdjustmentPositionsX3(recList[4])

    setter(newValue)

    patchData[attr] = newValue

    updateScore()

    const updatedSeller = await props.patchSeller(patchData)

    for (let v of updatedSeller[attr]) {
      if (v.name === '' && v.amount === 0 ) {
        toggleEditMode(v)
      }
    }
  }

  function deleteAdjustment(ap, attr, currentValue, setter) {
    const newValue = currentValue.filter(v => v !== ap)

    setter(newValue)

    let patchData = {}
    patchData[attr] = newValue

    updateScore()

    props.patchSeller(patchData)
  }

  function toggleEditMode(ap) {
    setEditMode(ap)
    setEditValue(ap.amount)
    setEditName(ap.name)
  }

  function fixAdjustmentRowValue(property, checkCondition) {
    let sum = 0
    for (let v of (property || [])) {
      if (checkCondition(v)) sum += v.amount
    }

    return sum.toLocaleString('de-DE', { currency: props.seller.currency })
  }

  return (
    <Accordion
      title="Normalisierungen / Sondereffekte"
      toggleState={props.toggleState}
      body="Unter Sondereffekten verstehen wir Aufwendungen und Erträge, die ausserordentlich, einmalig oder nicht operativ waren. Ziel ist die Darstellung des nachhaltigen operativen „Geschäftes“, indem diese Sondereffekte bereinigt werden, was in der Regel zu einer stärkeren Angebotsgrundlage führt."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
    >
      <div className="callout">
        <p>
          Beispiele für solche Sonder- und Einmaleffekte sind einmalige Beratungs-, Rechts- oder Marketingkosten wie auch Entwicklungskosten, Renovations- oder einmalige Instandhaltungskosten, Kosten für die Einführung neuer IT-Systeme, nicht regelmässig wiederkehrende Kosten für Personalsuchen oder Abfindungszahlungen (Aufwandskorrektur), aber auch „ausserordentliche“ Erträge wie z.B. Versicherungsentschädigungen (Ertragskorrektur).
        </p>
      </div>

      <div className="settings-section-header">
        <h3 className="settings-section-title">FY{new Date().getFullYear()+1} <span className="text-muted">FC</span></h3>
        <button className="button option" onClick={() => addAdjustmentRecord('adjustmentPositionsFC', adjustmentPositionsFC, setAdjustmentPositionsFC)}>+ Eintrag hinzufügen</button>
      </div>
      <table className="DataTable DataTable-bm">
        <thead>
          <tr>
            <th style={{ width: '150px' }}>Betrag</th>
            <th style={{ width: '150px' }}>Korrektur</th>
            <th>Position</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ApRecordRow
            attr='adjustmentPositionsFC'
            target={adjustmentPositionsFC}
            setter={setAdjustmentPositionsFC}
            seller={props.seller}
            editMode={editMode}
            editName={editName}
            editValue={editValue}
            menuTarget={menuTarget}
            setEditName={setEditName}
            setEditValue={setEditValue}
            toggleEditMode={toggleEditMode}
            updateMenuTarget={updateMenuTarget}
            deleteAdjustment={deleteAdjustment}
            saveAndLeaveEditMode={saveAndLeaveEditMode}
            />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="adjustment-summary">
                <div>
                  Ertragskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsFC, x => x.amount < 0)}</span>
                </div>
                <div>
                  Aufwandskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsFC, x => x.amount > 0)}</span>
                </div>
                <div>
                  Gesamt: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsFC, x => true)}</span>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="settings-section-header">
        <h3 className="settings-section-title">FY{new Date().getFullYear()} <span className="text-muted">Budget</span></h3>
        <button className="button option" onClick={() => addAdjustmentRecord('adjustmentPositions', adjustmentPositions, setAdjustmentPositions)}>+ Eintrag hinzufügen</button>
      </div>
      <table className="DataTable DataTable-bm">
        <thead>
          <tr>
            <th style={{ width: '150px' }}>Betrag</th>
            <th style={{ width: '150px' }}>Korrektur</th>
            <th>Position</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ApRecordRow
            attr='adjustmentPositions'
            target={adjustmentPositions}
            setter={setAdjustmentPositions}
            seller={props.seller}
            editMode={editMode}
            editName={editName}
            editValue={editValue}
            menuTarget={menuTarget}
            setEditName={setEditName}
            setEditValue={setEditValue}
            toggleEditMode={toggleEditMode}
            updateMenuTarget={updateMenuTarget}
            deleteAdjustment={deleteAdjustment}
            saveAndLeaveEditMode={saveAndLeaveEditMode}
            />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="adjustment-summary">
                <div>
                  Ertragskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositions, x => x.amount < 0)}</span>
                </div>
                <div>
                  Aufwandskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositions, x => x.amount > 0)}</span>
                </div>
                <div>
                  Gesamt: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositions, x => true)}</span>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="settings-section-header">
        <h3 className="settings-section-title">FY{new Date().getFullYear()-1} <span className="text-muted">IST</span></h3>
        <button className="button option" onClick={() => addAdjustmentRecord('adjustmentPositionsX1', adjustmentPositionsX1, setAdjustmentPositionsX1)}>+ Eintrag hinzufügen</button>
      </div>
      <table className="DataTable DataTable-bm">
        <thead>
          <tr>
            <th style={{ width: '150px' }}>Betrag</th>
            <th style={{ width: '150px' }}>Korrektur</th>
            <th>Position</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ApRecordRow
            attr='adjustmentPositionsX1'
            target={adjustmentPositionsX1}
            setter={setAdjustmentPositionsX1}
            seller={props.seller}
            editMode={editMode}
            editName={editName}
            editValue={editValue}
            menuTarget={menuTarget}
            setEditName={setEditName}
            setEditValue={setEditValue}
            toggleEditMode={toggleEditMode}
            updateMenuTarget={updateMenuTarget}
            deleteAdjustment={deleteAdjustment}
            saveAndLeaveEditMode={saveAndLeaveEditMode}
            />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="adjustment-summary">
                <div>
                  Ertragskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX1, x => x.amount < 0)}</span>
                </div>
                <div>
                  Aufwandskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX1, x => x.amount > 0)}</span>
                </div>
                <div>
                  Gesamt: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX1, x => true)}</span>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="settings-section-header">
        <h3 className="settings-section-title">FY{new Date().getFullYear()-2} <span className="text-muted">IST</span></h3>
        <button className="button option" onClick={() => addAdjustmentRecord('adjustmentPositionsX2', adjustmentPositionsX2, setAdjustmentPositionsX2)}>+ Eintrag hinzufügen</button>
      </div>
      <table className="DataTable DataTable-bm">
        <thead>
          <tr>
            <th style={{ width: '150px' }}>Betrag</th>
            <th style={{ width: '150px' }}>Korrektur</th>
            <th>Position</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ApRecordRow
            attr='adjustmentPositionsX2'
            target={adjustmentPositionsX2}
            setter={setAdjustmentPositionsX2}
            seller={props.seller}
            editMode={editMode}
            editName={editName}
            editValue={editValue}
            menuTarget={menuTarget}
            setEditName={setEditName}
            setEditValue={setEditValue}
            toggleEditMode={toggleEditMode}
            updateMenuTarget={updateMenuTarget}
            deleteAdjustment={deleteAdjustment}
            saveAndLeaveEditMode={saveAndLeaveEditMode}
            />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="adjustment-summary">
                <div>
                  Ertragskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX2, x => x.amount < 0)}</span>
                </div>
                <div>
                  Aufwandskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX2, x => x.amount > 0)}</span>
                </div>
                <div>
                  Gesamt: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX2, x => true)}</span>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>

      <div className="settings-section-header">
        <h3 className="settings-section-title">FY{new Date().getFullYear()-3} <span className="text-muted">IST</span></h3>
        <button className="button option" onClick={() => addAdjustmentRecord('adjustmentPositionsX3', adjustmentPositionsX3, setAdjustmentPositionsX3)}>+ Eintrag hinzufügen</button>
      </div>
      <table className="DataTable DataTable-bm">
        <thead>
          <tr>
            <th style={{ width: '150px' }}>Betrag</th>
            <th style={{ width: '150px' }}>Korrektur</th>
            <th>Position</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <ApRecordRow
            attr='adjustmentPositionsX3'
            target={adjustmentPositionsX3}
            setter={setAdjustmentPositionsX3}
            seller={props.seller}
            editMode={editMode}
            editName={editName}
            editValue={editValue}
            menuTarget={menuTarget}
            setEditName={setEditName}
            setEditValue={setEditValue}
            toggleEditMode={toggleEditMode}
            updateMenuTarget={updateMenuTarget}
            deleteAdjustment={deleteAdjustment}
            saveAndLeaveEditMode={saveAndLeaveEditMode}
            />
        </tbody>
        <tfoot>
          <tr>
            <td colSpan={4}>
              <div className="adjustment-summary">
                <div>
                  Ertragskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX3, x => x.amount < 0)}</span>
                </div>
                <div>
                </div>
                  Aufwandskorrektur: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX3, x => x.amount > 0)}</span>
                <div>
                  Gesamt: <span>{props.seller.currency} {fixAdjustmentRowValue(adjustmentPositionsX3, x => true)}</span>
                </div>
              </div>
            </td>
          </tr>
        </tfoot>
      </table>
    </Accordion>
  )
}