import { useEffect, useState } from 'react';

import CustomSelectable from '../../../../components/CustomSelectable';
import CustomCreatable from '../../../../components/CustomCreatable';

import optionService from '../../../../services/OptionService';
import Formatter from '../../../../services/Formatter';

import { X } from 'react-feather';

import './style.css';


export default function SearchFilter(props) {
  const [showBody, setShowBody] = useState(true)
  const [indicator, setIndicator] = useState(props.indicator)
  const [relation, setRelation] = useState(props.relation)
  const [targetValue, setTargetValue] = useState(props.targetValue)

  const sectorOptions = optionService.sectors.map(o => ({ label: o, value: o }))
  const countryOptions = optionService.countries.map(o => ({ label: o, value: o }))

  const selectorRelationOptions = [
    { label: 'passt auf', value: '$in' },
    { label: 'passt nicht auf', value: '$nin' }
  ]

  const numericRelationOptions = [
    { label: 'ist mindestens', value: '$gte' },
    { label: 'ist genau', value: '$eq' },
    { label: 'ist höchstens', value: '$lte' }
  ]

  let indicatorOptions = [];
  let relationOptions = {};
  let valueOptions = {};
  if (props.entity === 'sellers') {
    valueOptions['locations'] = countryOptions
    valueOptions['sectors'] = sectorOptions

    relationOptions['locations'] = selectorRelationOptions
    relationOptions['sectors'] = selectorRelationOptions

    relationOptions['ebitda'] = numericRelationOptions
    relationOptions['ebit'] = numericRelationOptions
    relationOptions['currentRevenue'] = numericRelationOptions
    relationOptions['employees'] = numericRelationOptions
    relationOptions['foundingYear'] = numericRelationOptions

    indicatorOptions = [
      { label: 'Länder', value: 'locations' },
      { label: 'Branche', value: 'sectors' },
      { label: 'EBITDA', value: 'ebitda' },
      { label: 'EBIT', value: 'ebit' },
      { label: 'Jahresumsatz', value: 'currentRevenue' },
      { label: 'Mitarbeiter', value: 'employees' },
      { label: 'Gründungsjahr', value: 'foundingYear' }
    ]
  } else {
    valueOptions['transactionType'] = [
      { label: 'Strategischer Investor / Unternehmen', value: 'Strategischer Investor / Unternehmen' },
      { label: 'Unabhängige Beteiligungsgesellschaft', value: 'Unabhängige Beteiligungsgesellschaft' },
      { label: 'Privater Investor', value: 'Privater Investor' },
      { label: 'Single/Multi Family Office', value: 'Single/Multi Family Office' },
      { label: 'Öffentliche Hand', value: 'Öffentliche Hand' },
      { label: 'Private Debt Fund', value: 'Private Debt Fund' },
      { label: 'Andere Investoren', value: 'Andere Investoren' }
    ]

    valueOptions['shareModel'] = [
      { label: 'Minderheitbeteiligung', value: 'Minderheitbeteiligung' },
      { label: 'Mehrheitsbeteiligung', value: 'Mehrheitsbeteiligung' },
      { label: 'Private Debt  / Darlehen', value: 'Private Debt  / Darlehen' },
      { label: 'Mezzanine-Kapital', value: 'Mezzanine-Kapital' },
      { label: 'Kapitalerhöhung', value: 'Kapitalerhöhung' }
    ]

    valueOptions['investmentHorizon'] = [
      { label: 'bis 5 Jahre', value: 'bis 5 Jahre' },
      { label: 'bis 10 Jahre', value: 'bis 10 Jahre' },
      { label: '>10 Jahre', value: '>10 Jahre' },
      { label: 'unbeschränkt', value: 'unbeschränkt' }
    ]

    valueOptions['locations'] = countryOptions
    valueOptions['sectors'] = sectorOptions

    relationOptions['transactionType'] = selectorRelationOptions
    relationOptions['shareModel'] = selectorRelationOptions
    relationOptions['investmentHorizon'] = selectorRelationOptions
    relationOptions['locations'] = selectorRelationOptions
    relationOptions['sectors'] = selectorRelationOptions

    relationOptions['employees'] = numericRelationOptions
    relationOptions['foundingYear'] = numericRelationOptions

    indicatorOptions = [
      { label: 'Klassifizierung', value: 'transactionType' },
      { label: 'Beteiligungen', value: 'shareModel' },
      { label: 'Horizont', value: 'investmentHorizon' },
      { label: 'Länder', value: 'locations' },
      { label: 'Branche', value: 'sectors' },
      { label: 'Mitarbeiter', value: 'employees' },
      { label: 'Gründungsjahr', value: 'foundingYear' }
    ]
  }

  useEffect(() => {
    if (props.indicator !== indicator) setIndicator(props.indicator)
    if (props.relation !== relation) setRelation(props.relation)
    if (props.targetValue !== targetValue) setTargetValue(props.targetValue)
  }, [props.indicator, props.relation, props.targetValue, indicator, relation, targetValue])

  function universalSetter(setter, newValue, dataLabel) {
    setter(newValue)

    let newData = {
      indicator: indicator,
      relation: relation,
      targetValue: targetValue
    }
    newData[dataLabel] = newValue

    props.updateFilterParams(newData)
  }

  let filterBody;
  if (showBody) {
    let conditionalValueField;
    if (indicator && indicator.value && valueOptions[indicator.value]) {
      conditionalValueField = (
        <CustomCreatable
          isMulti
          placeholder='Bitte wählen ...'
          classNamePrefix="react-select"
          options={valueOptions[indicator.value]}
          value={Array.isArray(targetValue) ? targetValue.map(o => ({ label: o, value: o })) : targetValue}
          onChange={v => universalSetter(setTargetValue, v.map(e => e.value), 'targetValue')}
          />
      )
    } else {
      conditionalValueField = (
        <div className="form-field">
          <input type="text" value={targetValue} placeholder='Ziel-Wert' onChange={e => universalSetter(setTargetValue, e.target.value, 'targetValue') }/>
        </div>
      )
    }

    filterBody = (
      <div className="search-filter-body">
        <div className="search-filter-option-row">
          <CustomSelectable
            placeholder='Indikator'
            classNamePrefix="react-select"
            options={indicatorOptions}
            value={indicator}
            onChange={v => universalSetter(setIndicator, v, 'indicator')}
            />

          <CustomSelectable
            placeholder='Bedingung'
            classNamePrefix="react-select"
            options={indicator && indicator.value ? relationOptions[indicator.value] : []}
            value={relation}
            onChange={v => universalSetter(setRelation, v, 'relation')}
            />
        </div>

        <div className="search-filter-option-row">
          {conditionalValueField}
        </div>
      </div>
    )
  }

  let formattedTargetValue = '...';
  if (targetValue) {
    if (Array.isArray(targetValue)) {
      formattedTargetValue = targetValue.join(', ')
    } else {
      formattedTargetValue = Formatter.currency(targetValue)
    }
  }

  return (
    <div className="search-filter">
      <div className="search-filter-inner">
        <div className="search-filter-head">
          <div className="search-filter-title" onClick={e => setShowBody(!showBody)}>
            <span className="search-filter-title-fragment">
              {indicator ? indicator.label : 'Neuer Filter'}
            </span>

            <span className="search-filter-title-fragment text-muted">
              {indicator ? (relation ? relation.label : '...') : ''}
            </span>

            <span className="search-filter-title-fragment">
              {indicator ? (relation ? formattedTargetValue : '') : ''}
            </span>
          </div>

          <div className="search-filter-action button circle secondary small" onClick={() => props.deleteFilter()}>
            <X size={16} color='white' />
          </div>
        </div>

        {filterBody}
      </div>
    </div>
  )
}