const predefinedOptions = {
  companyTypes: [
    'GmbH', 'AG', 'GmbH & Co. KG', 'UG', 'OHG', 'GbR', 'KIG', 'KG', 'PartnerG', 'KGaA', 'Ltd.', 'OG', 'KG', 'GesbR', 'StGes', 'Gen', 'Andere'
  ],
  sectors: [
    "Alternative Medizin",
    "Alternative Streitbeilegung",
    "Animation",
    "Anwaltskanzlei",
    "Architektur und Planung",
    "Automobil",
    "Bankwesen",
    "Baumaterialien",
    "Bauwesen",
    "Bekleidung & Mode",
    "Bergbau und Metalle",
    "Berufliche Ausbildung & Coaching",
    "Bewegte Bilder und Film",
    "Bibliotheken",
    "Bildende Kunst",
    "Bildungsmanagement",
    "Biotechnologie",
    "Buchhaltung",
    "Bürgerliche und soziale Organisationen",
    "Chemikalien",
    "Computer- und Netzsicherheit",
    "Computer-Hardware",
    "Computer-Software",
    "Computernetzwerke",
    "Computerspiele",
    "Darstellende Künste",
    "Denkfabriken",
    "Design",
    "Dienstleistungen für Einrichtungen",
    "Dienstleistungen für Einzelpersonen und Familien",
    "Drahtlos",
    "Druckerei",
    "E-Lernen",
    "Einzelhandel",
    "Elektrische/Elektronische Fertigung",
    "Erholungseinrichtungen und -dienstleistungen",
    "Erneuerbare Energien & Umwelt",
    "Exekutivbüro",
    "Finanzdienstleistungen",
    "Fischerei",
    "Fluggesellschaften/Luftfahrt",
    "Forschung",
    "Fotografie",
    "Freizeit",
    "Fundraising",
    "Gastgewerbe",
    "Geschäftsbedarf und Ausrüstung",
    "Gesundheit",
    "Gewerbliche Immobilien",
    "Glas",
    "Glücksspiel und Kasinos",
    "Grafikdesign",
    "Großhandel",
    "Halbleiter",
    "Herstellung von Eisenbahnen",
    "Höhere Bildung",
    "Immobilien",
    "Import und Export",
    "Industrielle Automatisierung",
    "Informationsdienste",
    "Informationstechnologie und Dienstleistungen",
    "Internationale Angelegenheiten",
    "Internationaler Handel und Entwicklung",
    "Internet",
    "Investmentbanking",
    "Justizwesen",
    "Kapitalmärkte",
    "Konsumgüter",
    "Kosmetika",
    "Krankenhaus & Gesundheitswesen",
    "Kunst & Kunsthandwerk",
    "Kunststoffe",
    "Lagerhaltung",
    "Landwirtschaft",
    "Lebensmittel und Getränke",
    "Lebensmittelproduktion",
    "Legislativbüro",
    "Logistik & Lieferkette",
    "Luftfahrt & Raumfahrt",
    "Luxusgüter & Schmuck",
    "Maritim",
    "Marketing und Werbung",
    "Marktforschung",
    "Maschinen",
    "Maschinenbau oder Wirtschaftsingenieurwesen",
    "Medienproduktion",
    "Medizinische Geräte",
    "Medizinische Praxis",
    "Militär",
    "Möbel",
    "Molkerei",
    "Museen & Institutionen",
    "Musik",
    "Nanotechnologie",
    "Öffentliche Ordnung",
    "Öffentliche Sicherheit",
    "Öffentlichkeitsarbeit und Kommunikation",
    "Öl & Energie",
    "Online-Medien",
    "Outsourcing/Offshoring",
    "Papier & Forstprodukte",
    "Personalwesen",
    "Personalwesen & Rekrutierung",
    "Pharmazeutika",
    "Philanthropie",
    "Politische Organisation",
    "Primar-/Sekundarbildung",
    "Programmentwicklung",
    "Psychische Gesundheitspflege",
    "Ranching",
    "Rechtsberatung",
    "Regierungsbeziehungen",
    "Regierungsverwaltung",
    "Religiöse Einrichtungen",
    "Restaurants",
    "Risikokapital und privates Beteiligungskapital",
    "Rundfunkmedien",
    "Schiffbau",
    "Schreiben und Redigieren",
    "Sicherheit & Ermittlungen",
    "Sport",
    "Sportartikel",
    "Strafverfolgung",
    "Supermärkte",
    "Tabak",
    "Telekommunikation",
    "Textilien",
    "Tiefbau",
    "Tiermedizin",
    "Transport/Trucking/Eisenbahn",
    "Übersetzung und Lokalisierung",
    "Umweltdienstleistungen",
    "Unterhaltung",
    "Unterhaltungselektronik",
    "Unternehmensberatung",
    "Veranstaltungsdienste",
    "Verbraucherdienstleistungen",
    "Verlagswesen",
    "Vermögensverwaltung",
    "Verpackung/Frachtzustellung",
    "Verpackungen & Behälter",
    "Versicherung",
    "Versorger",
    "Verteidigung und Raumfahrt",
    "Verwaltung gemeinnütziger Organisationen",
    "Wein & Spirituosen",
    "Zeitungen"
  ],
  countries: [
    'Schweiz',
    'Deutschland',
    'Österreich',
    'Belgien',
    'Niederlande',
    'Luxemburg',
    'Frankreich',
    'Italien',
    'Spanien',
    'Norwegen',
    'Dänemark',
    'Schweden',
    'Finnland',
    'Vereinigtes Königreich',
    'Irland'
  ],
  countryCodes: {
    'Deutschland': 'de',
    'Österreich': 'at',
    'Schweiz': 'ch',
    'Belgien': 'be',
    'Niederlande': 'nl',
    'Luxemburg': 'lu',
    'Frankreich': 'fr',
    'Italien': 'it',
    'Spanien': 'es',
    'Norwegen': 'no',
    'Dänemark': 'dk',
    'Schweden': 'se',
    'Finnland': 'fi',
    'Vereinigtes Königreich': 'uk',
    'Irland': 'ie'
  },
  regions: {
    'Deutschland': [
      'Baden-Württemberg', 'Bayern', 'Berlin', 'Brandenburg', 'Bremen', 'Hamburg',
      'Hessen', 'Mecklenburg-Vorpommern', 'Niedersachsen', 'Nordrhein-Westfalen',
      'Rheinland-Pfalz', 'Saarland', 'Sachsen', 'Sachsen-Anhalt',
      'Schleswig-Holstein', 'Thüringen'
    ],
    'Österreich': [
      'Burgenland', 'Kärnten', 'Niederösterreich', 'Oberösterreich', 'Salzburg',
      'Steiermark', 'Tirol', 'Vorarlberg', 'Wien',
    ],
    'Schweiz': [
      'Zürich', 'Bern', 'Luzern', 'Uri', 'Schwyz', 'Obwalden', 'Nidwalden', 'Glarus',
      'Zug', 'Freiburg', 'Solothurn', 'Basel-Stadt', 'Basel-Landschaft',
      'Schaffhausen', 'Appenzell Ausserrhoden', 'Appenzell Innerrhoden', 'St. Gallen',
      'Graubünden', 'Aargau', 'Thurgau', 'Tessin', 'Waadt', 'Wallis', 'Neuenburg',
      'Genf', 'Jura',
    ],
    'Belgien': [
      'Antwerpen',
      'Ostflandern',
      'Flämisch-Brabant',
      'Limburg',
      'Westflandern',
      'Hennegau',
      'Lüttich',
      'Luxemburg',
      'Namür',
      'Waals-Brabant',
      'Brüssel'
    ],
    'Niederlande': [
      'Groningen',
      'Friesland',
      'Drente',
      'Overijssel',
      'Gelderland',
      'Utrecht',
      'Nordholland',
      'Südholland',
      'Zeeland',
      'Nordbrabant',
      'Limburg',
      'Flevoland'
    ],
    'Luxemburg': [
      'Capellen',
      'Clerf',
      'Diekirch',
      'Echternach',
      'Esch/Alzette',
      'Grevenmacher',
      'Luxemburg',
      'Mersch',
      'Redingen',
      'Remich',
      'Vianden',
      'Wiltz'
    ],
    'Frankreich': [
      'Île-de-France',
      'Auvergne-Rhône-Alpes',
      'Nouvelle-Aquitaine',
      'Hauts-de-France',
      'Provence-Alpes-Côte d’Azur',
      'Centre-Val de Loire',
      'Pays de la Loire',
      'Grand Est',
      'Normandie',
      'Bourgogne-Franche-Comté',
      'Okzitanien',
      'Korsika (Collectivité territoriale)'
    ],
    'Italien': [
      'Lombardei',
      'Kampanien',
      'Latium',
      'Sizilien',
      'Venetien',
      'Piemont',
      'Emilia-Romagna',
      'Apulien',
      'Toskana',
      'Kalabrien',
      'Sardinien',
      'Ligurien',
      'Marken',
      'Abruzzen',
      'Venetien',
      'Südtirol',
      'Umbrien',
      'Basilikata',
      'Molise',
      'Aostatal'
    ],
    'Spanien': [
      'Andalusien',
      'Aragonien',
      'Asturien',
      'Balearische Inseln',
      'Baskenland',
      'Extremadura',
      'Galicien',
      'Kanarische Inseln',
      'Kantabrien',
      'Kastillen und León',
      'Kastillen-La Mancha',
      'Katalonien',
      'La Rioja',
      'Madrid',
      'Marcia',
      'Navarra',
      'Valencia',
      'Ceuta',
      'Melilla'
    ],
    'Norwegen': [
      'Nordnorwegen',
      'Mittelnorwegen',
      'Westnorwegen',
      'Ostnorwegen',
      'Südnorwegen'
    ],
    'Dänemark': [
      'Nordjütland',
      'Mittel-Jütland',
      'Süddänemark',
      'Sjælland',
      'Hovedstaden'
    ],
    'Schweden': [
      'Götaland',
      'Svealand',
      'Norrland'
    ],
    'Finnland': [
      'Åland',
      'Kainuu',
      'Kanta-Häme',
      'Kymenlaakso',
      'Lappland',
      'Mittelfinnland',
      'Mittelösterbotten',
      'Nordkarelien',
      'Nordösterbotten',
      'Nordsavo',
      'Österbotten',
      'Päijät-Häme',
      'Pirkanmaa',
      'Satakunta',
      'Südkarelien',
      'Südösterbotten',
      'Südsavo',
      'Uusimaa',
      'Varsinais-Suomi'
    ],
    'Vereinigtes Königreich': [
      'England',
      'Wales',
      'Schottland',
      'Nordirland'
    ],
    'Irland': [
      'Leinster',
      'Ulster',
      'Munster',
      'Connacht'
    ]
  },
  thresholds: [
    0, 5, 10, 20, 30, 50, 60, 70, 80, 90, 100, 200, 300, 500,
    1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000,
    10000, 20000, 30000, 40000, 50000, 60000, 70000, 80000, 90000,
    100000, 200000, 300000, 400000, 500000, 600000, 700000, 800000, 900000,
    1000000, 2000000, 3000000, 4000000, 5000000, 6000000, 7000000, 8000000, 9000000,
    10000000, 20000000, 30000000, 40000000, 50000000, 60000000, 70000000, 80000000, 90000000,
    100000000, 200000000, 300000000, 400000000, 500000000, 600000000, 700000000, 800000000, 900000000,
    1000000000, 2000000000, 3000000000, 4000000000, 5000000000, 6000000000, 7000000000, 8000000000, 9000000000
  ]
}

export default predefinedOptions;
