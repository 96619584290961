import './style.css';

export default function Conclusion(props) {
  return (
    <div className="ProjectStats">

      <div className="ProjectStats-section">
        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Transaktionsdauer</div>
          <div className="ProjectStat-value">183 Tage</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Beginn</div>
          <div className="ProjectStat-value">20. April 2021</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Bewertung</div>
          <div className="ProjectStat-value">CHF 338M</div>
        </div>
      </div>

      <h3 className="ProjectStats-section-title">Transaktionsstatistik</h3>
      <div className="ProjectStats-section">
        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Dokumente & Assets</div>
          <div className="ProjectStat-value">182 Dateien</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Organisation</div>
          <div className="ProjectStat-value">15 Termine</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Beteiligte Personen</div>
          <div className="ProjectStat-value">28 Teilnehmer</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Phasendauer</div>
          <div className="ProjectStat-value">10-45 Tage</div>
        </div>
      </div>

      <h3 className="ProjectStats-section-title">Persönliche Rekorde</h3>
      <div className="ProjectStats-section">
        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Aktivste Personen</div>
          <div className="ProjectStat-value">J. Doe & T. Miller</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Organisations-Talent</div>
          <div className="ProjectStat-value">R.J. Example</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Fleißigster Teiler</div>
          <div className="ProjectStat-value">M. Neuhauser</div>
        </div>

        <div className="ProjectStats-card">
          <div className="ProjectStat-title">Initiator</div>
          <div className="ProjectStat-value">J. Gischig</div>
        </div>
      </div>
    </div>
  )
}