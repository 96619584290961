import { useState } from 'react';

import Checkbox from '../../../components/Checkbox';

import QuestionForm from './QuestionForm';

import { ChevronUp, X, Trash } from 'react-feather';

export default function MultipleChoiceQuestion(props) {
  const [showBody, setShowBody] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [showQuestionForm, setShowQuestionForm] = useState(false)

  let completeCTA;
  if (!props.question.completed && !props.isSeller) {
    completeCTA = (
      <button className='button small secondary' onClick={() => toggleCompletion()}>
        Anfrage abschließen
      </button>
    )
  }

  let adminActions;
  let buyerActions = []
  if (!props.isSeller) {
    adminActions = [
      <button className="button danger circle small" onClick={() => setShowDeletionConfirmation(!showDeletionConfirmation)}><Trash size={12} /></button>
    ]

    if (!props.subQuestion) {
      buyerActions = [
        <button className="button link small" onClick={() => setShowQuestionForm(true)}>Nachfrage stellen</button>
      ]
    }
  }

  let conditionalInfoBody;
  let conditionalContentBody;
  if (showBody) {
    conditionalInfoBody = (
      <div className="AssessmentItem-info-body">
        <p className="AssessmentItem-intro">{props.question.description}</p>
        <div className="AssessmentItem-actions">
          {completeCTA}
          {buyerActions}
        </div>
      </div>
    )

    let checkboxes = []
    for (let option of props.question.choiceAnswer) {
      checkboxes.push(
        <Checkbox
          narrow
          user={props.user}
          label={option.choice}
          checked={option.checked}
          toggleOption={v => toggleChoice(option, v)}
          />
      )
    }

    conditionalContentBody = (
      <div className="AssessmentItem-content-body">
        {checkboxes}
      </div>
    )
  }

  async function toggleChoice(option, newValue) {
    let newQuestion = Object.assign(props.question)

    for (let i = 0; i < newQuestion.choiceAnswer.length; i++) {
      if (newQuestion.choiceAnswer[i] === option) {
        newQuestion.choiceAnswer[i] = {
          choice: option.choice, checked: newValue
        }
        break;
      }
    }

    await props.updateQuestion(newQuestion)
  }

  async function toggleCompletion() {
    let newQuestion = Object.assign(props.question)
    newQuestion.completed = !props.question.completed

    await props.updateQuestion(newQuestion)
  }

  async function deleteQuestion() {
    await props.deleteQuestion()
    setShowDeletionConfirmation(false)
  }

  const checkedOptions = props.question.choiceAnswer.filter(c => c.checked).length

  async function submitNewQuestion(questionData) {
    let newQuestion = questionData
    let newQuestions = []

    for (let q of props.assessment.questions) {
      if (q === props.question) {
        let parentQuestion = Object.assign(q)
        parentQuestion.questions = [...parentQuestion.questions, newQuestion]
        newQuestions.push(parentQuestion)
      } else {
        newQuestions.push(q)
      }
    }

    await props.patchAssessment({ questions: newQuestions })

    setShowQuestionForm(false)
  }

  let conditionalQuestionForm;
  if (showQuestionForm) {
    conditionalQuestionForm = (
      <QuestionForm
        setShowQuestionOverlay={() => setShowQuestionForm(false)}
        submitNewQuestion={questionData => submitNewQuestion(questionData)}
        />
    )
  }

  let deletionConfirmation;
  if (showDeletionConfirmation) {
    deletionConfirmation = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowDeletionConfirmation(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Bist du sicher?</h3>
              <p className="overlay-intro">
                Wenn du die Anfrage löschst, wird sie für alle Parteien dieser Transaktion entfernt. Diese Aktion kann nicht rückgängig gemacht werden.
              </p>
            </div>

            <div className="overlay-body">
              <div className="overlay-actions">
                <button style={{ marginTop: '12px' }} className="button small danger" onClick={e => deleteQuestion()}>
                  Löschen
                </button>

                <button style={{ marginTop: '12px' }} className="button small secondary" onClick={e => setShowDeletionConfirmation(false)}>
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="AssessmentItem">
      <div className="AssessmentItem-info">
        <div className="AssessmentItem-info-header">
          <h4 className="AssessmentItem-title">
            <span className="AssessmentItem-number">{props.idx}</span> {props.question.question}
          </h4>
          <div className="AssessmentItem-header-actions">
            {adminActions}
            <ChevronUp color="#959ABA" onClick={() => setShowBody(!showBody)} className={showBody ? 'AssessmentItem--header-action' : 'AssessmentItem--header-action inverted'} />
          </div>
        </div>
        {conditionalInfoBody}
      </div>
      <div className="AssessmentItem-content">
        <div className="AssessmentItem-content-header">
          <span className="AssessmentItem-subtitle">{checkedOptions || 'Keine'} Option{checkedOptions === 1 ? '' : 'en'} ausgwählt</span>
          <span className={`badge ${props.question.completed ? 'badge-success' : ''}`}>
            {props.question.completed ? 'Erledigt' : (checkedOptions ? 'In Review' : 'Todo')}
          </span>
        </div>
        {conditionalContentBody}
      </div>

      {conditionalQuestionForm}
      {deletionConfirmation}
    </div>
  )
}