import React, { useState, useMemo, useEffect } from "react"

import logo from '../../assets/logo.svg';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import ProgressBar from './components/ProgressBar';
import CustomSelectable from '../../components/CustomSelectable';
import CustomPhoneInput from '../../components/CustomPhoneInput';
import Checkbox from '../../components/Checkbox';

import ActivityLogger from '../../services/ActivityLogger';
import optionService from '../../services/OptionService';

import './style.css';

export default function Signup(props) {
  const [acceptTOC, setAcceptTOC] = useState(false)
  const [acceptPrivacy, setAcceptPrivacy] = useState(false)
  const [acceptMarketing, setAcceptMarketing] = useState(false)

  const countryOptions = optionService.countries
  const companyOptions = optionService.companyTypes

  const cookies = useMemo(() => new Cookies(), [])

  const [stepComplete, setStepComplete] = useState(false)

  const [step, setStep] = useState(0)

  // STEP 1
  const [formOfAddress, setFormOfAddress] = useState()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')

  // STEP 2
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [password2, setPassword2] = useState('')

  // STEP 3
  const [isSeller, setIsSeller] = useState(false)

  // STEP 4
  const [companyName, setCompanyName] = useState('')
  const [currency, setCurrency] = useState('')
  const [companyType, setCompanyType] = useState('')
  const [address, setAddress] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')

  useEffect(() => {
    if (step === 0) {
      setStepComplete(formOfAddress && firstName !== '' && lastName !== '')
    }

    if (step === 1) {
      setStepComplete(email !== '' && phone !== '' && password !== '' && password2 !== '' && password === password2)
    }

    if (step === 3) {
      setStepComplete(companyName !== '' && currency !== '' && companyType !== '' && zip !== '' && city !== '' && country !== '')
    }

    if (step === 4) {
      setStepComplete(acceptTOC && acceptPrivacy)
    }
  }, [stepComplete, step, formOfAddress, firstName, lastName, email, phone, password, password2, companyName, currency, companyType, zip, city, country, acceptTOC, acceptPrivacy, acceptMarketing])

  function nextStep() {
    setStepComplete(false)
    setStep(step+1)
  }

  let conditionalBackButton;
  if (step > 0) {
    conditionalBackButton = (
      <button type="button" className="Signup-submit button link" onClick={e => setStep(step-1)}>
        zurück
      </button>
    )
  } else {
    conditionalBackButton = <span></span>
  }

  let conditionalNextButton;
  if (stepComplete) {
    conditionalNextButton = (
      <button type="button" className="Signup-submit button secondary" onClick={e => nextStep()}>
        weiter
      </button>
    )
  } else {
    conditionalNextButton = (
      <button type="button" disabled className="Signup-submit button secondary disabled" onClick={e => nextStep()}>
        weiter
      </button>
    )
  }

  let formActions = (
    <div className="form-actions">
      {conditionalBackButton}
      {conditionalNextButton}
    </div>
  )

  function identifyAsSeller(v) {
    setIsSeller(v)
    setStep(step+1)
  }

  async function completeOnboarding() {
    // create a user
    const userData = {
      isAdmin: false,
      username: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      company: companyName,
      phone1: phone,
      website: email.split('@')[1]
    }

    const r1 = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: userData,
      url: `${Env.url}/users`
    })

    const user = r1.data

    if (user) {
      // create a buyer or seller
      let subjectName = isSeller ? 'sellers' : 'buyers'
      const subjectData = {
        user: { _id: user._id },
        companyName: companyName,
        address: address,
        zip: zip,
        city: city,
        country: country.value,
        locations: country.value ? [country.value] : [],
        type: companyType.value,
        name: companyName,
        contactFirstName: firstName,
        contactLastName: lastName,
        contactEmail: email,
        contactAddress1: address,
        contactZip: zip,
        contactCity: city,
        contactCountry: country.value,
        contactPhone: phone,
        currency: currency.value
      }

      const r2 = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': user.token
        },
        data: subjectData,
        url: `${Env.url}/${subjectName}`
      })

      const subject = r2.data

      if (subject) {
        try {
          const user = await axios({
            method: 'POST',
            headers: {
              'content-type': 'application/json',
            },
            data: {
              username: email,
              password: password
            },
            url: `${Env.url}/users/login`
          });

          if (user.data) {
            await ActivityLogger.log({
              user: user.data,
              description: 'hat sich erstmalig angemeldet',
              category: 'Auth'
            })

            props.signInUser(user.data)
          }
        } catch (e) {
          alert('E-Mail oder Passwort sind ungültig.')
        }

        document.location = '/'
      }

    }
  }

  let activeStep;
  switch (step) {
    case 1:
      activeStep = (
        <div className="Signup-form-active-frame">
          <div className="Signup-header">
            <h1 className="Signup-title">Account Informationen</h1>
          </div>

          <div className="form-field">
            <label>Vertrauliche E-Mail</label>
            <input type="email" value={email} onChange={e => setEmail(e.target.value) }/>
          </div>

          <div className="form-field">
            <label>Telefon</label>
            <CustomPhoneInput
              country='ch'
              value={phone}
              onChange={v => setPhone(v)}
              />
          </div>

          <div className="form-field">
            <label>Passwort</label>
            <input type="password" value={password} onChange={e => setPassword(e.target.value) }/>
          </div>

          <div className="form-field">
            <label>Passwort wiederholen</label>
            <input type="password" value={password2} onChange={e => setPassword2(e.target.value) }/>
          </div>

          {formActions}
        </div>
      )
      break;
    case 2:
      activeStep = (
        <div className="Signup-form-active-frame">
          <div className="Signup-header">
            <h1 className="Signup-title">Wie nutzt du <strong>Speer</strong>?</h1>
          </div>

          <div className="Signup-sections Signup-sections-horizontal">
            <button type="button" className="Signup-section-cta button secondary small" onClick={e => identifyAsSeller(false)}>
              Als Investor
            </button>

            <button type="button" className="Signup-section-cta button secondary small" onClick={e => identifyAsSeller(true)}>
              Als Unternehmer
            </button>
          </div>
        </div>
      )
      break;
    case 3:
      activeStep = (
        <div className="Signup-form-active-frame">
          <div className="Signup-header">
            <h1 className="Signup-title">Neues <strong>{isSeller ? 'Unternehmer' : 'Investor' }profil</strong></h1>
          </div>

          <div className="form-field">
            <label>Unternehmen</label>
            <input type="text" value={companyName} onChange={e => setCompanyName(e.target.value)} />
          </div>

          <div className="Settings-fields Settings-fields-c2">
            <div className="form-field">
              <label>Währung</label>
              <CustomSelectable
                placeholder=''
                classNamePrefix="react-select"
                options={[
                  { label: 'EUR', value: 'EUR' },
                  { label: 'CHF', value: 'CHF' },
                  { label: 'USD', value: 'USD' }
                ]}
                value={currency}
                onChange={v => setCurrency(v)}
                />
            </div>

            <div className="form-field">
              <label>Gesellschaftsform</label>
              <CustomSelectable
                placeholder=''
                classNamePrefix="react-select"
                options={companyOptions.map(o => ({ label: o, value: o }))}
                value={companyType}
                onChange={v => setCompanyType(v)}
                />
            </div>
          </div>

          <div className="form-field">
            <label>Straße & Hausnummer</label>
            <input type="text" value={address} onChange={e => setAddress(e.target.value)} />
          </div>

          <div className="Settings-fields Settings-fields-c2">
            <div className="form-field">
              <label>Postleitzahl</label>
              <input type="text" value={zip} onChange={e => setZip(e.target.value)} />
            </div>

            <div className="form-field">
              <label>Stadt</label>
              <input type="text" value={city} onChange={e => setCity(e.target.value)} />
            </div>
          </div>

          <div className="form-field">
            <label>Land</label>
            <CustomSelectable
              placeholder=''
              classNamePrefix="react-select"
              options={countryOptions.map(o => ({ label: o, value: o }))}
              value={country}
              onChange={v => setCountry(v)}
              />
          </div>

          {formActions}
        </div>
      )
      break;
    case 4:
      activeStep = (
        <div className="Signup-form-active-frame">
          <div className="Signup-header">
            <h1 className="Signup-title">Vertraulichkeit</h1>
          </div>

          <div className="Signup-sections">
            <div className="Signup-section Signup-section-center">
              <p className="text-muted">
                <a href="/" className="base">Terms of Service</a> gelesen und akzeptiert
              </p>
              <Checkbox
                narrow
                user={props.user}
                label="Erledigt"
                checked={acceptTOC}
                toggleOption={v => setAcceptTOC(true)}
                />
            </div>

            <div className="Signup-section Signup-section-center">
              <p className="text-muted">
                <a href="/" className="base">Privacy Policy</a> gelesen und akzeptiert
              </p>
              <Checkbox
                narrow
                user={props.user}
                label="Erledigt"
                checked={acceptPrivacy}
                toggleOption={v => setAcceptPrivacy(true)}
                />
            </div>

            <div className="Signup-section Signup-section-center">
              <p className="text-muted">
                Dürfen wir dich über Angebote informieren?
              </p>
              <Checkbox
                narrow
                user={props.user}
                label="Passt für mich"
                checked={acceptMarketing}
                toggleOption={v => setAcceptMarketing(true)}
                />
            </div>
          </div>

          <div className="Signup-section Signup-section-negative-space">
            {formActions}
          </div>
        </div>
      )
      break;
    case 5:
      activeStep = (
        <div className="Signup-form-active-frame">
          <div className="Signup-header">
            <h1 className="Signup-title"><strong>Willkommen</strong> in Speer</h1>
            <p className="Signup-intro">Wir prüfen deine Informationen. Nach erfolgreicher Verifikation wird dein Profil sichtbar in Speer.</p>
          </div>

          <div style={{ textAlign: 'center' }}>
            <button type="button" className="Signup-submit button primary" onClick={e => completeOnboarding()}>
              Los geht's
            </button>
          </div>
        </div>
      )
      break;
    default:
      activeStep = (
        <div className="Signup-form-active-frame">
          <div className="Signup-header">
            <h1 className="Signup-title">Persönliche <strong>Angaben</strong></h1>
          </div>

          <div className="form-field">
            <label>Anrede</label>
            <CustomSelectable
              placeholder=''
              classNamePrefix="react-select"
              options={[
                { label: 'Herr', value: 'Herr' },
                { label: 'Frau', value: 'Frau' },
                { label: 'Dr.', value: 'Dr.' },
                { label: 'Prof.', value: 'Prof.' },
                { label: 'Prof. Dr.', value: 'Prof. Dr.' }
              ]}
              value={formOfAddress}
              onChange={v => setFormOfAddress(v)}
              />
          </div>

          <div className="form-field">
            <label>Vorname</label>
            <input type="text" value={firstName} onChange={e => setFirstName(e.target.value) }/>
          </div>

          <div className="form-field">
            <label>Nachname</label>
            <input type="text" value={lastName} onChange={e => setLastName(e.target.value) }/>
          </div>

          {formActions}
        </div>
      )
      break;
  }

  return (
    <div className="Signup">
      <div className="Signup-inner">
        <div className="Signup-container fade-in-up">
          <ProgressBar activeStep={step} setStep={e => setStep(e)}/>

          <div className="Signup-form">
            {activeStep}
          </div>

        </div>

        <img
          alt=""
          src={logo}
          className="Signup-logo"
          />
      </div>
    </div>
  )
}