import './style.css';

import speerLogo from '../../assets/logo.svg';
import speerLogoLight from '../../assets/logo_light.svg';

import MenuBar from '../MenuBar';

import { Search, Grid, Box, Briefcase, LogOut, Sliders, Cpu, Aperture } from 'react-feather';

export default function Aside(props) {
  let settingsLink;
  if (!props.user.isAdmin) {
    settingsLink = (
      <div
        className={props.scope === 'settings' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
        onClick={() => document.location = '/settings' }
        >
        <div className="Sidebar-link-icon"><Sliders size={15} color='#656FA4' /></div>
        <div className="Sidebar-link-label">Einstellungen</div>
      </div>
    )
  }
  return (
    <div className="Aside">
      <MenuBar darkMode={props.darkMode} scope={props.scope} user={props.user} signOutUser={() => props.signOutUser()}/>
      <div className="Sidebar">
        <div className="Sidebar-inner">
          <div className="Sidebar-section Sidebar-section--narrow">
            <img src={props.darkMode ? speerLogoLight : speerLogo} alt="Speer Logo" className="fade-in" />
          </div>
          <div className="Sidebar-section Sidebar-section--narrow">
            <div
              className={props.scope === 'dashboard' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/' }
              >
              <div className="Sidebar-link-icon">
                <Grid size={15} color='#656FA4' />
                </div>
              <div className="Sidebar-link-label">Dashboard</div>
            </div>
          </div>

          <div className="Sidebar-section">
            <div className="Sidebar-section-header">
              <span className="Sidebar-section-title">Meine Profile</span>
            </div>
            <div
              className={props.scope === 'buyer-profiles' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/buyer-profiles' }
              >
              <div className="Sidebar-link-icon"><Box size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Investorenprofile</div>
            </div>
            <div
              className={props.scope === 'seller-profiles' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/seller-profiles' }
              >
              <div className="Sidebar-link-icon"><Briefcase size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Unternehmerprofile</div>
            </div>
          </div>

          <div className="Sidebar-section">
            <div className="Sidebar-section-header">
              <span className="Sidebar-section-title">Projekte</span>
            </div>
            <div
              className={props.scope === 'buyer-projects' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/buyer-projects' }
              >
              <div className="Sidebar-link-icon"><Box size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">{props.user.isAdmin ? 'Nach Investoren' : 'Als Investor'}</div>
            </div>
            <div
              className={props.scope === 'seller-projects' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/seller-projects' }
              >
              <div className="Sidebar-link-icon"><Briefcase size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">{props.user.isAdmin ? 'Nach Unternehmen' : 'Als Unternehmer'}</div>
            </div>
          </div>

          <div className="Sidebar-section">
            <div className="Sidebar-section-header">
              <span className="Sidebar-section-title">Suche</span>
            </div>
            <div
              className={props.scope === 'search' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/search/new' }
              >
              <div className="Sidebar-link-icon"><Search size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Quick Search</div>
            </div>
            <div
              className='Sidebar-link'
              onClick={() => document.location = '/search/new' }
              >
              <div className="Sidebar-link-icon"><Aperture size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Smart Search™</div>
            </div>
            <div
              className={props.scope === 'subscriptions' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/subscriptions' }
              >
              <div className="Sidebar-link-icon"><Cpu size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Speer Scouts™</div>
            </div>
          </div>

          <div className="Sidebar-section">
            {settingsLink}
            <div className="Sidebar-link" onClick={() => props.signOutUser()}>
              <div className="Sidebar-link-icon"><LogOut size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Abmelden</div>
            </div>
          </div>
        </div>
      </div>

      <div className="Content">
        {props.children}
      </div>
    </div>
  )
}