import React, { useState, useEffect, useMemo } from "react"

import { MoreHorizontal, ArrowDown, Check, X, Eye, Edit, Server } from 'react-feather';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Tooltip from '../../components/Tooltip';

import './style.css';

export default function FileCard(props) {
  const [asset, setAsset] = useState()
  const [fetchedAsset, setFetchedAsset] = useState(false)
  const [additionalUsers, setAdditionalUsers] = useState([])
  const [project, setProject] = useState({})

  const [fileName, setFileName] = useState('')
  const [fileSize, setFileSize] = useState(0)
  const [fileType, setFileType] = useState('')
  const [fileSource, setFileSource] = useState('')

  const [showAcl, setShowAcl] = useState('')

  const [editMode, setEditMode] = useState(false)
  const [newFileName, setNewFileName] = useState('')

  const [isAdmin, setIsAdmin] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [isReader, setIsReader] = useState(false)
  const [isEditor, setIsEditor] = useState(false)
  const [isManager, setIsManager] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchAsset() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assets/${props.asset._id}`
      })

      setAsset(response.data)

      // if this is a project asset, fetch the project
      if (response.data.project) {
        const r2 = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('p4itoken')
          },
          url: `${Env.url}/projects/${response.data.project}`
        })

        setProject(r2.data)
      }

      // fetch additional users
      const ctrlUsers = [
        response.data.owner._id.toString(),
        ...response.data.canRead.map(v => v.toString()),
        ...response.data.canEdit.map(v => v.toString()),
        ...response.data.canDelete.map(v => v.toString())
      ]

      let fetchedUsers = []
      for (let au of (props.additionalUsers || [])) {
        if (ctrlUsers.indexOf(au._id.toString()) === -1) {
          // fetch this user
          const response = await axios({
            method: 'GET',
            headers: {
              'content-type': 'application/json',
              'Authorization': cookies.get('p4itoken')
            },
            url: `${Env.url}/users/${au._id}`
          })
          fetchedUsers.push(response.data)

        }
      }

      setAdditionalUsers(fetchedUsers)
    }

    if (props.asset) {
      let newFileName = props.asset.name.split('.')[0].slice(0, 19)
      if (newFileName.length > 18) newFileName += '...'
      setFileName(newFileName)

      let newFileSize = (props.asset.size/1000).toFixed(1)
      if (newFileSize > 1000) {
        setFileSize(`${(newFileSize/1000).toFixed(1)}Mb`)
      } else {
        setFileSize(`${newFileSize}Kb`)
      }

      setFileType(props.asset.source.split('.').pop())
      setFileSource(props.asset.source)

      const uid = props.user._id.toString()
      const oid = (props.asset.owner._id || props.asset.owner).toString()
      const rids = props.asset.canRead.map(r => r._id ? r._id.toString() : r.toString())
      const eids = props.asset.canEdit.map(e => e._id ? e._id.toString() : e.toString())
      const dids = props.asset.canDelete.map(d => d._id ? d._id.toString() : d.toString())

      if (props.user.isAdmin) setIsAdmin(true)
      if (oid === uid) setIsOwner(true)
      if (rids.includes(uid)) setIsReader(true)
      if (eids.includes(uid)) setIsEditor(true)
      if (dids.includes(uid)) setIsManager(true)

      if (props.user.isAdmin || props.asset.owner === props.user._id || props.asset.canDelete.includes(props.user._id)) {
        // higher priviledged users can see more user details
        if (!fetchedAsset) {
          fetchAsset()
          setFetchedAsset(true)
        }
      }
    }
  }, [props.asset, props.user, cookies, fetchedAsset, props.additionalUsers])

  function toggleEditMode() {
    setEditMode(!editMode)
    if (newFileName === '') setNewFileName(props.asset.name.split('.')[0])
  }

  function renameFile() {
    setEditMode(false)
    props.patchFile({ name: `${newFileName}.${props.asset.name.split('.').pop()}` })
    setNewFileName('')
  }

  function toggleAcl() {
    setShowAcl(!showAcl)
    props.setMenuTarget()
  }

  async function toggleFrom(propertyName, user) {
    let patchData = {};
    if (props.asset[propertyName].includes(user._id.toString())) {
      patchData[propertyName] = props.asset[propertyName].filter(uid => uid !== user._id)
    } else {
      patchData[propertyName] = [...props.asset[propertyName], user._id]
    }

    await props.patchFile(patchData)
    setAsset()
    setFetchedAsset(false)
  }

  // only admins, owners and readers can see the file card
  if (isAdmin || isOwner || isReader) {
    let popoverMenu;
    if (isAdmin) {
      // full menu
      popoverMenu = (
        <div className="popover-menu">
          <div className="popover-action" onClick={() => toggleEditMode()}>
            <span className="popover-action-label">Dokument umbenennen</span>
          </div>
          <div className={`popover-action ${showAcl ? 'active' : ''}`} onClick={() => toggleAcl()}>
            <span className="popover-action-label">Berechtigungen {showAcl ? 'ausblenden' : 'verwalten'}</span>
          </div>
          <div className="popover-action" onClick={() => props.setMenuTarget()}>
            <a className="popover-action-label" href={fileSource} download>Dokument herunterladen</a>
          </div>
          <div className="popover-action danger">
            <span className="popover-action-label" onClick={() => props.deleteFile()}>Dokument löschen</span>
          </div>
        </div>
      )
    } else if (isOwner || isManager) {
      // full menu without acls
      popoverMenu = (
        <div className="popover-menu">
          <div className="popover-action" onClick={() => toggleEditMode()}>
            <span className="popover-action-label">Dokument umbenennen</span>
          </div>
          <div className="popover-action" onClick={() => props.setMenuTarget()}>
            <a className="popover-action-label" href={fileSource} download>Dokument herunterladen</a>
          </div>
          <div className="popover-action danger">
            <span className="popover-action-label" onClick={() => props.deleteFile()}>Dokument löschen</span>
          </div>
        </div>
      )
    } else if (isEditor) {
      // constrained menu
      popoverMenu = (
        <div className="popover-menu">
          <div className="popover-action" onClick={() => toggleEditMode()}>
            <span className="popover-action-label">Dokument umbenennen</span>
          </div>
          <div className="popover-action" onClick={() => props.setMenuTarget()}>
            <a className="popover-action-label" href={fileSource} download>Dokument herunterladen</a>
          </div>
        </div>
      )
    } else {
      // read only menu
      popoverMenu = (
        <div className="popover-menu">
          <div className="popover-action" onClick={() => props.setMenuTarget()}>
            <a className="popover-action-label" href={fileSource} download>Dokument herunterladen</a>
          </div>
        </div>
      )
    }

    let fileCardLabel;
    let fileCardActions;
    if (editMode) {
      fileCardLabel = (
        <div className="file-card-label">
          <div className="form-field no-margin">
            <input type="text" value={newFileName} onChange={e => setNewFileName(e.target.value) }/>
          </div>
        </div>
      )

      fileCardActions = (
        <div className="file-card-actions">
          <button className="file-card-action button small circle secondary" onClick={() => renameFile()}>
            <Check size={15} />
          </button>
        </div>
      )
    } else {
      fileCardLabel = (
        <div className="file-card-label">
          <span className="file-card-title">{fileName}</span>
          <span className="file-card-meta">{fileType}, {fileSize}</span>
        </div>
      )

      let conditionalActionMenu;
      if ((isAdmin || isOwner || isEditor || isManager) && props.setMenuTarget) {
        conditionalActionMenu = (
          <>
            <button
              className="file-card-action button small circle secondary"
              onClick={() => props.setMenuTarget(props.menuTarget === props.asset ? undefined : props.asset)}>
              <MoreHorizontal size={15} />
            </button>
    
            <div className={`popover-container extra-offset ${props.menuTarget === props.asset ? '' : 'hidden'}`}>
              <div className="popover">
                {popoverMenu}
              </div>
            </div>
          </>
        )
      }

      fileCardActions = (
        <div className="file-card-actions">
          <a className="file-card-action button small circle primary" href={fileSource} download rel="noreferrer">
            <ArrowDown size={15} />
          </a>
          {conditionalActionMenu}
        </div>
      )
    }

    let tableRows = [];
    if (asset) {
      const allUsers = [asset.owner, ...asset.canRead, ...asset.canEdit, ...asset.canDelete, ...additionalUsers]
      let visibleUsers = {}
      for (let u of allUsers) {
        function checkOrCross(acl, usr, callback) {
          if (acl.includes(usr) || usr === props.asset.owner) {
            return <Check size={16} color='limegreen' onClick={() => callback()} className="action" />
          } else {
            return <X size={16} color='tomato' onClick={() => callback()} className="action" />
          }
        }

        // avoid null users
        if (u && !visibleUsers[u._id]) {
          let uName = `${u.firstName} ${u.lastName}`

          if (project._id) {
            if (u.sellers.includes(project.seller._id) || u.sellers.map(s => s._id).includes(project.seller._id)) {
              uName = project.seller.name;
            } else {
              uName = project.buyer.name;
            }
          }

          let blurClasses;
          if (!isAdmin && (u._id !== props.asset.owner && (!project._id || project.step < 1))) {
            blurClasses = 'blurred'
          }

          tableRows.push(
            <tr>
              <td className={blurClasses}>{uName}</td>
              <td>{checkOrCross(props.asset.canRead, u._id, () => toggleFrom('canRead', u))}</td>
              <td>{checkOrCross(props.asset.canEdit, u._id, () => toggleFrom('canEdit', u))}</td>
              <td>{checkOrCross(props.asset.canDelete, u._id, () => toggleFrom('canDelete', u))}</td>
            </tr>
          )

          visibleUsers[u._id] = u
        }
      }
    }

    let conditionalAcl;
    if (showAcl) conditionalAcl = (
      <div className="file-card-acl">
        <table className="DataTable admin-actions th-right">
          <thead>
            <tr>
              <th>Benutzer</th>
              <th>
                <Tooltip text="Lesen"><Eye size={14} /></Tooltip>
              </th>
              <th>
                <Tooltip text="Bearbeiten"><Edit size={14} /></Tooltip>
              </th>
              <th>
                <Tooltip text="Vollzugriff"><Server size={14} /></Tooltip>
              </th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>
      </div>
    )

    return (
      <div className={`file-card-container ${props.className}`}>
        <div className="file-card">
          {fileCardLabel}
          {fileCardActions}
        </div>

        {conditionalAcl}
      </div>
    )
  } else {
    return (
      <div className={props.className}>
        <p className="text-muted placeholder-text">Die hochgeladene Datei muss zunächst noch durch Speer freigegeben werden.</p>
      </div>
    )
  }
}