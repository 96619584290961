import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import ProfileRow from '../../components/ProfileRow';

import { PlusCircle, X, ArrowLeft, Search as Find } from 'react-feather';

import SearchFilter from '../Search/components/SearchFilter';

import '../Search/style.css';

export default function Subscription(props) {
  const PAGESIZE = 3

  const [subscription, setSubscription] = useState({})
  const [entity, setEntity] = useState()
  const [records, setRecords] = useState([])
  const [recordsCount, setRecordsCount] = useState(0)
  const [fetchedRecords, setFetchedRecords] = useState(false)
  const [doneFetching, setDoneFetching] = useState(false)
  const [filterQuery, setFilterQuery] = useState('')

  const [showOverlay, setShowOverlay] = useState(false)
  const [subscriptionName, setSubscriptionName] = useState('')

  const [filterCounter, setFilterCounter] = useState(0)
  const [filterParams, setFilterParams] = useState([])

  const [configuredFilters, setConfiguredFilters] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  const { id } = useParams()

  useEffect(() => {
    async function configureFilters() {
      // fetch the search params
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/searches/${id}`
      })

      setSubscription(response.data)
      setFilterParams(JSON.parse(response.data.params))
      setEntity(response.data.scope)
    }

    async function fetchRecords() {
      let searchData = {}
      if (filterQuery) {
        searchData.name = { $regex: filterQuery }
      }

      for (let fp of filterParams) {
        // dismiss incomplete filters
        if (fp.indicator && fp.relation && fp.targetValue) {
          let queryParam = {}
          queryParam[fp.relation.value] = fp.targetValue

          searchData[fp.indicator.value] = queryParam
        }
      }

      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          filters: searchData,
          options: {
            limit: PAGESIZE,
            skip: records.length
          }
        },
        url: `${Env.url}/${entity}/search`
      })

      setRecords([...records, ...response.data.records])
      setRecordsCount(response.data.count)
      setDoneFetching(true)
    }

    if (!entity && !configuredFilters) {
      configureFilters()
      setConfiguredFilters(true)
    }

    if (!fetchedRecords && entity) {
      setDoneFetching(false)
      fetchRecords()
      setFetchedRecords(true)
    }
  }, [fetchedRecords, entity, cookies, filterParams, filterQuery, configuredFilters, id, records])

  async function saveAsSubscription(evt) {
    evt.preventDefault()

    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: {
        author: props.user._id,
        params: JSON.stringify(filterParams),
        title: subscriptionName,
        scope: entity
      },
      url: `${Env.url}/searches`
    })

    setSubscriptionName('')
    setShowOverlay(false)
  }

  let tableRows = [];
  for (let rec of records) {
    tableRows.push(
      <ProfileRow rec={rec} user={props.user} patchUser={props.patchUser} entity={entity} />
    )
  }

  function updateEntity(newEntity) {
    setEntity(newEntity)
    setRecords([])
    setFetchedRecords(false)
  }

  function updateFilterQuery(value) {
    setFilterQuery(value)
    if (doneFetching) {
      updateEntity(entity)
    }
  }

  function updateFilterParams(idx, v) {
    let newFilterParams = []
    for (let fp of filterParams) {
      if (fp.idx === idx) {
        newFilterParams.push({
          idx: idx,
          indicator: v.indicator,
          relation: v.relation,
          targetValue: v.targetValue
        })
      } else {
        newFilterParams.push(fp)
      }
    }

    setFilterParams(newFilterParams)
    updateEntity(entity)
  }

  function addFilter() {
    setFilterParams([...filterParams, { idx: Number(filterCounter) }])
    setFilterCounter(filterCounter+1)
  }

  function deleteFilter(idx) {
    let newFilterParams = []
    for (let fp of filterParams) {
      if (fp.idx !== idx) newFilterParams.push(fp)
    }

    setFilterParams(newFilterParams)
  }

  let tableHeaders;
  if (entity === 'buyers') {
    tableHeaders = (
      <tr>
        <th></th>
        <th></th>
        <th>Name</th>
        <th>Ausrichtung</th>
        <th>Horizont</th>
        <th>Investment-Fokus</th>
        <th>Status</th>
      </tr>
    )
  } else {
    tableHeaders = (
      <tr>
        <th></th>
        <th></th>
        <th>Name</th>
        <th>Branche</th>
        <th>Umsatz</th>
        <th>EBIT</th>
        <th>EBITDA</th>
      </tr>
    )
  }

  let filterList;
  if (filterParams.length) {
    filterList = [];
    for (let fp of filterParams) {
      filterList.push(
        <SearchFilter
          idx={fp.idx}
          indicator={fp.indicator}
          entity={entity}
          relation={fp.relation}
          targetValue={fp.targetValue}
          deleteFilter={() => deleteFilter(fp.idx)}
          updateFilterParams={(v) => updateFilterParams(fp.idx, v)}
          />
      )
    }
  } else {
    filterList = (
      <p className="text-muted centered">
        <small>Du hast keine Filter aktiviert. Klicke das <PlusCircle size={14} color='#3661EC' onClick={() => addFilter()} /> Symbol um einen Filter zu deiner Suche hinzuzufügen.</small>
      </p>
    )
  }

  let conditionalSaveButton;
  if (filterParams.length) {
    conditionalSaveButton = (
      <button className="secondary small" onClick={e => setShowOverlay(true)}>Als Speer Scout™ speichern</button>
    )
  }

  let conditionalOverlay;
  if (showOverlay) {
    conditionalOverlay = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowOverlay(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Neuen Speer Scout™ anlegen</h3>
              <p className="overlay-intro">
                Speichere dein Speer Scout™ und bleibe auf dem Laufenden. Speer Scouts™ werden kontinuierlich erweitert und helfendir dabei, passende Opportunitäten zu sichten, noch bevor sie entstehen.
              </p>
            </div>

            <form className="overlay-body" onSubmit={e => saveAsSubscription(e)}>
              <div className="form-field">
                <label>Benenne deinen Speer Scout™</label>
                <input type="text" value={subscriptionName} onChange={e => setSubscriptionName(e.target.value)} />
              </div>
  
              <button type="submit"
                style={{ marginTop: '12px' }}
                className="button primary full-width"
                >
                Speer Scout™ speichern
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  let conditionalTableAction;
  if (props.user.isAdmin) {
    conditionalTableAction = (
      <div className="TableAction">
        <span className="TableAction-action">
          <input
            type="text"
            className="search"
            placeholder={`${entity === 'buyers' ? 'Investoren' : 'Unternehmen'} filtern ...`}
            value={filterQuery}
            onChange={e => updateFilterQuery(e.target.value)}
            />
        </span>
      </div>
    )
  }

  let conditionalLoadMore;
  if (recordsCount > records.length) {
    conditionalLoadMore = (
      <div className="load-more">
        <button className="button primary ghost small" onClick={() => setFetchedRecords(false)}>Weitere laden ...</button>
      </div>
    )
  }

  return (
    <div className="Search">
      <Aside darkMode={props.darkMode} user={props.user} scope="search" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title">
                <strong>{subscription ? subscription.title : 'Speer Scout™'}</strong>
              </h1>
              <h5 className="PanelHeader-subline">{filterParams.length} aktive{filterParams.length === 1 ? 'r' : ''} Filter</h5>
            </div>

            <div className="Breadcrumbs">
              <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                <ArrowLeft size={18} color='#6B6E97' />
              </div>

              <div className="Breadcrumbs-path">
                <a href="/">Dashboard</a>
                <span>/</span>
                <a href="/search">Suche</a>
                <span>/</span>
                <a href="/" className="active">{entity === 'buyers' ? 'Investoren' : 'Unternehmen'}</a>
              </div>

              {conditionalSaveButton}
            </div>

            <div className="TableHeader">
              <div className="TableControl">
                <div className="toggle-switch">
                  <div
                    className={`toggle-option ${entity === 'sellers' ? 'active' : ''}`}
                    onClick={() => updateEntity('sellers')}
                    >
                      Unternehmen
                    </div>
                  <div
                    className={`toggle-option ${entity === 'buyers' ? 'active' : ''}`}
                    onClick={() => updateEntity('buyers')}
                    >
                      Investoren
                    </div>
                </div>
                <div className="TableCounter">{recordsCount} Ergebnis{records.length !== 1 ? 'se' : ''}</div>
              </div>

              {conditionalTableAction}
            </div>

            <table className="DataTable">
              <thead>
                {tableHeaders}
              </thead>
              <tbody>
                {tableRows}
              </tbody>
            </table>

            {conditionalLoadMore}
          </div>

          <div className="ContentPanel-sidebar">
            <div className="Widget fade-in-up">
              <div className="Widget-header">
                <h3 className="Widget-title">Suchbegriff</h3>
              </div>

              <div className="Widget-body">
                <div className="SearchPrompt-form SearchPrompt-form-inline">
                  <input type="text" value={filterQuery} onChange={e => updateFilterQuery(e.target.value)} className="SearchPrompt-input" placeholder="Family Offices Schweiz" autoFocus />
                  <div className="SearchPrompt-input-icon"><Find /></div>
                </div>
              </div>
            </div>

            <div className="Widget fade-in-up">
              <div className="Widget-header">
                <h3 className="Widget-title">{filterParams.length} aktive{filterParams.length === 1 ? 'r' : ''} Filter</h3>
                <div className="Widget-action" onClick={() => addFilter()}>
                  <PlusCircle size={16} color='#D8DAE9' />
                </div>
              </div>

              <div className="Widget-body">
                {filterList}
              </div>
            </div>
          </div>
        </div>

        {conditionalOverlay}
      </Aside>
    </div>
  )
}