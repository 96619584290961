import React, { useEffect, useState } from "react"

import './style.css';

export default function NumberInput(props) {
  const [value, setValue] = useState('')

  function updateValue(newValue) {
    const nakedValue = parseInt(newValue.replace(/\./g, ''))

    if (Number.isNaN(nakedValue)) {
      if (newValue === '-') {
        props.onChange()
        setValue('-')
      } else {
        props.onChange()
        setValue('')
      }
    } else {
      props.onChange(nakedValue)
      setValue(nakedValue.toLocaleString('de'))
    }
  }

  useEffect(() => {
    if (props.value && !Number.isNaN(props.value)) {
      setValue(props.value.toLocaleString('de'))
    } else {
      setValue('')
    }
  }, [props.value])

  let conditionalSuffix;
  if (props.suffix) {
    conditionalSuffix = <span className="input-label">{props.suffix || 'CHF'}</span>
  }

  let stateBasedInput;
  if (props.restrictAccess !== undefined) {
    if (props.restrictAccess) {
      stateBasedInput = (
        <input
          type="text"
          value={value}
          placeholder={props.placeholder}
          onChange={e => updateValue(e.target.value)}
          />
      )
    } else {
      stateBasedInput = (
        <input
          disabled
          type="text"
          value={value}
          className="disabled"
          placeholder={props.placeholder}
          />
      )
    }
  } else {
    stateBasedInput = (
      <input
        type="text"
        value={value}
        placeholder={props.placeholder}
        onChange={e => updateValue(e.target.value)}
        />
    )
  }

  return (
    <div className="number-input">
      {stateBasedInput}
      {conditionalSuffix}
    </div>
  )
}