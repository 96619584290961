import React, { useState, useEffect, useCallback } from 'react';

import { MoreHorizontal, X } from 'react-feather';

import Accordion from '../../../components/Accordion';

export default function PartnerSettings(props) {
  const [score, setScore] = useState(0)
  const [fieldsSet, setFieldsSet] = useState(false)
  const [showOverlay, setShowOverlay] = useState(false)
  const [menuTarget, setMenuTarget] = useState()
  const [invites, setInvites] = useState([])
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [invites]
    properties.forEach(p => {
      if (p && p.length) newScore += 100/properties.length
    })

    setScore(Math.round(newScore))
  }, [invites])

  useEffect(() => {
    if (props.seller._id && !fieldsSet) {
      setInvites(props.seller.invites)

      setFieldsSet(true)
    }

    updateScore()
  }, [props.seller, fieldsSet, updateScore])

  async function submitInvite(evt) {
    evt.preventDefault()

    await props.patchSeller({
      invites: [...invites, {
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
        from: props.seller.user._id
      }]
    })

    setFirstName('')
    setLastName('')
    setEmail('')
    setPhone('')

    setShowOverlay(false)

    setFieldsSet(false)
  }

  function removeInvite(ivt) {
    const newInvites = invites.filter(i => i !== ivt)
    setInvites(newInvites)

    props.patchSeller({ invites: newInvites })

    updateScore()
  }

  let partnerRows = [];
  for (let invite of invites) {
    let partnerBadge;
    if (invite.active) {
      partnerBadge = <span className="badge badge-success">Aktiv</span>
    } else {
      partnerBadge = <span className="badge">Inaktiv</span>
    }

    partnerRows.push(
      <tr key={invite.email}>
        <td>{invite.firstName} {invite.lastName}</td>
        <td><a href={`mailto:${invite.email}`}>{invite.email}</a></td>
        <td><a href={`tel:${invite.phone}`}>{invite.phone || '-'}</a></td>
        <td>{partnerBadge}</td>
        <td style={{ textAlign: 'right', verticalAlign: 'top' }}>
          <button
            className="button circle secondary"
            style={{ display: 'inline-flex' }}
            onClick={() => setMenuTarget(menuTarget === invite ? undefined : invite)}
            >
            <MoreHorizontal size={16} />
          </button>

          <div className={`popover-container ${menuTarget === invite ? '' : 'hidden'}`}>
            <div className="popover">
              <div className="popover-menu">
                <div className="popover-action danger" onClick={() => removeInvite(invite)}>
                  <span className="popover-action-label">Partner entfernen</span>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
    )
  }

  let conditionalOverlay;
  if (showOverlay) {
    conditionalOverlay = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowOverlay(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Partner einladen</h3>
              <p className="overlay-intro">
                Lade weitere Personen zu dieser Transaktion ein. Vondireingeladene Partner erhalten dieselben Berechtigungen wie Du.
              </p>
            </div>

            <div className="callout">
              <p>
              Falls ein durch sie eingeladener Partner keinen Speer Account besitzt, ist eine vorherige <a target="_blank" rel="noopener noreferrer" href="https://dashboard.p4i-hub.com/signup">Registrierung</a> unbedingt erforderlich.
              </p>
            </div>

            <form className="overlay-body" onSubmit={e => submitInvite(e)}>
              <div className="Settings-fields Settings-fields-c2">
                <div className="form-field">
                  <label>Vorname</label>
                  <input type="text" value={firstName} onChange={e => setFirstName(e.target.value)} />
                </div>

                <div className="form-field">
                  <label>Nachname</label>
                  <input type="text" value={lastName} onChange={e => setLastName(e.target.value)} />
                </div>
              </div>

              <div className="form-field">
                <label>E-Mail</label>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} />
              </div>

              <div className="form-field">
                <label>Telefon</label>
                <input type="text" value={phone} onChange={e => setPhone(e.target.value)} />
              </div>

              <button type="submit"
                style={{ marginTop: '12px' }}
                className="button primary full-width"
                >
                Einladung senden
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  let conditionalPartnerTable;
  if (partnerRows.length) {
    conditionalPartnerTable = (
      <div className="settings-subsection">
        <table className="DataTable">
          <thead>
            <tr>
              <th>Name</th>
              <th>E-Mail</th>
              <th>Telefon</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {partnerRows}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <>
      <Accordion
        title="Ansprechpartner"
        toggleState={props.toggleState}
        body="Füge Partner und Shareholder zu deinem Unternehmen hinzu, um diese automatisch in Transaktionsverläufe zu integrieren."
        badgeLabel={`${score}% ausgefüllt`}
        badgeStyle={score === 100 ? 'badge-success' : ''}
      >
        <button
          className="button secondary small"
          style={{ marginTop: '12px' }}
          onClick={e => setShowOverlay(true)}
          >
          Neuen Partner einladen
        </button>

        {conditionalPartnerTable}
      </Accordion>

      {conditionalOverlay}
    </>
  )
}