import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import faker from 'faker';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import FileCard from '../../components/FileCard';
import WidgetFeed from '../../components/WidgetFeed';

import { ArrowLeft, ChevronDown, ArrowRight } from 'react-feather';

import optionService from '../../services/OptionService';
import EventLogger from '../../services/EventLogger';
import Formatter from '../../services/Formatter';

import './style.css';

export default function SellerProfile(props) {
  const [seller, setSeller] = useState()
  const [fetchedSeller, setFetchedSeller] = useState(false)
  const [assets, setAssets] = useState([])
  const [isOwner, setIsOwner] = useState(false)
  const [censorProfile, setCensorProfile] = useState(true)
  const [commonProjects, setCommonProjects] = useState([])
  const [showRequestAction, setShowRequestAction] = useState(false)
  const [activerBuyer, setActiveBuyer] = useState()
  const [trackedVisit, setTrackedVisit] = useState(false)

  const { id } = useParams()

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchSeller() {
      const r1 = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/sellers/${id}`
      })

      setSeller(r1.data)

      const thisIsMe = (r1.data.user._id === props.user._id) || props.user.isAdmin
      setIsOwner(thisIsMe)

      if (!thisIsMe && !trackedVisit) {
        await EventLogger.log(cookies.get('p4itoken'), {
          name: 'Profilaufruf',
          actor: props.user._id,
          onSeller: r1.data._id
        })

        setTrackedVisit(true)
      }

      if (!thisIsMe) {
        let sellerProjects = r1.data.projects
        let projects = []
        let projectsData = []

        for (let buyer of props.user.buyers) {
          for (let pid of buyer.projects) {
            if (sellerProjects.includes(pid)) projects.push(pid)
          }
        }

        if (projects.length) {
          // fetch common projects
          for (let pid of projects) {
            const r = await axios({
              method: 'GET',
              headers: {
                'content-type': 'application/json',
                'Authorization': cookies.get('p4itoken')
              },
              url: `${Env.url}/projects/${pid}`
            })

            if (!r.data.archived) projectsData.push(r.data)
          }

          setCommonProjects(projectsData)

          for (let cp of projectsData) {
            if (cp.step > 0) {
              setCensorProfile(false)
              break;
            }
          }
        }
      }

      const r2 = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          owner: r1.data.user._id,
          path: { $regex : "presentation$" }
        },
        url: `${Env.url}/assets/search`
      })

      setAssets(r2.data)
    }

    if (!fetchedSeller) {
      fetchSeller()
      setFetchedSeller(true)
    }
  }, [fetchedSeller, cookies, id, props.user, trackedVisit])

  function redactedKpi(value, format = false) {
    if (isNaN(value) || value === undefined || value === '' || (Array.isArray(value) && !value.length)) {
      return 'N/A'
    } else {
      // see if there's a common project beyond stage 1
      if (isOwner || !censorProfile) {
        if (!isNaN(value) & format) {
          if (Math.abs(value) >= 1000000) return <span> {(value/1000000.0).toFixed(1)}M</span>
          if (Math.abs(value) >= 1000) return <span> {Math.round(value/1000)}K</span>
          return <span>{value}</span>
        } else {
          return value
        }
      } else {
        if (!isNaN(value)) {
          const thresholds = optionService.thresholds

          let min = 0;
          let max = 0;
          for (let t of thresholds) {
            if (t > value && (t < max || max === min)) max = t
            if (t < value && t > min) { min = t; max = t; }
          }

          if (format) {
            let minValue = min >= 1000000 ? `${min/1000000.0}M` : `${min/1000.0}K`
            let maxValue = max >= 1000000 ? `${max/1000000.0}M` : `${max/1000.0}K`
            return `${minValue}-${maxValue}`
          } else {
            return `${min}-${max}`
          }
        } else {
          return value
        }
      }
    }
  }

  function toNiceList(items) {
    let niceList = ''
    for (let i = 0; i < items.length; i++) {
      if (i === items.length-1) {
        niceList += `und ${items[i]}`
      } else if (i === items.length-2) {
        niceList += `${items[i]} `
      } else {
        niceList += `${items[i]}, `
      }
    }

    return niceList
  }

  async function toggleVerification() {
    const r1 = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: { verified: !seller.verified },
      url: `${Env.url}/sellers/${id}`
    })

    setSeller(r1.data)
  }

  function updateActiveBuyer(newBuyer) {
    setActiveBuyer(newBuyer)
    setShowRequestAction(false)
  }

  async function sendRequest() {
    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: {
        seller: seller._id,
        buyer: activerBuyer._id,
        status: 'initiated',
        step: 0,
        name: faker.name.firstName(),
        documentRequirements: activerBuyer.documentRequirements || []
      },
      url: `${Env.url}/projects`
    })

    document.location = `/projects/${response.data._id}`
  }

  function tableCellsFromArray(ap) {
    const apBase = ap || []
    let neg = 0
    let pos = 0
    let total = 0
    for (let ap of apBase) {
      if (ap.amount > 0) {
        pos += ap.amount
        total += ap.amount
      }

      if (ap.amount < 0) {
        neg -= ap.amount
        total += ap.amount
      }
    }

    return [
      <td><span className="badge">{Formatter.currency(total, seller.currency)}</span></td>,
      <td><span className="badge badge-danger">{Formatter.currency(neg, seller.currency)}</span></td>,
      <td><span className="badge badge-success">{Formatter.currency(pos, seller.currency)}</span></td>
    ]
  }

  if (seller) {
    // UMSATZ/(EBITDA+Normalisierungen/Sondereffekte)
    const totalRevenue = seller.currentRevenue || 0
    const totalEbitda = seller.ebitda || 0
    const totalAdjustments = seller.adjustments || 0
    let ebitdaMarge = (((totalEbitda + totalAdjustments) / totalRevenue) * 100).toFixed(1)

    let sectorTags = []
    for (let sector of seller.sectors) {
      sectorTags.push(
        <div className="ProfileCard-tag">{sector}</div>
      )
    }

    let regionTags = []
    for (let location of seller.locations) {
      regionTags.push(
        <div className="ProfileCard-tag">{location}</div>
      )
    }

    let fileCards = assets.map(a => (
      <FileCard
        user={props.user}
        additionalUsers={[]}
        asset={a}
        />
    ))

    let publicFiles;
    if ((isOwner || !censorProfile) && fileCards.length) {
      publicFiles = (
        <div className="SplitCard ProfileGrid-card fade-in-up delay-400">
          <div className="SplitCard-left">
            <h3 className="SplitCard-title">Dokumente & Downloads</h3>
            <p className="SplitCard-body">Sichtbare Dokumente des Unternehmens (bitte vertraulich behandeln)</p>
          </div>
          <div className="SplitCard-right">
            {fileCards}
          </div>
        </div>
      )
    }

    let adminCards = []
    if (props.user.isAdmin) {
      let verificationAction;
      if (seller.verified) {
        verificationAction = (
          <button className="button small danger" onClick={() => toggleVerification()}>Verifikation entziehen</button>
        )
      } else {
        verificationAction = (
          <button className="button small primary" onClick={() => toggleVerification()}>Unternehmen verifizieren</button>
        )
      }
      adminCards = [
        <div className="ProjectCard ActionCard fade-in-up delay-400">
          <h3 className="ActionCard-title">Verifikation</h3>
          <p className="ActionCard-body">Nur verifizierte Unternehmen haben Vollzugriff auf den Hub. Nach erfolgreicher Prüfung sollten alle validen Unternehmen verifiziert werden.</p>
          <div className="ActionCard-actions">
            {verificationAction}
          </div>
        </div>
      ]
    }

    let buyerCards = []
    if (!props.user.isAdmin && !isOwner && props.user.buyers.length) {
      if (commonProjects.length) {
        buyerCards = [
          <div className="ProjectCard ActionCard fade-in-up delay-400">
            <h3 className="ActionCard-title">Aktives Projekt</h3>
            <p className="ActionCard-body">Du bist bereits in ein aktives Projekt mit diesem Unternehmen eingebunden.</p>
            <div className="ActionCard-actions">
              <a className="button small secondary" href={`/projects/${commonProjects[0]._id}`}>Zum Projekt {commonProjects[0].name}</a>
            </div>
          </div>
        ]
      } else {
        let availableBuyers = []
        for (let b of props.user.buyers) {
          availableBuyers.push(
            <div
              className={`Dropdown-menu-item ${activerBuyer === b ? 'active' : ''}`}
              onClick={() => updateActiveBuyer(b)}
              >
              {b.name}
            </div>
          )
        }

        let buyerDropdown = (
          <div className={`Dropdown ${showRequestAction ? 'active' : ''}`}>
            <button className="Dropdown-trigger" onClick={() => setShowRequestAction(!showRequestAction)}>
              <span className="Dropdown-trigger-label">
                {(activerBuyer || { name: 'Wähle Profil ...' }).name}
              </span>
              <ChevronDown size={18} />
            </button>
            <div className={`Dropdown-menu Dropdown-menu-top ${showRequestAction ? '' : 'hidden'}`}>
              {availableBuyers}
            </div>
          </div>
        )

        let requestAction;
        if (activerBuyer) {
          requestAction = (
            <button className="button primary circle margin-left" onClick={() => sendRequest()}>
              <ArrowRight size={18} color='white' />
            </button>
          )
        }

        const profileUserName = seller.blurred ? `${faker.name.firstName()} ${faker.name.lastName()}` : `${seller.contactFirstName} ${seller.contactLastName}`
        const profileUserEmail = seller.blurred ? faker.internet.email() : <a href={`mailto:${seller.contactEmail}`}>{seller.contactEmail}</a>
        let profileUsers = [
          <div className="ProfileCard-list-item">
            <span className={`%{ProfileCard-list-item-label} ${seller.blurred ? 'blurred' : ''}`}>
              {profileUserName}
            </span>
            <span className={`%{ProfileCard-list-item-value} ${seller.blurred ? 'blurred' : ''}`}>
              {profileUserEmail}
            </span>
          </div>
        ]

        for (let ivt of seller.invites) {
          const ivtName = seller.blurred ? `${faker.name.firstName()} ${faker.name.lastName()}` : `${ivt.firstName} ${ivt.lastName}`
          const ivtEmail = seller.blurred ? faker.internet.email() : <a href={`mailto:${ivt.email}`}>{ivt.email}</a>
          profileUsers.push(
            <div className="ProfileCard-list-item">
              <span className={`%{ProfileCard-list-item-label} ${seller.blurred ? 'blurred' : ''}`}>{ivtName}</span>
              <span className={`%{ProfileCard-list-item-value} ${seller.blurred ? 'blurred' : ''}`}>{ivtEmail}</span>
            </div>
          )
        }

        buyerCards = [
          <div className="ProjectCard ActionCard fade-in-up delay-400">
            <h3 className="ActionCard-title">Ansprechpartner</h3>
            <p className="ActionCard-body">Ihre Ansprechpartner im Überblick</p>
            <div className="ProfileCard-list">{profileUsers}</div>
          </div>,
          <div className="ProjectCard ActionCard fade-in-up delay-400">
            <h3 className="ActionCard-title">Projektanfrage</h3>
            <p className="ActionCard-body">Sieht dieses Unternehmen spannend aus? Sende dem Inhaber  eine Projektanfrage um zu erfahren, ob gemeinsames Interesse besteht.</p>
            <div className="ActionCard-actions">
              {buyerDropdown}
              {requestAction}
            </div>
          </div>
        ]
      }
    }

    let conditionalSectorTags;
    if (sectorTags.length) {
      conditionalSectorTags = (
        <div className="ActionCard ProfileGrid-card fade-in-up delay-200">
          <h3 className="ActionCard-title">Branchen</h3>
          <p className="ActionCard-body">Angebote und Felder des Unternehmens</p>

          <div className="ProfileCard-tags">{sectorTags}</div>
        </div>
      )
    }

    let conditionalRegionTags;
    if (regionTags.length) {
      conditionalRegionTags = (
        <div className="ActionCard ProfileGrid-card fade-in-up delay-200">
          <h3 className="ActionCard-title">Standorte</h3>
          <p className="ActionCard-body">Regionale Aufstellung des Unternehmens</p>

          <div className="ProfileCard-tags">{regionTags}</div>
        </div>
      )
    }

    let financeCard1;
    let conditionalFinanceCard2;
    if (seller.blurred) {
      let adjustmentStats;
      const positiveAdjustments = seller.adjustmentPositions.filter(p => p.amount > 0)
      const negativeAdjustments = seller.adjustmentPositions.filter(p => p.amount < 0)
      if (positiveAdjustments.length || negativeAdjustments.length) {
        adjustmentStats = (
          <span className="ProfileCard-list-item-value">
            <span className="badge badge-success">
              {redactedKpi(positiveAdjustments.reduce((a, b) => a + b.amount, 0), true)}
            </span>
            <span className="text-muted">{' / '}</span>
            <span className="badge badge-danger">
              {redactedKpi(Math.abs(negativeAdjustments.reduce((a, b) => a + b.amount, 0)), true)}
            </span>
          </span>
        )
      } else {
        adjustmentStats = (
          <span className="ProfileCard-list-item-value">N/A</span>
        )
      }

      financeCard1 = (
        <div className="ActionCard ProfileGrid-card fade-in-up">
          <h3 className="ActionCard-title">Finanzzahlen</h3>
          <p className="ActionCard-body">Eck-Daten zur Einordnung des Unternehmens</p>

          <div className="ProfileCard-list">
            <div className="ProfileCard-list-item">
              <span className="ProfileCard-list-item-label">Jahresumsatz</span>
              <span className="ProfileCard-list-item-value">{redactedKpi(seller.currentRevenue + (seller.adjustments || 0), true)}</span>
            </div>
            <div className="ProfileCard-list-item">
              <span className="ProfileCard-list-item-label">EBITDA</span>
              <span className="ProfileCard-list-item-value">{redactedKpi(seller.ebitda + (seller.adjustments || 0), true)}</span>
            </div>
            <div className="ProfileCard-list-item">
              <span className="ProfileCard-list-item-label">EBITDA Marge</span>
              <span className="ProfileCard-list-item-value">{isNaN(ebitdaMarge) ? 'N/A' : `${redactedKpi(ebitdaMarge, false)}%`}</span>
            </div>
            <div className="ProfileCard-list-item">
              <span className="ProfileCard-list-item-label">EBIT</span>
              <span className="ProfileCard-list-item-value">{redactedKpi(seller.ebit + (seller.adjustments || 0), true)}</span>
            </div>
            <div className="ProfileCard-list-item">
              <span className="ProfileCard-list-item-label">Normalisierungen</span>
              <span className="ProfileCard-list-item-value">
                {adjustmentStats}
              </span>
            </div>
          </div>
        </div>
      )
    } else {
      financeCard1 = (
        <div className="ActionCard ProfileGrid-card fade-in-up">
          <h3 className="ActionCard-title">Normalisierungen</h3>
          <p className="ActionCard-body">Sondereffekte der letzten drei Jahre</p>

          <div className="ProfileCard-list">
          <table className="DataTable admin-actions">
              <thead>
                <tr>
                  <th>Jahr</th>
                  <th>Gesamt</th>
                  <th>Ertragskorr.</th>
                  <th>Aufwandskorr.</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{new Date().getFullYear()+1}</td>
                  {tableCellsFromArray(seller.adjustmentPositionsFC)}
                </tr>
                <tr>
                  <td>{new Date().getFullYear()}</td>
                  {tableCellsFromArray(seller.adjustmentPositions)}
                </tr>
                <tr>
                  <td>{new Date().getFullYear()-1}</td>
                  {tableCellsFromArray(seller.adjustmentPositionsX1)}
                </tr>
                <tr>
                  <td>{new Date().getFullYear()-2}</td>
                  {tableCellsFromArray(seller.adjustmentPositionsX2)}
                </tr>
                <tr>
                  <td>{new Date().getFullYear()-3}</td>
                  {tableCellsFromArray(seller.adjustmentPositionsX3)}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )

      const ebitdaMarge = ((((seller.ebitda || 0) + (seller.adjustments || 0)) / (seller.currentRevenue || 0)) * 100).toFixed(1)
      const ebitdaMargeX1 = ((((seller.ebitdaX1 || 0) + (seller.adjustmentsX1 || 0)) / (seller.currentRevenueX1 || 0)) * 100).toFixed(1)
      const ebitdaMargeX2 = ((((seller.ebitdaX2 || 0) + (seller.adjustmentsX2 || 0)) / (seller.currentRevenueX2 || 0)) * 100).toFixed(1)
      const ebitdaMargeX3 = ((((seller.ebitdaX3 || 0) + (seller.adjustmentsX3 || 0)) / (seller.currentRevenueX3 || 0)) * 100).toFixed(1)

      conditionalFinanceCard2 = (
        <div className="ActionCard ActionCard-double ProfileGrid-card fade-in-up">
          <h3 className="ActionCard-title">Finanzzahlen</h3>
          <p className="ActionCard-body">Entwicklung wichtiger Finanzzahlen der letzten drei Jahre. Alle Werte sind als normalisierte Beträge zu betrachten.</p>

          <div className="ProfileCard-list">
            <table className="DataTable admin-actions">
              <thead>
                <tr>
                  <th>KPI</th>
                  <th>{new Date().getFullYear()}</th>
                  <th>{new Date().getFullYear()-1}</th>
                  <th>{new Date().getFullYear()-2}</th>
                  <th>{new Date().getFullYear()-3}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Jahresumsatz (in {seller.currency})</td>
                  <td>{redactedKpi(seller.currentRevenue + seller.adjustmentPositions.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.currentRevenueX1 + seller.adjustmentPositionsX1.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.currentRevenueX2 + seller.adjustmentPositionsX2.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.currentRevenueX3 + seller.adjustmentPositionsX3.reduce((x, y) => x + y.amount, 0), true)}</td>
                </tr>
                <tr>
                  <td>EBITDA (in {seller.currency})</td>
                  <td>{redactedKpi(seller.ebitda + seller.adjustmentPositions.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.ebitdaX1 + seller.adjustmentPositionsX1.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.ebitdaX2 + seller.adjustmentPositionsX2.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.ebitdaX3 + seller.adjustmentPositionsX3.reduce((x, y) => x + y.amount, 0), true)}</td>
                </tr>
                <tr>
                  <td>EBITDA Marge</td>
                  <td>{isNaN(ebitdaMarge) ? 'N/A' : `${ebitdaMarge}%`}</td>
                  <td>{isNaN(ebitdaMargeX1) ? 'N/A' : `${ebitdaMargeX1}%`}</td>
                  <td>{isNaN(ebitdaMargeX2) ? 'N/A' : `${ebitdaMargeX2}%`}</td>
                  <td>{isNaN(ebitdaMargeX3) ? 'N/A' : `${ebitdaMargeX3}%`}</td>
                </tr>
                <tr>
                  <td>EBIT (in {seller.currency})</td>
                  <td>{redactedKpi(seller.ebit + seller.adjustmentPositions.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.ebitX1 + seller.adjustmentPositionsX1.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.ebitX2 + seller.adjustmentPositionsX2.reduce((x, y) => x + y.amount, 0), true)}</td>
                  <td>{redactedKpi(seller.ebitX3 + seller.adjustmentPositionsX3.reduce((x, y) => x + y.amount, 0), true)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )
    }

    let websiteLink;
    if (seller.website) {
      if (seller.website.startsWith('http')) {
        websiteLink = seller.website
      } else {
        websiteLink = `http://${seller.website}`
      }
    }

    return (
      <div className="SellerProfile">
        <Aside darkMode={props.darkMode} user={props.user} scope="seller-profile" signOutUser={() => props.signOutUser()}>
          <div className="ContentPanel">
            <div className="ContentPanel-main">
              <div className="PanelHeader">
                <h1 className='PanelHeader-title'>
                  <strong>Unternehmerprofile</strong> <span className={seller.blurred ? 'blurred blurred-more' : ''}>{seller.name} {seller.type}</span>
                </h1>
                <h5 className="PanelHeader-subline">{toNiceList(seller.sectors)} aus {seller.country}</h5>
              </div>
  
              <div className="Breadcrumbs">
                <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                  <ArrowLeft size={18} color='#6B6E97' />
                </div>
  
                <div className="Breadcrumbs-path">
                  <a href="/">Dashboard</a>
                  <span>/</span>
                  <a href="/seller-profiles">Unternehmerprofile</a>
                  <span>/</span>
                  <a href="/" className={seller.blurred ? 'active blurred' : 'active '}>{seller.name} {seller.type}</a>
                </div>
              </div>
  
              <div className="ProfileGrid">
                <div className="ActionCard ProfileGrid-card fade-in-up">
                  <h3 className="ActionCard-title">Quickfacts</h3>
                  <p className="ActionCard-body">Die wichtigsten Informationen auf einen Blick</p>
  
                  <div className="ProfileCard-list">
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Gründungsjahr</span>
                      <span className="ProfileCard-list-item-value">{seller.foundingYear || 'N/A'}</span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Mitarbeiter</span>
                      <span className="ProfileCard-list-item-value">{redactedKpi(seller.employees)}</span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Transaktionsgrund</span>
                      <span className="ProfileCard-list-item-value">{redactedKpi(seller.intentions)}</span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Website</span>
                      <span className={`ProfileCard-list-item-value ${seller.blurred ? 'blurred' : ''}`}>
                        <a href={websiteLink} target="_blank" rel="noreferrer">{redactedKpi(seller.website)}</a>
                      </span>
                    </div>
                    <div className="ProfileCard-list-item">
                      <span className="ProfileCard-list-item-label">Status</span>
                      <span className="ProfileCard-list-item-value">
                        <span className={`badge ${seller.active ? 'badge-success badge-bullet' : ''}`}>{seller.active ? 'Aktiv' : 'Inaktiv'}</span>
                      </span>
                    </div>
                  </div>
                </div>

                {financeCard1}

                {conditionalFinanceCard2}

                {conditionalSectorTags}
                {conditionalRegionTags}

                {publicFiles}
                {buyerCards}
                {adminCards}
              </div>
            </div>

            <div className="ContentPanel-sidebar">
              <WidgetFeed scope="seller" seller={seller} user={props.user} patchUser={v => props.patchUser(v)} />
            </div>
          </div>
        </Aside>
      </div>
    )
  } else {
    return (
      <div className="SellerProfile">
        <Aside darkMode={props.darkMode} user={props.user} scope="seller-profile" signOutUser={() => props.signOutUser()}>
          <div className="ContentPanel">
            <div className="ContentPanel-main">
              <div className="PanelHeader">
                <h1 className="PanelHeader-title"><strong>Firmenprofil</strong> ...</h1>
                <h5 className="PanelHeader-subline">Lade Daten ...</h5>
              </div>
  
              <div className="Breadcrumbs">
                <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                  <ArrowLeft size={18} color='#6B6E97' />
                </div>
  
                <div className="Breadcrumbs-path">
                  <a href="/">Dashboard</a>
                  <span>/</span>
                  <a href="/seller-profiles">Unternehmerprofile</a>
                  <span>/</span>
                  <a href="/" className="active">...</a>
                </div>
              </div>
  
              <div className="ProfileGrid">
              </div>
            </div>
  
            <div className="ContentPanel-sidebar">
              <WidgetFeed scope="seller" seller={seller} user={props.user} patchUser={v => props.patchUser(v)} />
            </div>
          </div>
        </Aside>
      </div>
    )
  }
}