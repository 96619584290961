import React, { useState, useEffect, useCallback } from 'react';

import Accordion from '../../../components/Accordion';
import CustomSelectable from '../../../components/CustomSelectable';
import CustomPhoneInput from '../../../components/CustomPhoneInput';

import optionService from '../../../services/OptionService';

export default function BaseData(props) {
  const [score, setScore] = useState(0)
  const [name, setName] = useState('')
  const [type, setType] = useState('')
  const [currency, setCurrency] = useState('')
  const [address, setAddress] = useState('')
  const [zip, setZip] = useState('')
  const [city, setCity] = useState('')
  const [country, setCountry] = useState('')
  const [contactFirstName, setContactFirstName] = useState('')
  const [contactLastName, setContactLastName] = useState('')
  const [contactEmail, setContactEmail] = useState('')
  const [contactPhone, setContactPhone] = useState('')
  const [website, setWebsite] = useState('')
  const [fieldsSet, setFieldsSet] = useState(false)

  const countryOptions = optionService.countries

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [name, type, currency, address, zip, city, country, contactFirstName, contactLastName, contactEmail, contactPhone, website]
    properties.forEach(p => {
      if (p && p !== '') newScore += 100/properties.length
    })
  
    setScore(Math.round(newScore))
  }, [name, type, currency, address, zip, city, country, contactFirstName, contactLastName, contactEmail, contactPhone, website])

  useEffect(() => {
    if (props.buyer._id && !fieldsSet) {
      setName(props.buyer.name)
      setType(props.buyer.type)
      setCurrency({ label: props.buyer.currency, value: props.buyer.currency })
      setAddress(props.buyer.address)
      setZip(props.buyer.zip)
      setCity(props.buyer.city)
      setCountry({ label: props.buyer.country, value: props.buyer.country })
      setCountry({ label: props.buyer.country, value: props.buyer.country })
      setContactFirstName(props.buyer.contactFirstName)
      setContactLastName(props.buyer.contactLastName)
      setContactEmail(props.buyer.contactEmail)
      setContactPhone(props.buyer.contactPhone)
      setWebsite(props.buyer.website)

      setFieldsSet(true)
    }

    updateScore()
  }, [props.buyer, fieldsSet, updateScore])

  function universalSetter(attr, attrSetter, value) {
    let patchData = {}

    attrSetter(value)

    if (value.value) {
      patchData[attr] = value.value
    } else {
      patchData[attr] = value
    }

    updateScore()
    props.patchBuyer(patchData)
  }

  return (
    <Accordion
      title="Basisdaten"
      toggleState={props.toggleState}
      body="Hinterlege Kontaktinformationen, über die wir dieses Unternehmen kontaktieren können. Alle Informationen werden streng vertraulich behandelt."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
      >
      <div className="Settings-fields Settings-fields-c4">
        <div className="form-field">
          <label>Unternehmensname</label>
          <input
            type="text"
            value={name}
            onChange={e => universalSetter('name', setName, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Gesellschaftsform</label>
          <input
            type="text"
            value={type}
            onChange={e => universalSetter('type', setType, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Währung</label>
          <CustomSelectable
            placeholder=''
            classNamePrefix="react-select"
            options={[
              { label: 'EUR', value: 'EUR' },
              { label: 'CHF', value: 'CHF' },
              { label: 'USD', value: 'USD' }
            ]}
            value={currency}
            onChange={v => universalSetter('currency', setCurrency, v)}
            />
        </div>

        <div className="form-field">
          <label>Website</label>
          <input
            type="text"
            value={website}
            onChange={e => universalSetter('website', setWebsite, e.target.value)}
            />
        </div>
      </div>

      <h3 className="settings-section-title">Anschrift des Unternehmens</h3>
      <div className="Settings-fields Settings-fields-c4">
        <div className="form-field">
          <label>Straße & Hausnummer</label>
          <input
            type="text"
            value={address}
            onChange={e => universalSetter('address', setAddress, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Postleitzahl</label>
          <input
            type="text"
            value={zip}
            onChange={e => universalSetter('zip', setZip, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Ort</label>
          <input
            type="text"
            value={city}
            onChange={e => universalSetter('city', setCity, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Land</label>
          <CustomSelectable
            placeholder=''
            classNamePrefix="react-select"
            options={countryOptions.map(o => ({ label: o, value: o }))}
            value={country}
            onChange={v => universalSetter('country', setCountry, v)}
            />
        </div>
      </div>

      <h3 className="settings-section-title">Primärer Ansprechpartner</h3>
      <div className="Settings-fields Settings-fields-c4">
        <div className="form-field">
          <label>Vorname</label>
          <input
            type="text"
            value={contactFirstName}
            onChange={e => universalSetter('contactFirstName', setContactFirstName, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Nachname</label>
          <input
            type="text"
            value={contactLastName}
            onChange={e => universalSetter('contactLastName', setContactLastName, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>E-Mail</label>
          <input
            type="text"
            value={contactEmail}
            onChange={e => universalSetter('contactEmail', setContactEmail, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Telefon</label>
          <CustomPhoneInput
            country='ch'
            value={contactPhone}
            onChange={v => universalSetter('contactPhone', setContactPhone, v)}
            />
        </div>
      </div>
    </Accordion>
  )
}