import CustomSelectable from "../../../../../components/CustomSelectable"
import NumberInput from "../../../../../components/NumberInput"

import { Save, MoreHorizontal } from 'react-feather'

export default function ApRecord(props) {
  let apBadge;
  let tableRow;

  if (props.editMode && props.editMode.name === props.ap.name && props.editMode.value === props.ap.value) {
    if (props.editValue < 0) {
      apBadge = <span className="badge badge-danger">Aufwand</span>
    } else {
      apBadge = <span className="badge badge-success">Ertrag</span>
    }

    let conditionalValue;
    if (props.editValue) {
      conditionalValue = props.editValue > 0 ? { label: 'Aufwand', value: 'Aufwand' } : { label: 'Ertrag', value: 'Ertrag' }
    }
    
    tableRow = (
      <tr key='editable-row'>
        <td>
          <div className="form-field">
            <NumberInput
              suffix={props.seller.currency}
              value={props.editValue}
              onChange={v => props.setEditValue(v)}
              />
          </div>
        </td>
        <td>
          <div className="form-field">
            <CustomSelectable
              placeholder=''
              classNamePrefix="react-select"
              options={[
                { label: 'Ertrag', value: 'Ertrag' },
                { label: 'Aufwand', value: 'Aufwand' }
              ]}
              value={conditionalValue}
              onChange={v => props.setEditValue(v.label === 'Aufwandskorrektur' ? Math.abs(props.editValue) : 0-Math.abs(props.editValue))}
              />
          </div>
        </td>
        <td>
          <div className="form-field">
            <input type="text" value={props.editName} onChange={e => props.setEditName(e.target.value)} />
          </div>
        </td>
        <td>
          <button
            className="button circle secondary"
            style={{ display: 'inline-flex' }}
            onClick={() => props.saveAndLeaveEditMode(props.ap)}
            >
            <Save size={16} />
          </button>
        </td>
      </tr>
    )
  } else {
    if (props.ap.amount < 0) {
      apBadge = <span className="badge badge-danger">Ertrag</span>
    } else {
      apBadge = <span className="badge badge-success">Aufwand</span>
    }

    tableRow = (
      <>
        <tr key={props.ap.name}>
          <td style={{ width: '150px' }}>{props.seller.currency} {Math.abs(props.ap.amount).toLocaleString('de-DE', { currency: props.seller.currency })}</td>
          <td style={{ width: '150px' }}>{apBadge}</td>
          <td>{props.ap.name}</td>
          <td style={{ textAlign: 'right', zIndex: props.menuTarget === props.ap ? 998 : 0 }}>
            <button
              className="button circle secondary"
              style={{ display: 'inline-flex' }}
              onClick={() => props.updateMenuTarget(props.ap)}
              >
              <MoreHorizontal size={16} />
            </button>
            <div className={`popover-container ${props.menuTarget === props.ap ? '' : 'hidden'}`}>
              <div className="popover">
                <div className="popover-menu">
                  <div className="popover-action danger" onClick={() => props.deleteAdjustment(props.ap, props.attr, props.target, props.setter)}>
                    <span className="popover-action-label">Eintrag löschen</span>
                  </div>
                  <div className="popover-action" onClick={() => props.toggleEditMode(props.ap)}>
                    <span className="popover-action-label">Eintrag bearbeiten</span>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      </>
    )
  }

  return tableRow;
}