import { useState } from 'react';

import CustomSelectable from '../../../components/CustomSelectable';

import { X } from 'react-feather';

export default function QuestionForm(props) {
  const [questionName, setQuestionName] = useState('')
  const [questionDescription, setQuestionDescription] = useState('')
  const [questionOptionCount, setQuestionOptionCount] = useState(0)
  const [questionType, setQuestionType] = useState({
    label: 'Text', value: 'text'
  })
  const [questionOptions, setQuestionOptions] = useState([])
  const [questionOptionValues, setQuestionOptionValues] = useState([])

  function updateOption(idx, newValue) {
    let newOptions = [...questionOptionValues]
    newOptions[idx] = newValue
    setQuestionOptionValues(newOptions)
  }

  function addQuestionOption() {
    const newQuestionOptionCount = questionOptionCount+1
    setQuestionOptions([...questionOptions, newQuestionOptionCount])
    setQuestionOptionCount(newQuestionOptionCount)
  }

  let multipleChoiceOptions;
  if (questionType && questionType.value === 'multiple-choice') {
    multipleChoiceOptions = [
      <p className="text-muted"><small>Hinterlege Optionen, aus denen dein Partner wählen kann (Mehrfachauswahl möglich).</small></p>
    ]
    for (let i = 0; i < questionOptions.length; i++) {
      multipleChoiceOptions.push(
        <div className="form-field less-margin">
          <input type="text" placeholder="Neue Option" onChange={e => updateOption(i, e.target.value)} />
        </div>
      )
    }
    multipleChoiceOptions.push(
      <button className="button small link margin-top margin-bottom" onClick={() => addQuestionOption()}>+ Option hinzufügen</button>
    )
  }

  function submitNewQuestion(evt) {
    evt.preventDefault()

    props.submitNewQuestion({
      question: questionName,
      description: questionDescription,
      questionType: questionType.value,
      choiceAnswer: questionOptionValues.map(opt => ({ choice: opt, checked: false }))
    })

    setQuestionName('')
    setQuestionDescription('')
    setQuestionType({ label: 'Text', value: 'text' })
    setQuestionOptionValues([])
  }

  return (
    <div className="overlay-container">
      <div className="overlay">
        <div className="overlay-inner">
          <X className="overlay-dismiss" onClick={e => props.setShowQuestionOverlay(false)} />
          <div className="overlay-header">
            <h3 className="overlay-title">Neue Anfrage</h3>
            <p className="overlay-intro">
              Formuliere eine Anfrage und versuche die Erwartungshaltung deiner Anforderung knapp zu beschreiben.
            </p>
          </div>

          <form className="overlay-body" onSubmit={e => submitNewQuestion(e)}>
            <div className="form-field">
              <label>Anfrage</label>
              <input type="text" value={questionName} onChange={e => setQuestionName(e.target.value)} />
            </div>

            <div className="form-field">
              <label>Beschreibung</label>
              <textarea value={questionDescription} onChange={e => setQuestionDescription(e.target.value)} />
            </div>

            <div className="form-field">
              <label>Anfragentyp</label>
              <CustomSelectable
                placeholder=''
                classNamePrefix="react-select"
                options={[
                  { label: 'Text', value: 'text' },
                  { label: 'Multiple Choice', value: 'multiple-choice' },
                  { label: 'Datei', value: 'file' }
                ]}
                value={questionType}
                onChange={v => setQuestionType(v)}
                />
            </div>

            {multipleChoiceOptions}

            <button type="submit"
              style={{ marginTop: '12px' }}
              className="button primary full-width"
              >
              Anfrage speichern
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}