import { useState, useEffect, useMemo } from "react";

import Cookies from 'universal-cookie';

import Makro from '../../Makro';
import MakroSelect from '../../MakroSelect';

export default function SellerFeed(props) {
  const [showRequestAction, setShowRequestAction] = useState(false)
  const [activeBuyer, setActiveBuyer] = useState()

  const [actionFeed, setActionFeed] = useState([
    <p className="text-muted centered">
      <small>Du bist auf dem neuesten Stand. Es liegen keine ungelesenen Updates für dich vor. Guten Tag 👋</small>
    </p>
  ])

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    if ((props.user.isAdmin && props.seller) || (props.seller && props.seller.user._id.toString() === props.user._id.toString())) {
      setActionFeed([
        <Makro
          iconAction
          label="Unternehmen bearbeiten"
          target={`/settings/sellers/${props.seller._id}`}
          />,
        <Makro
          iconAction
          label="Projekte verwalten"
          target='/seller-projects'
          />,
        <Makro
          iconAction
          label="Partner verwalten"
          target={`/settings/sellers/${props.seller._id}#partner-settings`}
          />,
        <Makro
          label="Finde Investoren"
          actionLabel="Quick Search"
          target={`/search/buyers`}
          />,
        // - TODO: browse competition
      ])
    } else if (props.seller) {
      function updateActiveBuyer(newBuyer) {
        setActiveBuyer(newBuyer)
        setShowRequestAction(false)
      }

      let availableBuyers = []
      for (let b of props.user.buyers) {
        // make sure to avoid buyers who already have a project with this seller
        const commonProjects = b.projects.filter(p => props.seller.projects.includes(p))

        if (!commonProjects.length) {
          availableBuyers.push({ value: b, label: b.name })
        }
      }

      let projectRequestCard;
      let hasVerifiedBuyers = false
      for (let s of props.user.buyers) {
        if (s.verified) {
          hasVerifiedBuyers = true
          break
        }
      }

      if (hasVerifiedBuyers && availableBuyers.length) {
        projectRequestCard = (
          <MakroSelect
            actionLabel="Profil wählen"
            label="Projektanfrage senden"
            onSelect={v => updateActiveBuyer(v)}
            options={availableBuyers}
          />
        )
      }

      setActionFeed([
        <Makro
          iconAction
          label="Finde ähnliche Unternehmer"
          target='/search/sellers'
        />,
        projectRequestCard
        // - TODO: contact
        // - TODO: claim profile
        // - TODO: report profile
      ])
    }
  }, [ props, activeBuyer, showRequestAction, cookies ])

  return actionFeed;
}