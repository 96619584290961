import React from 'react';

import { ArrowRight } from 'react-feather';

import './style.css';

export default function Makro(props) {
  let makroAction;

  if (props.target) {
    makroAction = (
      <a className={`Makro-action-link ${props.iconAction ? 'Makro-action-link--icon' : ''}`} href={props.target}>
        { props.iconAction ? <ArrowRight color='white' size='14' /> : props.actionLabel }
      </a>
    )
  } else {
    makroAction = (
      <div className={`Makro-action-link ${props.iconAction ? 'Makro-action-link--icon' : ''}`} onClick={props.targetAction}>
        { props.iconAction ? <ArrowRight color='white' size='14' /> : props.actionLabel }
      </div>
    )
  }

  return (
    <div className="Makro">
      <div className="Makro-label">{props.label}</div>
      <div className="Makro-action">
        {makroAction}
      </div>
    </div>
  )
}