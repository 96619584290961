import { useEffect, useState, useMemo, useRef } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../../Environments';

import CustomSelectable from '../../../components/CustomSelectable';
import Checkbox from '../../../components/Checkbox';
import FileCard from '../../../components/FileCard';
import EventScheduler from '../../../components/EventScheduler';

import AdminActions from './AdminActions';
import ApprovalCard from './ApprovalCard';

import { X } from 'react-feather';

export default function Stage2(props) {
  const [fetchedPreDD, setFetchedPreDD] = useState(false)
  const [preDDCompleted, setPreDDCompleted] = useState(false)
  const [fetchedAssets, setFetchedAssets] = useState(false)
  const [assets, setAssets] = useState([])
  const [projects, setProjects] = useState([])
  const [menuTarget, setMenuTarget] = useState()
  const [approvedLOIMeeting, setApprovedLOIMeeting] = useState(false)
  const [showHiddenCards, setShowHiddenCards] = useState(false)
  const [showDDPicker, setShowDDPicker] = useState(false)
  const [importFromProject, setImportFromProject] = useState()
  const [fetchedProjects, setFetchedProjects] = useState(false)

  const [financialDD, setFinancialDD] = useState(false)
  const [taxDD, setTaxDD] = useState(false)
  const [legalDD, setLegalDD] = useState(false)
  const [commercialDD, setCommercialDD] = useState(false)

  const fileUpload = useRef()

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchAssets() {
      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          path: `projects/${props.project._id}/${props.phaseNames[props.project.step]}`
        },
        url: `${Env.url}/assets/search`
      })

      setAssets(response.data)
    }

    async function fetchProjects() {
      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          buyer: props.project.buyer,
          step: { $gte: 2 },
          _id: { $ne: props.project._id },
        },
        url: `${Env.url}/projects/search`
      })

      setProjects(response.data)
    }

    async function fetchPreDD() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assessments/${props.project.preDD}`
      })

      setPreDDCompleted(response.data.questions.filter(q => q.completed).length === response.data.questions.length)
    }

    if (!fetchedPreDD) {
      fetchPreDD()
      setFetchedPreDD(true)
    }

    if (props.project._id && !fetchedAssets) {
      fetchAssets()
      setFetchedAssets(true)
    }

    if (props.project._id && (!props.isSeller && !props.user.isAdmin) && !fetchedProjects) {
      fetchProjects()
      setFetchedProjects(true)
    }

    const loiMeetings = props.project.meetingRequests.filter(mr => mr.reason === 'LOI')
    for (let loimr of loiMeetings) {
      if (loimr.approvedDate) setApprovedLOIMeeting(true)
    }

    setShowHiddenCards(props.isSeller ? props.project.sellerReadyForLOI : props.project.buyerReadyForLOI)
  }, [props, fetchedPreDD, cookies, fetchedAssets, fetchedProjects])

  async function deleteFile(asset) {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/assets/${asset._id}`
    })

    setFetchedAssets(false)
  }

  async function patchFile(asset, newData) {
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: newData,
      url: `${Env.url}/assets/${asset._id}`
    })

    setFetchedAssets(false)
  }

  async function handleFileUpload(e) {
    const fileList = Array.from(e.target.files)

    const data = new FormData()
    data.append('vfile', fileList[0])
    data.append('path', `projects/${props.project._id}/${props.phaseNames[props.project.step]}`)
    data.append('owner', props.user._id)

    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/assets`
    })

    if (props.isSeller) {
      await props.patchProject({ sellerNDASigned: true })
    } else {
      await props.patchProject({ buyerNDASigned: true })
    }

    setFetchedAssets(false)
  }

  async function updateHiddenCards(v) {
    setShowHiddenCards(v)
    let changeData = {}

    if (props.isSeller) {
      changeData['sellerReadyForLOI'] = v
      await props.patchProject(changeData)
    } else {
      changeData['buyerReadyForLOI'] = v
    }

    if (!props.user.isAdmin) await props.patchProject(changeData)
  }

  function pickDDOrProceed(e) {
    if (props.isSeller || props.user.isAdmin) {
      props.patchProject(e)
    } else {
      setShowDDPicker(true)
    }
  }

  function savePreferencesAndApprove(evt) {
    evt.preventDefault()

    props.patchProject({
      approvedByBuyer: true,
      defaultFinancialDD: financialDD,
      defaultTaxDD: taxDD,
      defaultLegalDD: legalDD,
      defaultCommercialDD: commercialDD,
      importDDFromProject: (importFromProject || {}).value
    })

    setShowDDPicker(false)
  }

  let conditionalDDPicker;
  if (showDDPicker) {
    let conditionalImportOption;
    if (projects.length) {
      conditionalImportOption = [
        <div className="separator margin-top margin-bottom" />,
        <h4 className="overlay-subtitle">Eigene Listen</h4>,
        <p className="text-muted margin-bottom">Du kannst Anforderungslisten aus einem anderen Projekt in Speer importieren.</p>,
        <div className="dd-picker">
          <CustomSelectable
            placeholder=''
            classNamePrefix="react-select"
            options={projects.map(p => (
              { label: p.name, value: p._id }
            ))}
            value={importFromProject}
            onChange={v => setImportFromProject(v)}
            />
        </div>
      ]
    }

    conditionalDDPicker = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowDDPicker(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Anforderungslisten wählen</h3>
              <p className="overlay-intro">
                Lege die Anforderungslisten fest, mit denen du in die Due Diligence starten möchtest.
              </p>
            </div>

            <form className="overlay-body" onSubmit={evt => savePreferencesAndApprove(evt)}>
              <h4 className="overlay-subtitle">Speer Standards</h4>
              <p className="text-muted margin-bottom">Wähle aus P4i-Standardlisten für die bevorstehende Due Diligence.</p>

              <Checkbox
                narrow
                user={props.user}
                label='Finanzielle Due Diligence'
                checked={financialDD}
                toggleOption={v => setFinancialDD(v)}
                />

              <Checkbox
                narrow
                user={props.user}
                label='Steuerliche Due Diligence'
                checked={taxDD}
                toggleOption={v => setTaxDD(v)}
                />

              <Checkbox
                narrow
                user={props.user}
                label='Rechtliche Due Diligence'
                checked={legalDD}
                toggleOption={v => setLegalDD(v)}
                />

              <Checkbox
                narrow
                user={props.user}
                label='Commercial Due Diligence'
                checked={commercialDD}
                toggleOption={v => setCommercialDD(v)}
                />

              {conditionalImportOption}

              <button
                type="submit"
                className="button primary margin-top full-width"
                >
                Speichern & fortfahren
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  let fileCards = [];
  for (let asset of assets) {
    fileCards.push(
      <FileCard
        user={props.user}
        additionalUsers={props.isSeller ? [{ _id: props.project.buyer.user }] : [{ _id: props.project.seller.user }]}
        asset={asset}
        menuTarget={menuTarget}
        setMenuTarget={setMenuTarget}
        deleteFile={() => deleteFile(asset)}
        patchFile={newData => patchFile(asset, newData)}
        />
    )
  }

  if (!fileCards.length) fileCards = <p className="text-muted centered">Keine Dokumente</p>

  let userCards = [];
  let adminCards = [];

  const preDDSellerText = 'Erfülle alle individuellen Anfragen, die dein Partner hier an dich stellt bevor erdireine mögliche Strukturierung der potentielle Zusammenarbeit aufzeigen kann. Alle Angaben werden im Rahmen vom NDA mit Ihrem Partner und auch Speer absolut vertraulich behandelt.'
  const preDDBuyerText = 'Erstelle deine individuelle Anfrageliste für diese Opportunität um danach ein spezifisches unverbindliches Angebot (LOI) und die Strukturierung der potentiellen Zusammenarbeit mit dem interesseierten Partner vorstellen zu können. Alle Angaben werden im Rahmen vom NDA mit Ihrem Partner und auch Speer absolut vertraulich behandelt.'
  const listCard = (
    <div className="ProjectCard ActionCard">
      <h3 className="ActionCard-title">Indikative Anforderungsliste</h3>
      <p className="ActionCard-body">
        {props.isSeller ? preDDSellerText : preDDBuyerText}
      </p>
      <div className="ActionCard-actions">
        <a href={`/assessments/${props.project.preDD}`} className="ActionCard-action button secondary small">Liste öffnen</a>
      </div>
    </div>
  )

  let carryoverEventCards = []
  // if the event from a previous phase is set for a future date, display it
  for (let mr of props.project.meetingRequests) {
    if (mr.reason === 'Erstes Kennenlernen') {
      // this is it!
      if (mr.approvedDate) {
        const d = new Date(mr.approvedDate)
        const today = new Date()
        if (d.getTime() > today.getTime()) {
          // yeah - it's in the future
          carryoverEventCards.push(
            <EventScheduler
              title="Terminabstimmung: Ersten Kennenlernen"
              description="Stimme einen Termin für einen ersten unverbindlichen Austausch ab, um so alle Beteiligten der Opportunität persönlich kennenzulernen."
              meetingName="Erstes Kennenlernen"
              user={props.user}
              project={props.project}
              isSeller={props.isSeller}
              patchProject={d => props.patchProject(d)}
            />
          )
        }
      }
    }
  }

  let eventCards = []
  const eventTextSeller = 'Stimme einen gemeinsamen Termin ab zur Präsentation des unverbindlichen Vorschlages, welcherdiraufzeigen soll wie eine potentielle Zusammenarbeit nun konkret aussehen könnte.'
  const eventTextBuyer = 'Stimme einen gemeinsamen Termin ab zur Präsentation des unverbindlichen Angebots und Strukturierung der potentiellen Zusammenarbeit.'
  eventCards.push(
    <EventScheduler
      title="Terminabstimmung: Vorstellung des indikativen Angebots"
      description={props.isSeller ? eventTextSeller : eventTextBuyer}
      meetingName="LOI"
      user={props.user}
      project={props.project}
      isSeller={props.isSeller}
      patchProject={d => props.patchProject(d)}
    />
  )

  let filesCard;
  
  if (!props.isSeller || assets.length) {
    filesCard = (
      <div className="ProjectCard ActionCard ProjectCard-full fade-in-up">
        <div className="ProjectCard-info">
          <h3 className="ActionCard-title">Indikative Angebote</h3>
          <p className="ActionCard-body">
            Lege bitte stets die letzte Version mit dem aktuellen Markup des indikativen Angebots hier ab. Bei wirtschaftlicher Übereinkunft muss das von beiden Parteien final unterzeichnete und damit gültige Angebot bzw. Letter of Intent (LOI) hochgeladen und entsprechend die Bereitschaft für den Fortschritt in die Due Diligence Phase bestätigt werden.
          </p>
          <div className="ActionCard-actions">
            <button className="ActionCard-action secondary small" onClick={() => fileUpload.current.click()}>LOI hochladen</button>
            <input type="file" ref={fileUpload} className="invisible" onChange={e => handleFileUpload(e)} />
          </div>
        </div>
  
        <div className="ProjectCard-surface">
          {fileCards}
        </div>
      </div>
    )
  } else {
    filesCard = (
      <div className="ProjectCard ActionCard ProjectCard-full fade-in-up">
        <div className="ProjectCard-info">
          <h3 className="ActionCard-title">Indikative Angebote</h3>
          <p className="ActionCard-body no-margin">
            Psst ... hier erscheint in Kürze dein unverbindliches Angebot. Wir warten noch auf die finale Freigabe und benachrichtigen dich, sobald es etwas zu sehen gibt 😉
          </p>
        </div>
      </div>
    )
  }

  if (props.user.isAdmin) {
    adminCards = [
      ...carryoverEventCards,
      listCard,
      ...eventCards,
      filesCard,
      <AdminActions
        user={props.user}
        project={props.project}
        patchProject={d => props.patchProject(d)}
        options={[
          [ 'Indikative Anforderungsliste', preDDCompleted, preDDCompleted ],
          [ 'Termin LOI', approvedLOIMeeting, approvedLOIMeeting ],
          [ 'LOI', assets.length, assets.length ],
          [ 'Fortschritt', props.project.approvedBySeller, props.project.approvedByBuyer ]
        ]}
      />
    ]
  } else {
    const approvalSellerBody = "Mit deiner Freigabe akzeptierst du die Konditionen gemäss dem unterzeichneten und hochgeladenen indikativen Angebot und signalisierst hiermit deine Bereitschaft in einer diskreten Unternehmensprüfung via Hub weitere Fragen und Dokumente deinem potentiellen Partner offenzulegen. Diese Phase dient deinem Gegenüber und seinen Beratern detaillierte kommerzielle, rechtliche sowie steuerliche Aspekte zu prüfen und später die notwendigen rechtlichen Dokumente für eine mögliche Zusammenarbeit vorzubereiten."
    const approvalBuyerBody = "Mit deiner Freigabe akzeptierst du die Konditionen gemäss dem unterzeichneten und hochgeladenen indikativen Angebot und signalisierst hiermit deine Bereitschaft für eine detaillierte Due Diligence via Hub. Zusammen mit deinen Beratern bist du bereit kommerzielle, rechtliche sowie steuerliche Aspekte zu prüfen und später die notwendigen rechtlichen Dokumente für die Zusammenarbeit entsprechend vorzubereiten."

    const readySellerText = 'Falls du bereit bist für einen ersten, unverbindlichen Vorschlag, welcherdiraufzeigt wie eine potentielle Zusammenarbeit nun konkret aussehen könnte, gilt es nun nur noch einen Termin zur Vorstellung abzustimmen und uns hiermit deine Bereitschaft zu signalisieren.'
    const readyBuyerText = 'Bist du bereit ein indikatives Angebot und die potentielle Strukturierung der möglichen Zusammenarbeit deinem Gegenüber zu präsentieren?'
    userCards = [
      listCard,
      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">Bereit für ein erstes Angebot?</h3>
        <p className="ActionCard-body">
          {props.isSeller ? readySellerText : readyBuyerText}
        </p>
        <div className="ActionCard-actions">
          <div className="toggle-switch button-sized">
            <div
              className={`toggle-option ${showHiddenCards ? 'active' : ''}`}
              onClick={() => updateHiddenCards(true)}
              >
              Bereit
            </div>
            <div
              className={`toggle-option ${showHiddenCards ? '' : 'active'}`}
              onClick={() => updateHiddenCards(false)}
              >
              Nicht bereit
            </div>
          </div>
        </div>
      </div>
    ]

    if (showHiddenCards) {
      userCards = [
        ...userCards,
        ...eventCards
      ]

      userCards.push(filesCard)
    }

    userCards = [
      ...userCards,
      <ApprovalCard
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={e => pickDDOrProceed(e)}
        approved={props.isSeller ? props.project.approvedBySeller : props.project.approvedByBuyer}
        customBody={props.isSeller ? approvalSellerBody : approvalBuyerBody}
        />
    ]
  }

  return (
    <div className="ProjectCards">
      {userCards}
      {adminCards}

      {conditionalDDPicker}
    </div>
  )
}