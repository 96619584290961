import { useEffect, useState, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import Makro from '../../components/Makro';
import ProfileRow from '../../components/ProfileRow';

import DashboardStats from './components/DashboardStats';

import ReactCountryFlag from 'react-country-flag';

import { ChevronDown } from 'react-feather';

import optionService from '../../services/OptionService';

import './style.css';

export default function Dashboard(props) {
  const [projectData, setProjectData] = useState()
  const [searchData, setSearchData] = useState()
  const [searchResults, setSearchResults] = useState({})
  const [showProjectAction, setShowProjectAction] = useState(false)
  const [unverifiedBuyers, setUnverifiedBuyers] = useState()
  const [unverifiedSellers, setUnverifiedSellers] = useState()

  const countryOptions = optionService.countryCodes

  const cookies = useMemo(() => new Cookies(), [])

  const phaseNames = [
    'Unverbindlicher Austausch',
    'Informationsaustausch',
    'Due Diligence',
    'Signing & Closing'
  ]

  const today = new Date()
  let greeting;
  if (today.getHours() < 11) {
    greeting = 'Guetä Morgä,'
  } else if (today.getHours() > 17) {
    greeting = 'Guetä Abig,'
  } else {
    greeting = 'Grüezi,'
  }

  useEffect(() => {
    async function fetchActiveProjects() {
      if (props.user.isAdmin) {
        // fetch the top five projects
        const pResponse = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('p4itoken')
          },
          url: `${Env.url}/projects`
        })

        setProjectData(pResponse.data.filter(p => !p.archived))
      } else {
        let projectList = []
        for (let profile of [...props.user.buyers, ...props.user.sellers]) {
          projectList.push(...profile.projects)
        }

        // fetch the top five projects
        const pResponse = await axios({
          method: 'POST',
            headers: {
              'content-type': 'application/json',
              'Authorization': cookies.get('p4itoken')
            },
            data: { _id: projectList.slice(0, 5) },
            url: `${Env.url}/projects/search`
        })

        setProjectData(pResponse.data.filter(p => !p.archived))
      }
    }

    async function fetchActiveSearches() {
      // fetch the top five projects
      const sResponse = await axios({
        method: 'POST',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('p4itoken')
          },
          data: { _id: props.user.searches },
          url: `${Env.url}/searches/search`
      })

      setSearchData(sResponse.data)

      // fire up each search to count results
      let resultsMap = {}
      for (let search of sResponse.data) {
        let searchData = {}
        for (let fp of JSON.parse(search.params)) {
          // dismiss incomplete filters
          if (fp.indicator && fp.relation && fp.targetValue) {
            let queryParam = {}
            queryParam[fp.relation.value] = fp.targetValue

            searchData[fp.indicator.value] = queryParam
          }
        }

        const s = await axios({
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('p4itoken')
          },
          data: searchData,
          url: `${Env.url}/buyers/search`
        })

        resultsMap[search._id] = s.data
      }

      setSearchResults(resultsMap)
    }

    async function fetchUnverifiedAccounts() {
      const bResponse = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/buyers`
      })

      const sResponse = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/sellers`
      })

      setUnverifiedBuyers(bResponse.data)
      setUnverifiedSellers(sResponse.data)
    }

    if (!projectData) fetchActiveProjects()
    if (!searchData) fetchActiveSearches()
    if (!unverifiedBuyers && props.user.isAdmin) fetchUnverifiedAccounts()
  }, [props.user, cookies, projectData, searchData, unverifiedBuyers])

  let conditionalDashboardStats;
  let nextSteps = [];
  let activeProjects;
  let activeScouts;
  if (!props.user.isAdmin) {
    conditionalDashboardStats = <DashboardStats user={props.user} />

    const findBuyers = props.user.sellers.length > props.user.buyers.length

    nextSteps = [
      <h3 className="Dashboard-section-header fade-in-up delay-200">Shortcuts</h3>,
      <div className="Dashboard-missions fade-in-up delay-200">
        <Makro
          actionLabel="Los geht's"
          label="Speer Scouts™ verwalten"
          target="/subscriptions"
          />

        <Makro
          iconAction
          label="Einstellungen vervollständigen"
          target="/settings"
          />

        <Makro
          iconAction
          label={findBuyers ? 'Investorenprofile finden' : 'Unternehmerprofile finden'}
          target={findBuyers ? '/find/buyers' : '/find/sellers' }
          />
      </div>
    ]

    if (searchData && searchData.length) {
      let tableRowsScouts = searchData.map(search => (
        <tr>
          <td><a href={`/subscriptions/${search._id}`}>{search.title}</a></td>
          <td><span className="badge badge-success badge-bullet">Aktiv</span></td>
          <td>{new Date(search.createdAt).toLocaleDateString('de-DE')}</td>
          <td>{(searchResults[search._id] || []).length}</td>
          <td>{search.params ? JSON.parse(search.params).length : 'N/A'}</td>
        </tr>
      ))

      activeScouts = (
        <div className="fade-in-up delay-300">
          <div className="TableHeader TableHeader-top-margin">
            <h3 className="TableHeader-title">Aktive Speer Scouts™</h3>
            <div className="TableAction">
              <a href="/subscriptions" className="button small secondary">Speer Scouts™</a>
            </div>
          </div>
          <table className="DataTable">
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Erstellt</th>
                <th>Leads</th>
                <th>Filter</th>
              </tr>
            </thead>
            <tbody>
              {tableRowsScouts}
            </tbody>
          </table>
        </div>
      )
    }
  }

  if (projectData && projectData.length) {
    let tableRows = []
    for (let p of projectData.sort((a, b) => a.createdAt < b.createdAt)) {
      let statusBadge;
      if (p.archived) {
        statusBadge = <span className="badge">Archiviert</span>
      } else {
        statusBadge = <span className="badge badge-success badge-bullet">Live</span>
      }

      let actorColumns = []
      if (p.seller.user.toString() === props.user._id.toString()) {
        actorColumns = [
          <td><a href={`/seller-profile/${p.seller._id}`}>{p.seller.name}</a></td>,
          <td className={p.buyer.blurred ? 'flex-cell blurred' : 'flex-cell'}>
            <ReactCountryFlag
              countryCode={countryOptions[p.buyer.country]}
              svg
              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
              cdnSuffix="svg"
              title={countryOptions[p.buyer.country]}
              style={{ width: '1em', height: '1em', }}
              />
            <a href={`/buyer-profile/${p.buyer._id}`}>{p.buyer.name}</a>
          </td>
        ]
      } else if (!props.user.isAdmin) {
        actorColumns = [
          <td><a href={`/buyer-profile/${p.buyer._id}`}>{p.buyer.name}</a></td>,
          <td className={p.seller.blurred ? 'flex-cell blurred' : 'flex-cell'}>
            <ReactCountryFlag
              countryCode={countryOptions[p.seller.country]}
              svg
              cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
              cdnSuffix="svg"
              title={countryOptions[p.seller.country]}
              style={{ width: '1em', height: '1em', }}
              />
            <a href={`/seller-profile/${p.seller._id}`}>{p.seller.name}</a>
          </td>
        ]
      } else {
        actorColumns = [
          <td><a href={`/seller-profile/${p.seller._id}`}>{p.seller.name}</a></td>,
          <td><a href={`/buyer-profile/${p.buyer._id}`}>{p.buyer.name}</a></td>
        ]
      }

      let highlight = false;
      if (p.seller.user.toString() === props.user._id.toString()) {
        highlight = p.sellerOptOut
      } else {
        highlight = p.buyerOptOut
      }

      const tableRow = (
        <tr className={highlight ? 'highlight' : ''}>
          <td><a href={`/projects/${p._id}`}>{p.name}</a></td>
          <td>{statusBadge}</td>
          <td>{Math.round((p.step/phaseNames.length)*100)}%</td>
          <td>{phaseNames[p.step]}</td>
          {actorColumns}
        </tr>
      )

      tableRows.push(tableRow)
    }

    let conditionalTableAction;
    if (props.user.isAdmin) {
      conditionalTableAction = (
        <div className="TableAction">
          <a href='/seller-projects' className="button small secondary">
            Alle anzeigen
          </a>
        </div>
      )
    } else {
      conditionalTableAction = (
        <div className="TableAction">
          <div className={`Dropdown ${showProjectAction ? 'active' : ''}`}>
            <button className="Dropdown-trigger" onClick={() => setShowProjectAction(!showProjectAction)}>
              <span className="Dropdown-trigger-label">
                Alle anzeigen
              </span>
              <ChevronDown size={18} />
            </button>
            <div className={`Dropdown-menu ${showProjectAction ? '' : 'hidden'}`}>
              <a href="/buyer-projects" className="Dropdown-menu-item">Als Investor</a>
              <a href="/seller-projects" className="Dropdown-menu-item">Als Unternehmer</a>
            </div>
          </div>
        </div>
      )
    }

    activeProjects = (
      <div className="fade-in-up delay-300">
        <div className={props.user.isAdmin ? `TableHeader` : `TableHeader TableHeader-top-margin`}>
          <h3 className="TableHeader-title">Aktive Projekte</h3>
          {conditionalTableAction}
        </div>
        <table className="DataTable">
          <thead>
            <tr>
              <th>Id</th>
              <th>Status</th>
              <th>Fortschritt</th>
              <th className="mid">Phase</th>
              <th className="mid_plus">{props.user.isAdmin ? 'Unternehmen' : 'Deine Rolle'}</th>
              <th className="mid_plus">{props.user.isAdmin ? 'Investor' : 'Partner'}</th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>
      </div>
    )
  }

  let ubRows = [];
  if (props.user.isAdmin) {
    if (unverifiedBuyers && unverifiedBuyers.length) {
      for (let rec of unverifiedBuyers) {
        ubRows.push(
          <ProfileRow rec={rec} user={props.user} patchUser={props.patchUser} entity='buyers' />
        )
      }
    }
  }

  let hiddenBuyers;
  if (ubRows.length) {
    hiddenBuyers = (
      <div className="fade-in-up delay-300">
        <div className={props.user.isAdmin ? `TableHeader` : `TableHeader TableHeader-top-margin`}>
          <h3 className="TableHeader-title">Nicht verifizierte Investoren</h3>
        </div>
        <table className="DataTable">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th>Name</th>
              <th>Ausrichtung</th>
              <th>Horizont</th>
              <th>Investment-Fokus</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {ubRows}
          </tbody>
        </table>
      </div>
    )
  }

  let usRows = [];
  if (props.user.isAdmin) {
    if (unverifiedSellers && unverifiedSellers.length) {
      for (let rec of unverifiedSellers) {
        usRows.push(
          <ProfileRow rec={rec} user={props.user} patchUser={props.patchUser} entity='sellers' />
        )
      }
    }
  }

  let hiddenSellers;
  if (usRows.length) {
    hiddenSellers = (
      <div className="fade-in-up delay-300">
        <div className={props.user.isAdmin ? `TableHeader` : `TableHeader TableHeader-top-margin`}>
          <h3 className="TableHeader-title">Nicht verifizierte Unternehmen</h3>
        </div>
        <table className="DataTable">
          <thead>
            <tr>
              <th className="tiny"></th>
              <th className="tiny"></th>
              <th className="mid">Name</th>
              <th>Branche</th>
              <th>Umsatz</th>
              <th>EBIT</th>
              <th>EBITDA</th>
            </tr>
          </thead>
          <tbody>
            {usRows}
          </tbody>
        </table>
      </div>
    )
  }

  return (
    <div className="Dashboard">
      <Aside darkMode={props.darkMode} user={props.user} scope="dashboard" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title"><strong>{greeting}</strong> {props.user.firstName} <span className="emoji-wiggle">👋</span></h1>
              <h5 className="PanelHeader-subline">Speer {props.user.isAdmin ? 'Administrator' : 'Associate'}</h5>
            </div>

            {conditionalDashboardStats}

            {activeProjects}
            {activeScouts}
            {hiddenSellers}
            {hiddenBuyers}

            {nextSteps}
          </div>
        </div>
      </Aside>
    </div>
  )
}