import { useState, useEffect } from 'react';

import StatusBadge from '../../../components/StatusBadge';

import { CheckCircle } from 'react-feather';

export default function ApprovalCard(props) {
  const [approved, setApproved] = useState(props.approved)

  useEffect(() => {
    setApproved(props.approved)
  }, [ props.approved ])

  const phaseNames = [
    'Unverbindlicher Austausch',
    'Informationsaustausch',
    'Due Diligence',
    'Signing & Closing'
  ]

  async function approveStage(newValue) {
    setApproved(newValue)

    if (props.isSeller) {
      await props.patchProject({ approvedBySeller: newValue })
    } else {
      await props.patchProject({ approvedByBuyer: newValue })
    }
  }

  let conditionalApprovalButton;
  if (props.approved) {
    conditionalApprovalButton = (
      <button className="ActionCard-action primary small" onClick={() => approveStage(false)}>
        <CheckCircle size={16} /> <span>{props.customCTALabelSuccess || 'Fortschritt freigegeben'}</span>
      </button>
    )
  } else {
    conditionalApprovalButton = (
      <button className="ActionCard-action secondary small" onClick={() => approveStage(true)}>
        {props.customCTALabelDefault || 'Fortschritt freigeben'}
      </button>
    )
  }

  if (props.user.isAdmin) {
    return <div></div>
  } else {
    return (
      <div className="ProjectCard ActionCard fade-in-up">
        <StatusBadge baseValue={approved} />

        <h3 className="ActionCard-title">
          {props.customTitle || 'Fortschritt freigeben'}
        </h3>

        <p className="ActionCard-body">
          {props.customBody || `Mit deiner Freigabe signalisierst du deine Bereitschaft für die nächste Phase "${phaseNames[props.project.step+1]}". Dein Partner und Speer werden entsprechend informiert.`}
        </p>

        <div className="ActionCard-actions">
          {conditionalApprovalButton}
        </div>
      </div>
    )
  }
}