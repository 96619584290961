import React, { useEffect, useState } from 'react';

import './style.css';

import { X } from 'react-feather';

export default function CustomDatePicker(props) {
  const [dates, setDates] = useState([''])
  const [times, setTimes] = useState([''])
  const [datePickers, setDatePickers] = useState([])

  const today = new Date()
  const tomorrow = new Date()
  tomorrow.setDate(today.getDate()+1)

  useEffect(() => {
    function updateDate(value, index) {
      let newDates = [...dates]
      newDates[index] = value
      setDates(newDates)
    }

    function updateTime(value, index) {
      let newTimes = [...times]
      newTimes[index] = value
      setTimes(newTimes)
    }

    function dropIndex(idx) {
      let newDates = []
      let newTimes = []

      for (let i = 0; i < dates.length; i++) {
        if (i !== idx) {
          newDates.push(dates[i])
          newTimes.push(times[i])
        }
      }

      setDates(newDates)
      setTimes(newTimes)
    }

    let pickerBlocks = []
    for (let i = 0; i < dates.length; i++) {
      pickerBlocks.push(
        <div className="form-field">
          <input type="date" value={dates[i]} onChange={e => updateDate(e.target.value, i)}/>
          <input type="time" value={times[i]} onChange={e => updateTime(e.target.value, i)}/>
          <button type="button" className="circle secondary small button" onClick={() => dropIndex(i)}><X size={16} /></button>
        </div>
      )
    }

    setDatePickers(pickerBlocks)
  }, [dates, times])

  function addNewSlot() {
    setDates([...dates, ''])
    setTimes([...times, ''])
  }

  function saveDates() {
    let timeSlots = []
    for (let i = 0; i < dates.length; i++) {
      let date = new Date(dates[i])

      const [hours, minutes] = times[i].split(':')
      date.setHours(hours)
      date.setMinutes(minutes)

      timeSlots.push(date)
    }

    props.onSave(timeSlots)
  }

  let conditionalSaveButton;
  if (dates[0] && times[0]) {
    conditionalSaveButton = (
      <button type="button" className="button primary small" onClick={() => saveDates()}>
        Vorschläge speichern
      </button>
    )
  }

  return (
    <div className="DatePicker">
      {datePickers}

      <div className="DatePicker-actions">
        <button type="button" className="button link small" onClick={() => addNewSlot()}>
          + weiterer Vorschlag
        </button>

        {conditionalSaveButton}
      </div>
    </div>
  )
}