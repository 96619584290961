import Select from 'react-select';

export default function CustomSelectable(props) {
  const customSelectStyles = {
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: '999'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      border: '1px solid #F1F1F3',
      borderColor: '#F1F1F3',
      borderRadius: '6px',
      boxShadow: 'none'
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      borderRadius: '6px',
      borderColor: '#F1F1F3',
      boxShadow: 'none'
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '9px 18px',
      borderColor: '#F1F1F3',
      boxShadow: 'none'
    }),
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        display: 'none'
      }
    },
    singleValue: (provided, state) => ({
      ...provided,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px'
    }),
    multiValue: (provided, state) => ({
      ...provided,
      background: '#6877B2',
      borderRadius: '6px',
      padding: '6px 12px',
      marginRight: '6px'
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '16px',
      color: '#FDFDFF'
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      background: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      background: state.isFocused ? '#EEF1FC' : 'none',
      color: '#6877B2'
    })
  }

  return (
    <Select
      isMulti={props.isMulti}
      placeholder={props.placeholder}
      classNamePrefix={props.classNamePrefix}
      styles={customSelectStyles}
      options={props.options}
      value={props.value}
      onChange={props.onChange}
      menuPortalTarget={document.body}
      />
  )
}