import { useState, useEffect } from "react";

import Makro from '../../Makro';

export default function ProjectAdminFeed(props) {
  const [actionFeed, setActionFeed] = useState([])

  useEffect(() => {
    let newActionFeed = []

    if (props.project && props.project.buyer) {
      newActionFeed.push(
        <Makro
          iconAction
          label="Investorenprofil"
          target={`/buyer-profile/${props.project.buyer._id}`}
        />
      )

      newActionFeed.push(
        <Makro
          iconAction
          label="Profileinstellungen"
          target={`/settings/buyers/${props.project.buyer._id}`}
        />
      )
    }

    if (props.project && props.project.seller) {
      newActionFeed.push(
        <Makro
          iconAction
          label="Unternehmerprofil"
          target={`/settings/sellers/${props.project.seller._id}`}
        />
      )
    }

    if (newActionFeed.length) setActionFeed(newActionFeed)
  }, [ props ])

  return actionFeed;
}