import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Aside from '../../components/Aside';

import { Search, ArrowRight } from 'react-feather';

import optionService from '../../services/OptionService';

import './style.css';

export default function SearchPrompt(props) {
  const [prompt, setPrompt] = useState('')
  const [entity, setEntity] = useState()
  const [searchPlaceholder, setSearchPlaceholder] = useState('')

  const { scope } = useParams()

  useEffect(() => {
    if (!entity) {
      if (scope === 'buyers' || scope === 'sellers') {
        setEntity(scope)
      } else {
        setEntity(props.user.sellers.length > props.user.buyers.length ? 'buyers' : 'sellers')
      }
    }
  }, [props.user, scope, entity])

  useEffect(() => {
    let c = [];
    let s = [];
    if (entity === 'buyers') {
      c = optionService.countries
      s = [
        'Privater Investor',
        'Family Office',
        'Öffentliche Hand',
        'Private Debt Fund',
        'Minderheitbeteiligung',
        'Mehrheitsbeteiligung',
        'Private Debt',
        'Darlehen',
        'Mezzanine-Kapital',
        'Kapitalerhöhung'
      ]
    } else {
      c = optionService.countries
      s = optionService.sectors
    }

    const p1 = s[Math.floor(Math.random() * s.length)]
    const p2 = c[Math.floor(Math.random() * c.length)]

    setSearchPlaceholder(p1.length < 10 ? `z.B. ${p1} ${p2}` : `z.B. ${p1}`)
  }, [entity])

  function submitSearch(evt) {
    evt.preventDefault()
    document.location = `/find/${entity}/?query=${prompt}`
  }

  return (
    <div className="SearchPrompt">
      <Aside darkMode={props.darkMode} user={props.user} scope="search" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="SearchPrompt-panel">
            <h1 className="SearchPrompt-title fade-in-up">
              <span className="correction">{entity === 'sellers' ? 'Erweitere' : 'Skaliere'}</span> {entity === 'sellers' ? 'dein Portfolio' : 'dein Unternehmen'}.
            </h1>
            <form className="SearchPrompt-form fade-in-up delay-100" onSubmit={e => submitSearch(e)}>
              <input type="text" value={prompt} onChange={e => setPrompt(e.target.value)}className="SearchPrompt-input" placeholder={searchPlaceholder} autoFocus />
              <div className="SearchPrompt-input-icon"><Search /></div>
              <button type="submit" className="button primary circle"><ArrowRight color='white' size={18} /></button>
            </form>

            <div className="toggle-switch fade-in-up delay-200">
              <div
                className={`toggle-option ${entity === 'sellers' ? 'active' : ''}`}
                onClick={() => setEntity('sellers')}
                >
                  Unternehmer
                </div>
              <div
                className={`toggle-option ${entity === 'buyers' ? 'active' : ''}`}
                onClick={() => setEntity('buyers')}
                >
                  Investoren
              </div>
            </div>
          </div>
        </div>
      </Aside>
    </div>
  )
}