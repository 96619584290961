import React, { useState } from 'react';

import BuyerFeed from './components/BuyerFeed';
import SellerFeed from './components/SellerFeed';
import SubscriptionsFeed from './components/SubscriptionsFeed';
import SellerProjectsFeed from './components/SellerProjectsFeed';
import BuyerProjectsFeed from './components/BuyerProjectsFeed';
import ProjectSellerFeed from './components/ProjectSellerFeed';
import ProjectBuyerFeed from './components/ProjectBuyerFeed';
import ProjectAdminFeed from './components/ProjectAdminFeed';

import logo from '../../assets/28v.svg';
import logoW from '../../assets/28v_white.svg';

import './style.css';

export default function WidgetFeed(props) {
  const [showMakros, setShowMakros] = useState(false)

  let enabledMakros = []
  if (showMakros) {
    let scopeMakros;
    switch (props.scope) {
      case 'seller':
        scopeMakros = <SellerFeed seller={props.seller} user={props.user} patchUser={v => props.patchUser(v)} />
        break;
      case 'buyer':
        scopeMakros = <BuyerFeed buyer={props.buyer} user={props.user} patchUser={v => props.patchUser(v)} />
        break;
      case 'subscriptions':
        scopeMakros = <SubscriptionsFeed user={props.user} searchResults={props.searchResults} />
        break;
      case 'seller-projects':
        scopeMakros = <SellerProjectsFeed user={props.user} projects={props.projects} />
        break;
      case 'buyer-projects':
        scopeMakros = <BuyerProjectsFeed user={props.user} projects={props.projects} />
        break;
      case 'project':
        if (props.isSeller) {
          scopeMakros = <ProjectSellerFeed user={props.user} project={props.project} patchProject={p => props.patchProject(p)} updateActiveScope={props.updateActiveScope} />
        } else if (props.isAdmin) {
          scopeMakros = <ProjectAdminFeed user={props.user} project={props.project} patchProject={p => props.patchProject(p)} updateActiveScope={props.updateActiveScope} />
        } else {
          scopeMakros = <ProjectBuyerFeed user={props.user} project={props.project} patchProject={p => props.patchProject(p)} updateActiveScope={props.updateActiveScope} />
        }
        break;
      default:
        // nothing to do
    }

    enabledMakros = [props.children, scopeMakros]
  }

  return (
    <div className="WidgetFeed">
      {enabledMakros}

      <div className={`WidgetFeed-toggle ${showMakros ? 'WidgetFeed-toggle--active' : ''}`} onClick={() => setShowMakros(!showMakros)}>
        <img
          alt=""
          src={showMakros ? logoW : logo}
          className="WidgetFeed-toggle-icon"
          />
      </div>
    </div>
  )
}