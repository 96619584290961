import { useState } from 'react';

import Accordion from '../../../components/Accordion';

import { X } from 'react-feather';

export default function ProfileDeletion(props) {
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)

  let conditionalDeletionButton;
  if (props.seller.projects && props.seller.projects.length) {
    conditionalDeletionButton = (
      <div className="ProjectCard ActionCard ActionCard--white">
        <h3 className="ActionCard-title">Profil löschen</h3>
        <p className="ActionCard-body">
          <small className="text-muted">Da du mit diesem Profil in mindestens eine Transaktion eingebunden bist, kann das Profil nicht gelöscht werden.</small>
        </p>

        <div className="ActionCard-actions">
          <button className="button danger small disabled" disabled>Profil löschen</button>
        </div>
      </div>
    )
  } else {
    conditionalDeletionButton = (
      <div className="ProjectCard ActionCard ActionCard--white">
        <h3 className="ActionCard-title">Profil löschen</h3>
        <p className="ActionCard-body">
          <small className="text-muted">Mit dem Löschen verlierst du den Zugriff auf deine Unternehmensdaten und verschwindest aus der Suche.</small>
        </p>

        <div className="ActionCard-actions">
          <button className="button danger small" onClick={() => setShowDeletionConfirmation(true)}>Profil löschen</button>
        </div>
      </div>
    )
  }

  let conditionalDeactivationButton;
  if (props.seller.active) {
    conditionalDeactivationButton = (
      <div className="ProjectCard ActionCard ActionCard--white">
        <h3 className="ActionCard-title">Profil verbergen</h3>
        <p className="ActionCard-body">
          <small className="text-muted">Verberge dein Unternehmensprofil um es vorübergehend aus der Suche zu verstecken.</small>
        </p>

        <div className="ActionCard-actions">
          <button className="button danger small" onClick={() => props.patchSeller({ active: false })}>Profil verbergen</button>
        </div>
      </div>
    )
  } else {
    conditionalDeactivationButton = (
      <div className="ProjectCard ActionCard ActionCard--white">
        <h3 className="ActionCard-title">Profil verbergen</h3>
        <p className="ActionCard-body">
          <small className="text-muted">Aktiviere dein Unternehmensprofil um es wieder in der Suche anzuzeigen.</small>
        </p>

        <div className="ActionCard-actions">
          <button className="button primary small" onClick={() => props.patchSeller({ active: true })}>Profil reaktivieren</button>
        </div>
      </div>
    )
  }

  let conditionalDeletionConfirmation;
  if (showDeletionConfirmation) {
    conditionalDeletionConfirmation = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={() => setShowDeletionConfirmation(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Bist du sicher?</h3>
              <p className="overlay-intro">
                Diese Aktion kann nicht rückgängig gemacht werden und du verlierst sofort all deine Profildaten.
              </p>
            </div>

            <button className="button danger full-width margin-top" onClick={() => props.deleteSeller()}>Ja, wirklich löschen</button>
            <button className="button primary full-width margin-top" onClick={() => setShowDeletionConfirmation(false)}>Nein, schon gut</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Accordion
        title="Profil deaktivieren"
        toggleState={props.toggleState}
        body="Wenn du dieses Unternehmensprofil nicht mehr verwenden möchtest, kannst du es deaktivieren oder vorübergehend pausieren."
        badgeLabel='Irreversibel'
        badgeStyle='badge-danger'
      >
        <div className="Settings-fields Settings-fields-c2">
          {conditionalDeletionButton}
          {conditionalDeactivationButton}
        </div>

      </Accordion>

      {conditionalDeletionConfirmation}
    </>
  )
}