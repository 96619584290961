import ApRecord from "./ApRecord"

export default function ApRecordRow(props) {
  if (props.target && props.target.length) {
    return props.target.map(ap => (
      <ApRecord
        ap={ap}
        attr={props.attr}
        target={props.target}
        setter={props.setter}
        seller={props.seller}
        editMode={props.editMode}
        editName={props.editName}
        editValue={props.editValue}
        menuTarget={props.menuTarget}
        setEditName={props.setEditName}
        setEditValue={props.setEditValue}
        toggleEditMode={props.toggleEditMode}
        updateMenuTarget={props.updateMenuTarget}
        deleteAdjustment={props.deleteAdjustment}
        saveAndLeaveEditMode={props.saveAndLeaveEditMode}
        />
    ))
  } else {
    return (
      <tr>
        <td colSpan={4} className="placeholder">
          <p className="text-muted">Keine Einträge hinterlegt.</p>
        </td>
      </tr>
    )
  }
}