import './style.css';

export default function ProgressBar(props) {
  function jumpBack(newStep) {
    if (newStep <= props.activeStep) props.setStep(newStep)
  }

  const stepCounters = [0, 1, 2, 3, 4].map(e => {
    let additionalClass;
    if (e < props.activeStep) {
      additionalClass = 'ProgressBar-step-done'
    } else if (props.activeStep === e) {
      additionalClass = 'ProgressBar-step-active'
    }

    return (
      <div
        key={e}
        className={['ProgressBar-step', additionalClass].join(' ')}
        onClick={() => jumpBack(e)}
        >
        {e+1}
      </div>
    )
  })

  let conditionalLastCounter;
  if (props.activeStep > 4) {
    conditionalLastCounter = (
      <div className='ProgressBar-hidden-step'>Verifikation</div>
    )
  }

  return (
    <div className="ProgressBar">
      <div className="ProgressBar-indicator" style={{ width: `${(props.activeStep/4)*100}%` }} />
      {stepCounters}
      {conditionalLastCounter}
    </div>
  )
}