import React, { useState } from 'react';

import './style.css';

import axios from 'axios';

import Env from '../../Environments';

import speerLogo from '../../assets/logo.svg';
import speerLogoLight from '../../assets/logo_light.svg';

import logoCRK from '../../assets/logo_CRK.svg';
import logoSITS from '../../assets/logo_SITS.svg';
import logoEvidence from '../../assets/logo_evidence.png';
import logoDBI from '../../assets/logo_DBI.png';

import ActivityLogger from '../../services/ActivityLogger';

import { Cpu, Layers, TrendingUp, AlertCircle } from 'react-feather';

export default function Login(props) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [warning, setWarning] = useState();

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      const user = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        data: {
          username: username,
          password: password
        },
        url: `${Env.url}/users/login`
      });

      if (user.data) {
        await ActivityLogger.log({
          user: user.data,
          description: 'hat sich angemeldet',
          category: 'Auth'
        })

        props.signInUser(user.data)
      } else {
        setWarning('E-Mail oder Passwort ist ungültig.')
      }
    } catch (e) {
      setWarning('E-Mail oder Passwort ist ungültig.')
    }
  }

  let conditionalWarning;
  if (warning) {
    conditionalWarning = <p className="warning"><AlertCircle size={16} color='tomato' /> {warning}</p>
  }

  return (
    <div className="Login">
      <div className="Login-info">
        <h4>Zugang zum digitalen Mittelstand</h4>
        <h1>M&A neu gedacht</h1>

        <div className="Login-benefits">
          <div className="Login-benefit fade-in-up">
            <div className="benefit-icon">
              <Cpu size={22} color='white' />
            </div>
            <div className="benefit-body">
              <h3>Proaktives Sourcing durch M&A KI</h3>
              <p>Finde Opportunitäten über viele Datenbanken hinweg und erhalte proaktive Vorschläge noch bevor diese auf anderen Kanälen zu finden sind.</p>
            </div>
          </div>

          <div className="Login-benefit fade-in-up delay-100">
            <div className="benefit-icon">
              <Layers size={22} color='white' />
            </div>
            <div className="benefit-body">
              <h3>Holistische Prozessabwicklung</h3>
              <p>Von der initialen Kontaktaufnahme über den vertraulichen Austausch von Dokumenten bis hin zum finalen Unterzeichnen des Vertragswerkes stützt sich deine Transaktion auf den Hub.</p>
            </div>
          </div>

          <div className="Login-benefit fade-in-up delay-200">
            <div className="benefit-icon">
              <TrendingUp size={22} color='white' />
            </div>
            <div className="benefit-body">
              <h3>Automatisiertes Wachstum</h3>
              <p>Entwickle eine Wachstumsstrategie auf Basis von Transaktions- und Unternehmensdaten im Hub und vielen weiteren öffentlichen Datenbanken. Planen deinen Wachstum im Voraus.</p>
            </div>
          </div>
        </div>

        <div className="separator fade-in-up delay-1000">
          Unternehmen, die uns vertrauen:
        </div>

        <div className="logo-grid fade-in-up delay-1000">
          <div className="logo-grid-tile">
            <img alt="Logo CRK" src={logoCRK} className="logo-grid-item-small" />
          </div>
          <div className="logo-grid-tile">
            <img alt="Logo SITS" src={logoSITS} className="logo-grid-item" />
          </div>
          <div className="logo-grid-tile">
            <img alt="Logo Evidence" src={logoEvidence} className="logo-grid-item-big" />
          </div>
          <div className="logo-grid-tile">
            <img alt="Logo DBI" src={logoDBI} className="logo-grid-item" />
          </div>
        </div>
      </div>

      <div className="Login-form fade-in-up">
        <div className="Login-form-inner">
          <div className="Login-form-logo">
            <img src={props.darkMode ? speerLogoLight : speerLogo} alt="Speer Logo" className="fade-in" />
          </div>
          <div className="Login-form-intro">
            <h3><strong>Anmelden</strong> in Speer</h3>

            <form className="Login-form-fields" onSubmit={handleSubmit}>
              <div className="form-field">
                <label>E-Mail</label>
                <input
                  type="text"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  >
                </input>
              </div>

              <div className="form-field">
                <label>Passwort</label>
                <input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  >
                </input>
                <small className="form-field-sidenote">
                  <a href="/">Passwort vergessen?</a>
                </small>
              </div>

              {conditionalWarning}

              <div className="form-cta">
                <button className="primary full-width">Anmelden</button>
                <p>Du bist neu hier? <a href="/signup">Jetzt registrieren</a></p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}