import { useEffect, useState, useMemo } from 'react';

import Aside from '../../components/Aside';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import { ArrowLeft, X } from 'react-feather';

export default function Subscriptions(props) {
  const [searches, setSearches] = useState([])
  const [fetchedSearches, setFetchedSearches] = useState(false)
  const [searchResults, setSearchResults] = useState({})
  const [entity, setEntity] = useState('buyers')

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchSearches() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/searches`
      })

      const searchData = response.data.filter(d => d.scope === entity)
      setSearches(searchData)

      // fire up each search to count results
      let resultsMap = {}
      for (let search of searchData) {
        let searchData = {}
        for (let fp of JSON.parse(search.params)) {
          // dismiss incomplete filters
          if (fp.indicator && fp.relation && fp.targetValue) {
            let queryParam = {}
            queryParam[fp.relation.value] = fp.targetValue

            searchData[fp.indicator.value] = queryParam
          }
        }

        const s = await axios({
          method: 'POST',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('p4itoken')
          },
          data: searchData,
          url: `${Env.url}/buyers/search`
        })

        resultsMap[search._id] = s.data
      }

      setSearchResults(resultsMap)
    }

    if (!fetchedSearches) {
      fetchSearches()
      setFetchedSearches(true)
    }
  }, [fetchedSearches, cookies, entity])

  function updateEntity(newEntity) {
    setEntity(newEntity)
    setFetchedSearches(false)
  }

  async function deleteSearch(search) {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/searches/${search._id}`
    })

    setFetchedSearches(false)
  }

  let tableRows = [];

  for (let search of searches) {
    tableRows.push(
      <tr>
        <td><a href={`/subscriptions/${search._id}`}>{search.title}</a></td>
        <td><span className="badge badge-success badge-bullet">Aktiv</span></td>
        <td>{new Date(search.createdAt).toLocaleDateString('de-DE')}</td>
        <td>{(searchResults[search._id] || []).length}</td>
        <td>{JSON.parse(search.params).length}</td>
        <td></td>
        <td>
          <div className="button circle danger small" onClick={() => deleteSearch(search)}>
            <X size={16} color='white' />
          </div>
        </td>
      </tr>
    )
  }

  let conditionalDataTable;
  if (tableRows.length) {
    conditionalDataTable = (
      <>
        <table className="DataTable">
          <thead>
            <tr>
              <th className="mid">Name</th>
              <th>Status</th>
              <th>Erstellt</th>
              <th>Leads</th>
              <th>Filter</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {tableRows}
          </tbody>
        </table>

        <div className="placeholder-block fade-in-up margin-top">
          <div className="placeholder-inner">
            <h3 className="placeholder-title">Weitere Speer Scouts™ erstellen</h3>
            <p className="placeholder-body">Erstelle beliebig viele weitere Speer Scouts™. Starte und speichere eine neue Suche mit passenden Parametern, um automatisiert nach passenden Opportunitäten zu suchen.</p>
            <div className="placeholder-actions">
              <a className="button primary small" href="/search/buyers">Investoren suchen</a>
              <a className="button primary small" href="/search/sellers">Unternehmen suchen</a>
            </div>
          </div>
        </div>
      </>
    )
  } else {
    conditionalDataTable = (
      <div className="placeholder-block fade-in-up">
        <div className="placeholder-inner">
          <h3 className="placeholder-title">Keine Speer Scouts™ gespeichert</h3>
          <p className="placeholder-body">Du hast keine aktiven Speer Scouts™. Starte und speichere eine neue Suche mit passenden Parametern, um automatisiert nach passenden Opportunitäten zu suchen.</p>
          <div className="placeholder-actions">
            <a className="button primary small" href="/search/buyers">Investoren suchen</a>
            <a className="button primary small" href="/search/sellers">Unternehmen suchen</a>
          </div>
        </div>
      </div>
    )
  }
  
  return (
    <div className="Subscriptions">
      <Aside darkMode={props.darkMode} user={props.user} scope="subscriptions" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title">Speer <strong>Scouts™</strong></h1>
              <h5 className="PanelHeader-subline">{searches.length} aktive{searches.length === 1 ? 'r' : ''} Speer Scout{searches.length === 1 ? '' : 's'}™</h5>
            </div>

            <div className="Project-header">
              <div className="Breadcrumbs">
                <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                  <ArrowLeft size={18} color='#6B6E97' />
                </div>

                <div className="Breadcrumbs-path">
                  <a href="/">Dashboard</a>
                  <span>/</span>
                  <a href="/subscriptions" className="active">Speer Scouts™</a>
                </div>
              </div>

              <div className="toggle-switch fade-in-up">
                <div
                  className={`toggle-option ${entity === 'sellers' ? 'active' : ''}`}
                  onClick={() => updateEntity('sellers')}
                  >
                    Unternehmen
                  </div>
                <div
                  className={`toggle-option ${entity === 'buyers' ? 'active' : ''}`}
                  onClick={() => updateEntity('buyers')}
                  >
                    Investoren
                </div>
              </div>
            </div>

            {conditionalDataTable}
          </div>
        </div>
      </Aside>
    </div>
  )
}
