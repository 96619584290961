import React, { useState, useEffect, useCallback } from 'react';

import NumberInput from '../../../components/NumberInput';

import Accordion from '../../../components/Accordion';

export default function FinanceData(props) {
  const [score, setScore] = useState(0)

  const [currentRevenueFC, setCurrentRevenueFC] = useState(0)
  const [currentRevenue, setCurrentRevenue] = useState(0)
  const [currentRevenueX1, setCurrentRevenueX1] = useState(0)
  const [currentRevenueX2, setCurrentRevenueX2] = useState(0)
  const [currentRevenueX3, setCurrentRevenueX3] = useState(0)

  const [ebitdaFC, setEbitdaFC] = useState(0)
  const [ebitda, setEbitda] = useState(0)
  const [ebitdaX1, setEbitdaX1] = useState(0)
  const [ebitdaX2, setEbitdaX2] = useState(0)
  const [ebitdaX3, setEbitdaX3] = useState(0)

  const [ebitFC, setEbitFC] = useState(0)
  const [ebit, setEbit] = useState(0)
  const [ebitX1, setEbitX1] = useState(0)
  const [ebitX2, setEbitX2] = useState(0)
  const [ebitX3, setEbitX3] = useState(0)
  
  const [fieldsSet, setFieldsSet] = useState(false)

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [
      currentRevenueFC, currentRevenue, currentRevenueX1, currentRevenueX2, currentRevenueX3,
      ebitdaFC, ebitda, ebitdaX1, ebitdaX2, ebitdaX3,
      ebitFC, ebit, ebitX1, ebitX2, ebitX3]
    properties.forEach(p => {
      if (p && p !== '') newScore += 100/properties.length
    })
  
    setScore(Math.round(newScore))
  }, [currentRevenueFC, currentRevenue, currentRevenueX1, currentRevenueX2, currentRevenueX3, ebitdaFC, ebitda, ebitdaX1, ebitdaX2, ebitdaX3, ebitFC, ebit, ebitX1, ebitX2, ebitX3])

  useEffect(() => {
    if (props.seller._id && !fieldsSet) {
      setCurrentRevenueFC(props.seller.currentRevenueFC)
      setCurrentRevenue(props.seller.currentRevenue)
      setCurrentRevenueX1(props.seller.currentRevenueX1)
      setCurrentRevenueX2(props.seller.currentRevenueX2)
      setCurrentRevenueX3(props.seller.currentRevenueX3)

      setEbitdaFC(props.seller.ebitdaFC)
      setEbitda(props.seller.ebitda)
      setEbitdaX1(props.seller.ebitdaX1)
      setEbitdaX2(props.seller.ebitdaX2)
      setEbitdaX3(props.seller.ebitdaX3)

      setEbitFC(props.seller.ebitFC)
      setEbit(props.seller.ebit)
      setEbitX1(props.seller.ebitX1)
      setEbitX2(props.seller.ebitX2)
      setEbitX3(props.seller.ebitX3)

      setFieldsSet(true)
    }

    updateScore()
  }, [props.seller, fieldsSet, updateScore])

  function universalSetter(attr, attrSetter, value) {
    attrSetter(value)

    let patchData = {}
    patchData[attr] = value

    updateScore()

    props.patchSeller(patchData)
  }

  function renderConditionalWarning(value, comparison, valueLabel, comparisonLabel) {
    if (comparison < value) {
      return (
        <div className="warning"><small><small>{valueLabel} darf nicht größer als {comparisonLabel} sein!</small></small></div>
      )
    }
  }

  return (
    <Accordion
      title="Finanzdaten"
      toggleState={props.toggleState}
      body="Bitte versuche die Kennzahlen des aktuellen sowie mindestens der vergangenen drei Geschäftsjahre aktuell zu halten."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
    >
      <h3 className="settings-section-title">FY{new Date().getFullYear()+1} <span className="text-muted">FC</span></h3>
      <div className="Settings-fields">
        <div className="form-field">
          <label>Jahresumsatz</label>
          <NumberInput
            suffix={props.seller.currency}
            value={currentRevenueFC}
            onChange={v => universalSetter('currentRevenueFC', setCurrentRevenueFC, v)}
            />
        </div>

        <div className="form-field">
          <label>EBITDA</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitdaFC}
            onChange={v => universalSetter('ebitdaFC', setEbitdaFC, v)}
            />
          {renderConditionalWarning(ebitdaFC, currentRevenueFC, 'EBITDA', 'Jahresumsatz')}
        </div>

        <div className="form-field">
          <label>EBIT</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitFC}
            onChange={v => universalSetter('ebitFC', setEbitFC, v)}
            />
          {renderConditionalWarning(ebitFC, currentRevenueFC, 'EBIT', 'Jahresumsatz')}
        </div>
      </div>

      <h3 className="settings-section-title">FY{new Date().getFullYear()} <span className="text-muted">Budget</span></h3>
      <div className="Settings-fields">
        <div className="form-field">
          <label>Jahresumsatz</label>
          <NumberInput
            suffix={props.seller.currency}
            value={currentRevenue}
            onChange={v => universalSetter('currentRevenue', setCurrentRevenue, v)}
            />
        </div>

        <div className="form-field">
          <label>EBITDA</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitda}
            onChange={v => universalSetter('ebitda', setEbitda, v)}
            />
            {renderConditionalWarning(ebitda, currentRevenue, 'EBITDA', 'Jahresumsatz')}
        </div>

        <div className="form-field">
          <label>EBIT</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebit}
            onChange={v => universalSetter('ebit', setEbit, v)}
            />
            {renderConditionalWarning(ebit, currentRevenue, 'EBIT', 'Jahresumsatz')}
        </div>
      </div>

      <div className="divider" />

      <h3 className="settings-section-title">FY{new Date().getFullYear()-1} <span className="text-muted">IST</span></h3>
      <div className="Settings-fields">
        <div className="form-field">
          <label>Jahresumsatz</label>
          <NumberInput
            suffix={props.seller.currency}
            value={currentRevenueX1}
            onChange={v => universalSetter('currentRevenueX1', setCurrentRevenueX1, v)}
            />
        </div>

        <div className="form-field">
          <label>EBITDA</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitdaX1}
            onChange={v => universalSetter('ebitdaX1', setEbitdaX1, v)}
            />
            {renderConditionalWarning(ebitdaX1, currentRevenueX1, 'EBITDA', 'Jahresumsatz')}
        </div>

        <div className="form-field">
          <label>EBIT</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitX1}
            onChange={v => universalSetter('ebitX1', setEbitX1, v)}
            />
            {renderConditionalWarning(ebitX1, currentRevenueX1, 'EBIT', 'Jahresumsatz')}
        </div>
      </div>

      <h3 className="settings-section-title">FY{new Date().getFullYear()-2} <span className="text-muted">IST</span></h3>
      <div className="Settings-fields">
        <div className="form-field">
          <label>Jahresumsatz</label>
          <NumberInput
            suffix={props.seller.currency}
            value={currentRevenueX2}
            onChange={v => universalSetter('currentRevenueX2', setCurrentRevenueX2, v)}
            />
        </div>

        <div className="form-field">
          <label>EBITDA</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitdaX2}
            onChange={v => universalSetter('ebitdaX2', setEbitdaX2, v)}
            />
            {renderConditionalWarning(ebitdaX2, currentRevenueX2, 'EBITDA', 'Jahresumsatz')}
        </div>

        <div className="form-field">
          <label>EBIT</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitX2}
            onChange={v => universalSetter('ebitX2', setEbitX2, v)}
            />
            {renderConditionalWarning(ebitX2, currentRevenueX2, 'EBIT', 'Jahresumsatz')}
        </div>
      </div>

      <h3 className="settings-section-title">FY{new Date().getFullYear()-3} <span className="text-muted">IST</span></h3>
      <div className="Settings-fields">
        <div className="form-field">
          <label>Jahresumsatz</label>
          <NumberInput
            suffix={props.seller.currency}
            value={currentRevenueX3}
            onChange={v => universalSetter('currentRevenueX3', setCurrentRevenueX3, v)}
            />
        </div>

        <div className="form-field">
          <label>EBITDA</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitdaX3}
            onChange={v => universalSetter('ebitdaX3', setEbitdaX3, v)}
            />
            {renderConditionalWarning(ebitdaX3, currentRevenueX3, 'EBITDA', 'Jahresumsatz')}
        </div>

        <div className="form-field">
          <label>EBIT</label>
          <NumberInput
            suffix={props.seller.currency}
            value={ebitX3}
            onChange={v => universalSetter('ebitX3', setEbitX3, v)}
            />
            {renderConditionalWarning(ebitX3, currentRevenueX3, 'EBIT', 'Jahresumsatz')}
        </div>
      </div>
    </Accordion>
  )
}