import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'universal-cookie'

import Env from '../../Environments';

import Aside from '../../components/Aside';
import WidgetFeed from '../../components/WidgetFeed';

import BaseData from './components/BaseData';
import FinanceData from './components/FinanceData';
import QuickFacts from './components/QuickFacts';
import AdjustmentSettings from './components/AdjustmentSettings';
import DocumentSettings from './components/DocumentSettings';
import PartnerSettings from './components/PartnerSettings';
import ProfileDeletion from './components/ProfileDeletion';
import AdminActions from './components/AdminActions';

import './style.css';

export default function SellerSettings(props) {
  const [seller, setSeller] = useState({})
  const [showScope, setShowScope] = useState('')

  const cookies = useMemo(() => new Cookies(), [])

  const { sid } = useParams()

  useEffect(() => {
    const hrefSplit = document.location.href.split('#')
    if (hrefSplit.length > 1) setShowScope(hrefSplit[1])
  }, [showScope])

  async function patchSeller(data) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/sellers/${sid}`
    })

    setSeller(response.data)

    return response.data
  }

  async function deleteSeller() {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/sellers/${sid}`
    })

    document.location = '/seller-profiles'
  }

  useEffect(() => {
    async function fetchSeller() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/sellers/${sid}`
      })

      setSeller(response.data)
    }

    if (!seller._id) fetchSeller()
  }, [cookies, sid, seller._id])

  let conditionalAdminActions;
  if (props.user.isAdmin) {
    conditionalAdminActions = (
      <AdminActions seller={seller} deleteSeller={deleteSeller} patchSeller={patchSeller} toggleState={showScope === 'profile-deletion'} />
    )
  }

  return (
    <div className="SellerSettings">
      <Aside darkMode={props.darkMode} user={props.user} scope="settings" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title"><strong>Firmeneinstellungen</strong> für {seller.name} {seller.type}</h1>
              <h5 className="PanelHeader-subline">Unternehmensdaten, Projektvorbereitung & Partnerverwaltung</h5>
            </div>

            <BaseData seller={seller} patchSeller={patchSeller} toggleState={showScope === 'base-data'} />
            <QuickFacts seller={seller} patchSeller={patchSeller} toggleState={showScope === 'quick-facts'} />
            <FinanceData seller={seller} patchSeller={patchSeller} toggleState={showScope === 'finance-data'} />
            <AdjustmentSettings seller={seller} patchSeller={patchSeller} toggleState={showScope === 'adjustment-settings'} />
            <DocumentSettings user={props.user} seller={seller} patchSeller={patchSeller} toggleState={showScope === 'document-settings'} />
            <PartnerSettings seller={seller} patchSeller={patchSeller} toggleState={showScope === 'partner-settings'} />
            <ProfileDeletion seller={seller} deleteSeller={deleteSeller} patchSeller={patchSeller} toggleState={showScope === 'profile-deletion'} />
            {conditionalAdminActions}
          </div>

          <div className="ContentPanel-sidebar">
            <WidgetFeed scope="seller-settings" />
          </div>
        </div>
      </Aside>
    </div>
  )
}