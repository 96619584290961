import { useEffect, useState, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../../Environments';

import EventScheduler from '../../../components/EventScheduler';
import CustomCreatable from '../../../components/CustomCreatable';

import AdminActions from './AdminActions';
import ApprovalCard from './ApprovalCard';

import { X } from 'react-feather';

export default function Stage3(props) {
  const [assessments, setAssessments] = useState([])
  const [showHiddenCards, setShowHiddenCards] = useState(false)
  const [fetchedAssessments, setFetchedAssessments] = useState(false)
  const [showNewAssessmentModal, setShowNewAssessmentModal] = useState(false)
  const [newAssessment, setNewAssessment] = useState()

  const assessmentOptions = []

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchAssessments() {
      let aList = []
      for (let a of props.project.assessments) {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('p4itoken')
          },
          url: `${Env.url}/assessments/${a}`
        })

        aList.push(response.data)
      }

      setAssessments(aList)
    }

    if (!fetchedAssessments) {
      fetchAssessments()
      setFetchedAssessments(true)
    }

    setShowHiddenCards(props.isSeller ? props.project.sellerReadyForDD2 : props.project.buyerReadyForDD2)
  }, [props.project, fetchedAssessments, props.project.assessments, cookies, props.isSeller])

  async function updateHiddenCards(v) {
    setShowHiddenCards(v)
    let changeData = {}

    if (props.isSeller) {
      changeData['sellerReadyForDD2'] = v
      await props.patchProject(changeData)
    } else {
      changeData['buyerReadyForDD2'] = v
    }

    if (!props.user.isAdmin) await props.patchProject(changeData)
  }

  async function createNewAssessment() {
    // create new assessment
    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: {
        owner: props.user._id,
        project: props.project._id,
        name: newAssessment.label
      },
      url: `${Env.url}/assessments`
    })

    // attach assessment to project DD
    await props.patchProject({
      assessments: [...assessments, response.data]
    })

    setShowNewAssessmentModal(false)
    setFetchedAssessments(false)
  }

  let userCards = [];
  for (let assessment of assessments) {
    userCards.push(
      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">{assessment.name}</h3>
        <p className="ActionCard-body">Erfülle alle Anfragen, die dein Partner an dich stellt. Deine Angaben werden vor ihrer Freigabe durch Speer geprüft.</p>
        <div className="ActionCard-actions">
          <a href={`/assessments/${assessment._id}`} className="button ActionCard-action secondary small">Liste öffnen</a>
        </div>
      </div>
    )
  }

  if (!props.isSeller) {
    userCards.push(
      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">Neue Anfragenliste</h3>
        <p className="ActionCard-body">Erstelle neue Anfragenlisten oder importiere Listen aus anderen Projekten um weitere Themen in der Due Diligence abzudecken.</p>
        <div className="ActionCard-actions">
          <button className="button ActionCard-action secondary small" onClick={() => setShowNewAssessmentModal(true)}>Neue Liste</button>
        </div>
      </div>
    )
  }

  let hiddenUserCards = [];
  let hiddenCardToggle;
  if (!props.user.isAdmin) {
    hiddenCardToggle = (
      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">Es wird ernst. Bereit für rechtliche Dokumente?</h3>
        <p className="ActionCard-body">Wenn du bereit bist für die Diskussion und Verhandlung von rechtlichen Dokumente wie z.B. dem Aktienkaufvertrag (SPA), leite nun hiermit die nächsten Schritte der Due Diligence ein.</p>
        <div className="ActionCard-actions">
          <div className="toggle-switch button-sized">
            <div
              className={`toggle-option ${showHiddenCards ? 'active' : ''}`}
              onClick={() => updateHiddenCards(true)}
              >
              Bereit
            </div>
            <div
              className={`toggle-option ${showHiddenCards ? '' : 'active'}`}
              onClick={() => updateHiddenCards(false)}
              >
              Nicht bereit
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (props.user.isAdmin || showHiddenCards) {
    const scheduler = (
      <EventScheduler
        title="Terminabstimmung: Signing"
        description="Stimme einen gemeinsamen Termin zur Unterzeichnung der finalen Dokumente ab."
        meetingName="Signing"
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={d => props.patchProject(d)}
      />
    )

    const legalDocs = (
      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">Rechtliche Dokumente</h3>
        <p className="ActionCard-body">Lege bitte stets die letzte Version mit dem aktuellen Markup aller wichtigen und von deinem Gegenüber angefragten rechtlichen Dokumente hier ab. Stehen die finalen Verträge und notwendigen rechtlichen Dokumente für den Signing-Termin bereit, kannst du zudem den Fortschritt für die nächste Phase "Signing & Closing" freigeben.</p>
        <div className="ActionCard-actions">
          <a href={`/assessments/${props.project.legalDocs}`} className="button ActionCard-action secondary small">Liste öffnen</a>
        </div>
      </div>
    )

    const approval = (
      <ApprovalCard
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={e => props.patchProject(e)}
        approved={props.isSeller ? props.project.approvedBySeller : props.project.approvedByBuyer}
        customBody='Mit deiner Freigabe signalisierst du deine Bereitschaft den Aktienkaufvertrag (SPA) und die weiteren rechtlichen Dokumente zu unterzeichnen und die Transaktion zu vollziehen. Gebe daher hier den Fortschritt frei und bestätige damit, dass die Due Diligence beendet ist.'
        />
    )
    
    if (props.user.isAdmin) {
      hiddenUserCards = [legalDocs, scheduler]
    } else {
      hiddenUserCards = [scheduler, legalDocs, approval]
    }
  }

  let adminOptions = []
  if (props.user.isAdmin) {
    for (let assessment of assessments) {
      const allDone = assessment.questions.filter(q => q.completed).length === assessment.questions.length
      adminOptions.push(
        [assessment.name.replace(/Due Diligence/g, 'DD'), allDone, allDone]
      )
    }

    const signingEvents = props.project.meetingRequests.filter(mr => mr.reason === 'Signing')
    let signingEventApproved = false;
    for (let se of signingEvents) {
      if (se.approvedDate) {
        signingEventApproved = true
        break;
      }
    }
    adminOptions.push(['Signing-Termin', signingEventApproved, signingEventApproved])

    adminOptions.push(['Fortschritt', props.project.approvedBySeller, props.project.approvedByBuyer])
  }

  let newAssessmentModal;
  if (showNewAssessmentModal) {
    newAssessmentModal = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowNewAssessmentModal(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Neue Anfragenliste</h3>
              <p className="overlay-intro">
                du kannst eine neue Anfragenliste erstellen oder aus einem bestehenden Projekt importieren.
              </p>
            </div>

            <div className="overlay-body">
              <div className="form-field">
                <label>Neue Liste <span className="text-muted">(neu erstellen oder wählen)</span></label>
                <CustomCreatable
                  placeholder='Technische Due Diligence'
                  classNamePrefix="react-select"
                  options={assessmentOptions}
                  value={newAssessment}
                  onChange={v => setNewAssessment(v)}
                  />
              </div>

              <div className="overlay-actions">
                <button
                  style={{ marginTop: '12px' }}
                  className="button small primary"
                  onClick={() => createNewAssessment(false)}
                  >
                  Liste erstellen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="ProjectCards">
      <EventScheduler
        title='Due Diligence Kick-Off'
        description="Stimme bei Bedarf einen Termin zum Kick-Off der Due Diligence ab, um etwaige Fragen zu klären."
        meetingName='Due Diligence Kick-Off'
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={d => props.patchProject(d)}
      />

      {userCards}
      {hiddenCardToggle}
      {hiddenUserCards}

      <AdminActions
        user={props.user}
        project={props.project}
        patchProject={d => props.patchProject(d)}
        options={adminOptions}
      />

      {newAssessmentModal}
    </div>
  )
}