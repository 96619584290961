import { useState } from 'react';

import './style.css';

import { Check } from 'react-feather';

export default function Checkbox(props) {
  const [checked, setChecked] = useState(props.checked)

  function toggleChecked() {
    const newValue = !checked

    setChecked(newValue)
    props.toggleOption(newValue)
  }

  let conditionalInfoRow;
  if (props.info && checked) conditionalInfoRow = (
    <div className="checkbox-info-row">
      <p className="text-muted">{props.info}</p>
    </div>
  )

  let conditionalCheckmark;
  if (checked) conditionalCheckmark = <Check size={18} />

  let labelOrInput;
  if (props.editable) {
    labelOrInput = <input className="editable-input" type="text" placeholder={props.placeholder} />
  } else {
    labelOrInput = props.label
  }

  return (
    <div className={`checkbox-field ${checked ? 'checkbox-field-checked' : ''} ${props.editable ? 'checkbox-field-editable' : ''} ${props.narrow ? 'checkbox-field-narrow' : ''}`}>
      <div className="checkbox-action-row">
        <div className="checkbox-icon-column" onClick={() => toggleChecked()}>
          {conditionalCheckmark}
        </div>
        <div className="checkbox-label-column">
          {labelOrInput}
        </div>
      </div>
      {conditionalInfoRow}
    </div>
  )
}