import React, { useState, useEffect, useMemo } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import './colors.css';
import './App.css';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from './Environments';

import Login from './views/Login';
import Signup from './views/Signup';
import Dashboard from './views/Dashboard';
import SellerProfile from './views/SellerProfile';
import BuyerProfile from './views/BuyerProfile';
import SellerProfiles from './views/SellerProfiles';
import BuyerProfiles from './views/BuyerProfiles';
import Settings from './views/Settings';
import SellerSettings from './views/SellerSettings';
import BuyerSettings from './views/BuyerSettings';
import Subscriptions from './views/Subscriptions';
import Subscription from './views/Subscription';
import Search from './views/Search';
import SearchPrompt from './views/SearchPrompt';
import SellerProjects from './views/SellerProjects';
import BuyerProjects from './views/BuyerProjects';
import Project from './views/Project';
import Assessment from './views/Assessment';

import ScrollToTop from './ScrollToTop';

function App() {
  // const h = (new Date()).getHours()
  // const darkMode = (h > 21 || h < 6)
  const darkMode = false

  const [user, setUser] = useState({});

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchMe() {
      try {
        const response = await axios({
          method: 'GET',
          headers: {
            'content-type': 'application/json',
            'Authorization': cookies.get('p4itoken')
          },
          url: `${Env.url}/users/me`
        });
  
        setUser(response.data)
      } catch (e) {
        console.log('Unable to fetch logged in user')
      }
    }

    fetchMe()
  }, [cookies])

  function signInUser(user) {
    setUser(user)
    cookies.set('p4itoken', user.token, { path: '/' });
  }

  function signOutUser() {
    setUser({})
    cookies.set('p4itoken', '', { path: '/' });
  }

  async function patchUser(data) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/users/${user._id}`
    })

    setUser(response.data)
  }

  let appContent;
  if (user.token) {
    // show routes for verified users
    appContent = (
      <Switch>
        <Route path="/dashboard">
          <Dashboard
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/projects/:pid">
          <Project
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/buyer-projects">
          <BuyerProjects
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/seller-projects">
          <SellerProjects
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/search/:scope">
          <SearchPrompt
            user={user}
            darkMode={darkMode}
            patchUser={v => patchUser(v)}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/find/:scope?">
          <Search
            user={user}
            darkMode={darkMode}
            patchUser={v => patchUser(v)}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/subscriptions/:id">
          <Subscription
            user={user}
            darkMode={darkMode}
            patchUser={v => patchUser(v)}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/subscriptions">
          <Subscriptions
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/settings/sellers/:sid">
          <SellerSettings
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/settings/buyers/:bid">
          <BuyerSettings
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/settings">
          <Settings
            user={user}
            darkMode={darkMode}
            patchUser={v => patchUser(v)}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/seller-profile/:id">
          <SellerProfile
            user={user}
            darkMode={darkMode}
            patchUser={v => patchUser(v)}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/buyer-profile/:id">
          <BuyerProfile
            user={user}
            darkMode={darkMode}
            patchUser={v => patchUser(v)}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/seller-profiles">
          <SellerProfiles
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/buyer-profiles">
          <BuyerProfiles
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/assessments/:aid">
          <Assessment
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/">
          <Redirect to="/dashboard" />
        </Route>
      </Switch>
    )
  } else if (cookies.get('p4itoken')) {
    // we're waiting for the user record to be fetched
  } else {
    // we're only showing login routes
    appContent = (
      <Switch>
        <Route path="/login">
          <Login
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/signup">
          <Signup
            user={user}
            darkMode={darkMode}
            signOutUser={() => signOutUser()}
            signInUser={(u) => signInUser(u)}
            />
        </Route>

        <Route path="/">
          <Redirect to="/login" />
        </Route>
      </Switch>
    )
  }

  return (
    <div id="speer-container" className={darkMode ? 'dark' : 'light'}>
      <Router>
        <ScrollToTop>
          {appContent}
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
