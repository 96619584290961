import { useEffect, useState, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import WidgetFeed from '../../components/WidgetFeed';
import ProjectRow from '../../components/ProjectRow';

import { ArrowLeft } from 'react-feather';

export default function BuyerProjects(props) {
  const [fetchedProjects, setFetchedProjects] = useState(false)
  const [projects, setProjects] = useState([])
  const [filterQuery, setFilterQuery] = useState('')
  const [projectsLoaded, setProjectsLoaded] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchProjects() {
      let searchData;
      if (props.user.isAdmin) {
        searchData = {}
        if (filterQuery) {
          // filter buyers
          const br = await axios({
            method: 'POST',
            headers: {
              'content-type': 'application/json',
              'Authorization': cookies.get('p4itoken')
            },
            data: { name: { $regex: filterQuery } },
            url: `${Env.url}/buyers/search`
          })
          
          searchData.buyer = br.data.map(b => b._id)
        }
      } else {
        searchData = { buyer: props.user.buyers }
      }

      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: searchData,
        url: `${Env.url}/projects/search`
      })

      setProjects(response.data)
      setProjectsLoaded(true)
    }

    if (!fetchedProjects) {
      fetchProjects()
      setFetchedProjects(true)
    }
  }, [fetchedProjects, cookies, props.user, filterQuery])

  function updateFilterQuery(newValue) {
    setFilterQuery(newValue)
    setFetchedProjects(false)
    setProjects([])
  }

  let activeTableRows = [];
  let archivedTableRows = [];
  for (let p of projects) {
    const tableRow = <ProjectRow p={p} user={props.user} isSeller={false} />

    if (!p.archived) {
      activeTableRows.push(tableRow)
    } else {
      archivedTableRows.push(tableRow)
    }
  }

  let conditionalPartnerHeaders;
  if (props.user.isAdmin) {
    conditionalPartnerHeaders = [
      <th>Investor</th>,
      <th>Unternehmen</th>
    ]
  } else {
    conditionalPartnerHeaders = [
      <th>Deine Rolle</th>,
      <th>Partner</th>
    ]
  }

  let conditionalArchive;
  if (archivedTableRows.length) {
    conditionalArchive = [
      <div className="TableHeader TableHeader-top-margin">
        <h3 className="TableHeader-title">Archivierte Projekte</h3>
        <div className="TableAction">
        </div>
      </div>,
      <table className="DataTable">
        <thead>
          <tr>
            <th>Id</th>
            <th>Status</th>
            <th>Fortschritt</th>
            <th>Phase</th>
            {conditionalPartnerHeaders}
          </tr>
        </thead>
        <tbody>
          {archivedTableRows}
        </tbody>
      </table>
    ]
  }

  let conditionalDataTable;
  if (activeTableRows.length) {
    conditionalDataTable = (
      <table className="DataTable">
        <thead>
          <tr>
            <th>Id</th>
            <th>Status</th>
            <th>Fortschritt</th>
            <th>Phase</th>
            {conditionalPartnerHeaders}
          </tr>
        </thead>
        <tbody>
          {activeTableRows}
        </tbody>
      </table>
    )
  } else if (projectsLoaded) {
    conditionalDataTable = (
      <div className="placeholder-block fade-in-up">
        <div className="placeholder-inner">
          <h3 className="placeholder-title">Keine aktiven Projekte</h3>
          <p className="placeholder-body">Du bist aktuell an keinen aktiven Projekten als investierender Partner beteiligt.</p>
          <a className="button primary small" href="/search">Jetzt Unternehmen suchen</a>
        </div>
      </div>
    )
  }

  let adminFilterAction;
  if (props.user.isAdmin) {
    adminFilterAction = (
      <span className="TableAction-action">
        <input
          type="text"
          className="search"
          placeholder='Investoren filtern ...'
          value={filterQuery}
          onChange={e => updateFilterQuery(e.target.value)}
          />
      </span>
    )
  }

  return (
    <div className="BuyerProjects">
      <Aside darkMode={props.darkMode} user={props.user} scope="buyer-projects" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title"><strong>Projekte</strong> {props.user.isAdmin ? 'nach Investoren' : 'als Investor'}</h1>
              <h5 className="PanelHeader-subline">{activeTableRows.length} laufende Transaktion{activeTableRows.length === 1 ? '' : 'en'}</h5>
            </div>

            <div className="Project-header">
              <div className="Breadcrumbs">
                <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
                  <ArrowLeft size={18} color='#6B6E97' />
                </div>

                <div className="Breadcrumbs-path">
                  <a href="/">Dashboard</a>
                  <span>/</span>
                  <a href="/seller-projects" className="active">Projekte {props.user.isAdmin ? 'nach Investoren' : 'als Investor'}</a>
                </div>
              </div>

              <div className="TableAction">
                {adminFilterAction}
              </div>
            </div>

            {conditionalDataTable}
            {conditionalArchive}
          </div>

          <div className="ContentPanel-sidebar">
            <WidgetFeed scope="buyer-projects" user={props.user} projects={projects} />
          </div>
        </div>
      </Aside>
    </div>
  )
}