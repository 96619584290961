const Formatter = {
  currency: (amount, currency) => {
    const absAmount = Math.abs(amount)

    if (absAmount >= 1000000) return `${currency} ${amount/1000000}M`
    if (absAmount >= 1000) return `${currency} ${amount/1000}K`
    return `${currency} ${amount}`
  }
}

export default Formatter;
