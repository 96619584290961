import { useEffect, useState, useMemo, useRef } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import FileCard from '../../../components/FileCard';
import QuestionForm from './QuestionForm';

import Env from '../../../Environments';

import { ChevronUp, Trash, X } from 'react-feather';

export default function FileQuestion(props) {
  const [fileComment, setFileComment] = useState(props.question.fileComment)
  const [fetchedAsset, setFetchedAsset] = useState(false)
  const [menuTarget, setMenuTarget] = useState()
  const [asset, setAsset] = useState()
  const [showBody, setShowBody] = useState(false)
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)
  const [showQuestionForm, setShowQuestionForm] = useState(false)

  const fileUpload = useRef()

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchAsset() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assets/${props.question.fileAnswer}`
      })

      setAsset(response.data)
    }

    // fetch asset
    if (props.question && props.question.fileAnswer && !fetchedAsset) {
      fetchAsset()
      setFetchedAsset(true)
    }
  }, [props.question, fetchedAsset, cookies])

  async function deleteFile(a) {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/assets/${asset._id}`
    })

    await props.updateQuestion({
      question: props.question.question,
      description: props.question.description,
      questionType: props.question.questionType,
      priority: props.question.priority,
      fileComment: props.question.fileComment,
      fileAnswer: undefined
    })

    setAsset()
  }

  async function handleFileUpload(e) {
    const fileList = Array.from(e.target.files)

    const data = new FormData()
    data.append('vfile', fileList[0])
    data.append('path', `projects/${props.project._id}/${props.phaseNames[props.project.step]}`)
    data.append('owner', props.user._id)
    data.append('project', props.project._id)

    if (asset && asset._id) {
      // delete existing file
      await axios({
        method: 'DELETE',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assets/${asset._id}`
      })
    }

    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/assets`
    })

    let newQuestion = Object.assign(props.question)
    newQuestion.fileAnswer = response.data._id

    await props.updateQuestion(newQuestion)
    setAsset(response.data)
  }

  async function patchFile(a, newData) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: newData,
      url: `${Env.url}/assets/${a._id}`
    })

    setAsset(response.data)
  }

  async function toggleCompletion() {
    let newQuestion = Object.assign(props.question)
    newQuestion.completed = !props.question.completed

    await props.updateQuestion(newQuestion)
  }

  async function deleteQuestion() {
    await props.deleteQuestion()
    setShowDeletionConfirmation(false)
  }

  async function updateFileComment(newValue) {
    setFileComment(newValue)

    let newQuestion = Object.assign(props.question)
    newQuestion.fileComment = fileComment

    await props.updateQuestion(newQuestion)
  }

  let fileCard;
  if (asset) {
    let additionalUsers = [
      { _id: props.project.buyer.user },
      { _id: props.project.seller.user }
    ]

    fileCard = (
      <FileCard
        className="margin-bottom"
        user={props.user}
        additionalUsers={additionalUsers}
        asset={asset}
        menuTarget={menuTarget}
        setMenuTarget={setMenuTarget}
        deleteFile={() => deleteFile(asset)}
        patchFile={newData => patchFile(asset, newData)}
        />
    )
  }

  let completeCTA;
  if (!props.question.completed && !props.isSeller) {
    completeCTA = (
      <button className={`button small ${props.isAdmin ? 'link' : 'secondary'}`} onClick={() => toggleCompletion()}>
        Anfrage abschließen
      </button>
    )
  }

  let sellerActions = []
  if (!props.isBuyer) {
    sellerActions = [
      <button className="button secondary small" onClick={() => fileUpload.current.click()}>Datei hochladen</button>,
      <input type="file" ref={fileUpload} className="hidden" onChange={e => handleFileUpload(e)} />
    ]
  }

  let adminActions;
  let buyerActions = []
  if (!props.isSeller) {
    adminActions = [
      <button className="button danger circle small" onClick={() => setShowDeletionConfirmation(!showDeletionConfirmation)}><Trash size={12} /></button>
    ]

    if (!props.subQuestion) {
      buyerActions = [
        <button className="button link small" onClick={() => setShowQuestionForm(true)}>Nachfrage stellen</button>
      ]
    }
  }

  let conditionalInfoBody;
  let conditionalContentBody;
  if (showBody) {
    conditionalInfoBody = (
      <div className="AssessmentItem-info-body">
        <p className="AssessmentItem-intro">{props.question.description}</p>
        <div className="AssessmentItem-actions">
          {sellerActions}
          {completeCTA}
          {buyerActions}
        </div>
      </div>
    )

    let textAreaOrBlock;
    if (props.isBuyer) {
      textAreaOrBlock = (
        <div className="form-field no-margin">
          <label>Kommentar</label>
          <p className="text-muted">{fileComment}</p>
        </div>
      )
    } else {
      textAreaOrBlock = (
        <div className="form-field">
          <label>Kommentar (optional)</label>
          <textarea value={fileComment} onChange={e => updateFileComment(e.target.value)} />
        </div>
      )
    }

    conditionalContentBody = (
      <div className="AssessmentItem-content-body">
        {fileCard}

        {textAreaOrBlock}
      </div>
    )
  }

  let deletionConfirmation;
  if (showDeletionConfirmation) {
    deletionConfirmation = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowDeletionConfirmation(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Bist du sicher?</h3>
              <p className="overlay-intro">
                Wenn du die Anfrage löschst, wird sie für alle Parteien dieser Transaktion entfernt. Diese Aktion kann nicht rückgängig gemacht werden.
              </p>
            </div>

            <div className="overlay-body">
              <div className="overlay-actions">
                <button style={{ marginTop: '12px' }} className="button small danger" onClick={e => deleteQuestion()}>
                  Löschen
                </button>

                <button style={{ marginTop: '12px' }} className="button small secondary" onClick={e => setShowDeletionConfirmation(false)}>
                  Abbrechen
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  async function submitNewQuestion(questionData) {
    let newQuestion = questionData
    let newQuestions = []

    for (let q of props.assessment.questions) {
      if (q === props.question) {
        let parentQuestion = Object.assign(q)
        parentQuestion.questions = [...parentQuestion.questions, newQuestion]
        newQuestions.push(parentQuestion)
      } else {
        newQuestions.push(q)
      }
    }

    await props.patchAssessment({ questions: newQuestions })

    setShowQuestionForm(false)
  }

  let conditionalQuestionForm;
  if (showQuestionForm) {
    conditionalQuestionForm = (
      <QuestionForm
        setShowQuestionOverlay={() => setShowQuestionForm(false)}
        submitNewQuestion={questionData => submitNewQuestion(questionData)}
        />
    )
  }

  return (
    <div className="AssessmentItem">
      <div className="AssessmentItem-info">
        <div className="AssessmentItem-info-header">
          <h4 className="AssessmentItem-title">
            <span className="AssessmentItem-number">{props.idx}</span> {props.question.question}
          </h4>
          <div className="AssessmentItem-header-actions">
            {adminActions}
            <ChevronUp color="#959ABA" onClick={() => setShowBody(!showBody)} className={showBody ? 'AssessmentItem--header-action' : 'AssessmentItem--header-action inverted'} />
          </div>
        </div>
        {conditionalInfoBody}
      </div>
      <div className="AssessmentItem-content">
        <div className="AssessmentItem-content-header">
          <span className="AssessmentItem-subtitle">{asset ? '' : 'Keine'} Datei {fileComment ? '(Kommentar vorhanden)' : ''}</span>
          <span className={`badge ${props.question.completed ? 'badge-success' : ''}`}>
            {props.question.completed ? 'Erledigt' : ((asset || fileComment)? 'In Review' : 'Todo')}
          </span>
        </div>
        {conditionalContentBody}
      </div>

      {conditionalQuestionForm}
      {deletionConfirmation}
    </div>
  )
}