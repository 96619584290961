import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../Environments';

import Aside from '../../components/Aside';
import CustomSelectable from '../../components/CustomSelectable';
import WidgetFeed from '../../components/WidgetFeed';
import Makro from '../../components/Makro';

import Stage1 from './components/Stage1';
import Stage2 from './components/Stage2';
import Stage3 from './components/Stage3';
import Stage4 from './components/Stage4';
import Conclusion from './components/Conclusion';

import TransactionLog from './components/TransactionLog';
import Archive from './components/Archive';
import Partners from './components/Partners';

import { ArrowLeft, ChevronDown, X } from 'react-feather';

import './style.css';

export default function Project(props) {
  const [fetchedProject, setFetchedProject] = useState(false)
  const [project, setProject] = useState({})
  const [isBuyer, setIsBuyer] = useState(false)
  const [isSeller, setIsSeller] = useState(false)
  const [isAdmin, setIsAdmin] = useState(false)
  const [showProjectAction, setShowProjectAction] = useState(false)
  const [activeScope, setActiveScope] = useState('Übersicht')
  const [showPartnerOverlay, setShowPartnerOverlay] = useState(false)
  const [showCancelOverlay, setShowCancelOverlay] = useState(false)
  
  const [partnerFirstName, setPartnerFirstName] = useState('')
  const [partnerLastName, setPartnerLastName] = useState('')
  const [partnerEmail, setPartnerEmail] = useState('')
  const [partnerPhone, setPartnerPhone] = useState('')
  const [partnerRole, setPartnerRole] = useState('')
  const [partnerMenuTarget, setPartnerMenuTarget] = useState()

  const cookies = useMemo(() => new Cookies(), [])

  const { pid } = useParams()

  const phaseNames = [
    'Unverbindlicher Austausch',
    'Informationsaustausch',
    'Due Diligence',
    'Signing & Closing'
  ]

  const partnerRoleOptions = [
    { label: 'Interessent', value: 'Interessent' },
    { label: 'Gesellschafter', value: 'Gesellschafter' },
    { label: 'Steuer- und Finanzberater', value: 'Steuer- und Finanzberater' },
    { label: 'Unternehmensberater', value: 'Unternehmensberater' },
    { label: 'Rechtsberater/Anwalt', value: 'Rechtsberater/Anwalt' },
    { label: 'M&A Berater', value: 'M&A Berater' },
    { label: 'Andere Beratung', value: 'Andere Beratung' }
  ]

  useEffect(() => {
    async function fetchProject() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/projects/${pid}`
      })

      const buyerIds = props.user.buyers.map(b => b._id)
      const sellerIds = props.user.sellers.map(s => s._id)

      if (buyerIds.indexOf(response.data.buyer._id.toString()) !== -1) setIsBuyer(true)
      if (sellerIds.indexOf(response.data.seller._id.toString()) !== -1) setIsSeller(true)
      if (props.user.isAdmin) setIsAdmin(true)

      setProject(response.data)
    }

    if (!fetchedProject) {
      fetchProject()
      setFetchedProject(true)
    }
  }, [fetchedProject, cookies, pid, props.user])

  function updateActiveScope(newScope) {
    setShowProjectAction(false)
    setActiveScope(newScope)
  }

  async function patchProject(patchedProject) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: patchedProject,
      url: `${Env.url}/projects/${pid}`
    })

    setProject(response.data)
  }

  async function submitPartnerInvite(evt) {
    evt.preventDefault()

    let inviteAuthor;
    if (isBuyer) inviteAuthor = project.buyer.name
    if (isSeller) inviteAuthor = project.seller.name
    if (isAdmin) inviteAuthor = 'P4i'

    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: {
        firstName: partnerFirstName,
        lastName: partnerLastName,
        email: partnerEmail,
        phone: partnerPhone,
        focus: partnerRole.value,
        inviteBy: inviteAuthor,
        inviteUser: props.user._id,
        project: { _id: project._id }
      },
      url: `${Env.url}/invites`
    })

    setFetchedProject(false)
    setShowPartnerOverlay(false)
    setPartnerFirstName('')
    setPartnerLastName('')
    setPartnerEmail('')
    setPartnerPhone('')
    setPartnerRole('')
  }

  async function deleteInvite(invite) {
    setPartnerMenuTarget()

    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/invites/${invite._id}`
    })

    setFetchedProject(false)
  }

  function resetScopeOrGoBack() {
    if (activeScope !== 'Übersicht') {
      updateActiveScope('Übersicht')
    } else {
      window.history.back()
    }
  }

  function cancelProject() {
    patchProject({ archived: true })
    setShowCancelOverlay(false)
  }

  let breadcrumbs;
  if (isAdmin) {
    breadcrumbs = (
      <div className="Breadcrumbs">
        <div className="Breadcrumbs-back" onClick={() => resetScopeOrGoBack()}>
          <ArrowLeft size={18} color='#6B6E97' />
        </div>

        <div className="Breadcrumbs-path">
          <a href="/">Dashboard</a>
          <span>/</span>
          <a href={`/projects/${pid}`} className="active">Projekt {project.name}</a>
        </div>
      </div>
    )
  } else if (isSeller) {
    breadcrumbs = (
      <div className="Breadcrumbs">
        <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
          <ArrowLeft size={18} color='#6B6E97' />
        </div>

        <div className="Breadcrumbs-path">
          <a href="/">Dashboard</a>
          <span>/</span>
          <a href="/seller-projects">Projekte als Firma</a>
          <span>/</span>
          <a href={`/projects/${pid}`} className="active">Projekt {project.name}</a>
        </div>
      </div>
    )
  } else if (isBuyer) {
    breadcrumbs = (
      <div className="Breadcrumbs">
        <div className="Breadcrumbs-back" onClick={() => window.history.back()}>
          <ArrowLeft size={18} color='#6B6E97' />
        </div>

        <div className="Breadcrumbs-path">
          <a href="/">Dashboard</a>
          <span>/</span>
          <a href="/buyer-projects">Projekte als Investor</a>
          <span>/</span>
          <a href={`/projects/${pid}`} className="active">Projekt {project.name}</a>
        </div>
      </div>
    )
  }

  let activeStage;
  if (project.archived) {
    activeStage = <Conclusion user={props.user} project={project} phaseNames={phaseNames} />
  } else {
    switch (project.step) {
      case 0:
        activeStage = (
          <Stage1
            user={props.user}
            project={project}
            phaseNames={phaseNames}
            isSeller={isSeller}
            isAdmin={isAdmin}
            patchProject={p => patchProject(p)}
            />
        )
        break;
      case 1:
        activeStage = (
          <Stage2
            user={props.user}
            project={project}
            phaseNames={phaseNames}
            isSeller={isSeller}
            isAdmin={isAdmin}
            patchProject={p => patchProject(p)}
            />
        )
        break;
      case 2:
        activeStage = (
          <Stage3
            user={props.user}
            project={project}
            phaseNames={phaseNames}
            isSeller={isSeller}
            isAdmin={isSeller}
            patchProject={p => patchProject(p)}
            />
        )
        break;
      case 3:
        activeStage = (
          <Stage4
            user={props.user}
            project={project}
            phaseNames={phaseNames}
            isSeller={isSeller}
            isAdmin={isSeller}
            patchProject={p => patchProject(p)}
            />
        )
        break;
      default:
        break;
    }
  }

  let activeContext;
  switch (activeScope) {
    case 'Übersicht':
      activeContext = activeStage
      break;
    case 'Transaction-Log':
      activeContext = <TransactionLog user={props.user} project={project} />
      break;
    case 'Archiv':
      activeContext = <Archive user={props.user} project={project} />
      break;
    case 'Partner':
      activeContext = (
        <Partners
          user={props.user}
          project={project}
          menuTarget={partnerMenuTarget}
          setMenuTarget={v => setPartnerMenuTarget(v)}
          deleteInvite={v => deleteInvite(v)}
          />
      )
      break;
    default:
      activeContext = activeStage
      break;
  }

  let partnerWidget;
  let partnerOverlay;
  if (activeScope === 'Partner') {
    if (showPartnerOverlay) {
      partnerOverlay = (
        <div className="overlay-container">
          <div className="overlay">
            <div className="overlay-inner">
              <X className="overlay-dismiss" onClick={e => setShowPartnerOverlay(false)} />
              <div className="overlay-header">
                <h3 className="overlay-title">Partner einladen</h3>
                <p className="overlay-intro">
                  Laden weitere Personen zu diesem Projekt ein und lege deren Berechtigungen fest.
                </p>
              </div>

              <form className="overlay-body" onSubmit={e => submitPartnerInvite(e)}>
                <div className="Settings-fields Settings-fields-c2">
                  <div className="form-field">
                    <label>Vorname</label>
                    <input type="text" value={partnerFirstName} onChange={e => setPartnerFirstName(e.target.value)} />
                  </div>

                  <div className="form-field">
                    <label>Nachname</label>
                    <input type="text" value={partnerLastName} onChange={e => setPartnerLastName(e.target.value)} />
                  </div>
                </div>

                <div className="form-field">
                  <label>E-Mail</label>
                  <input type="email" value={partnerEmail} onChange={e => setPartnerEmail(e.target.value)} />
                </div>

                <div className="form-field">
                  <label>Telefon</label>
                  <input type="text" value={partnerPhone} onChange={e => setPartnerPhone(e.target.value)} />
                </div>

                <div className="form-field">
                  <label>Berechtigung</label>
                  <CustomSelectable
                    isMulti
                    placeholder=''
                    classNamePrefix="react-select"
                    options={partnerRoleOptions}
                    value={partnerRole}
                    onChange={v => setPartnerRole(v)}
                    />
                </div>

                <button type="submit"
                  style={{ marginTop: '12px' }}
                  className="button primary full-width"
                  >
                  Einladung senden
                </button>
              </form>
            </div>
          </div>
        </div>
      )
    }

    partnerWidget = (
      <Makro
        iconAction
        label="Weitere Partner einladen"
        targetAction={() => setShowPartnerOverlay(true)}
      />
    )
  }

  let cancelOverlay;
  if (showCancelOverlay) {
    cancelOverlay = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowCancelOverlay(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Bist du <strong>sicher</strong>?</h3>
              <p className="overlay-intro">
                Wenn du diese Transaktion abbrichst, werden alle Verhandlungen mit diesem Partner eingestellt und das Projekt wird beendet.
              </p>
              <br />
              <p className="overlay-intro">
                Die Verhandlungen können <i>nicht</i> wieder aufgenommen werden. Möchtest du trotzdem fortfahren?
              </p>
            </div>

            <div className="overlay-body">
              <button className="button primary ghost small full-width" onClick={e => setShowCancelOverlay(false)}>
                Nein, Projekt fortführen
              </button>
              <button className="button danger small full-width margin-top" onClick={() => cancelProject()}>
                Ja, Projekt abbrechen
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  
  let conditionalSidebar;
  if (!project.archived) {
    conditionalSidebar = (
      <div className="ContentPanel-sidebar">
        <WidgetFeed
          scope="project"
          project={project}
          user={props.user}
          isSeller={isSeller}
          isAdmin={isAdmin}
          patchProject={p => patchProject(p)}
          updateActiveScope={updateActiveScope}
          >
            {partnerWidget}
            
            <Makro
              iconAction
              makroStyle="danger"
              label="Transaktion abbrechen"
              targetAction={() => setShowCancelOverlay(true)}
            />
        </WidgetFeed>
      </div>
    )
  }

  return (
    <div className="Project">
      <Aside darkMode={props.darkMode} user={props.user} scope={isSeller ? 'seller-projects' : 'buyer-projects'} signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title"><strong>Projekt</strong> {project.name}</h1>
              <h5 className="PanelHeader-subline"><strong>Aktuelle Phase:</strong> {phaseNames[project.step]}</h5>
            </div>

            <div className="Project-header">
              {breadcrumbs}

              <div className={`Dropdown ${showProjectAction ? 'active' : ''}`}>
                <button className="Dropdown-trigger" onClick={() => setShowProjectAction(!showProjectAction)}>
                  <span className="Dropdown-trigger-label">
                    {activeScope}
                  </span>
                  <ChevronDown size={18} />
                </button>
                <div className={`Dropdown-menu ${showProjectAction ? '' : 'hidden'}`}>
                  <div
                    className={`Dropdown-menu-item ${activeScope === 'Übersicht' ? 'active' : ''}`}
                    onClick={() => updateActiveScope('Übersicht')}
                    >
                    Übersicht
                  </div>
                  <div
                    className={`Dropdown-menu-item ${activeScope === 'Transaction-Log' ? 'active' : ''}`}
                    onClick={() => updateActiveScope('Transaction-Log')}
                    >
                    Transaction-Log
                  </div>
                  <div
                    className={`Dropdown-menu-item ${activeScope === 'Archiv' ? 'active' : ''}`}
                    onClick={() => updateActiveScope('Archiv')}
                    >
                    Archiv
                  </div>
                  <div
                    className={`Dropdown-menu-item ${activeScope === 'Partner' ? 'active' : ''}`}
                    onClick={() => updateActiveScope('Partner')}
                    >
                    Partner
                  </div>
                </div>
              </div>
            </div>

            {activeContext}
          </div>

          {conditionalSidebar}
        </div>

        {partnerOverlay}
        {cancelOverlay}
      </Aside>
    </div>
  )
}