import React, { useState, useEffect, useCallback } from 'react';

import Accordion from '../../../components/Accordion';
import CustomCreatable from '../../../components/CustomCreatable';
import CustomSelectable from '../../../components/CustomSelectable';

import optionService from '../../../services/OptionService';

export default function QuickFacts(props) {
  const [score, setScore] = useState(0)
  const [tags, setTags] = useState([])
  const [sectors, setSectors] = useState([])
  const [locations, setLocations] = useState([])
  const [regions, setRegions] = useState([])
  const [foundingYear, setFoundingYear] = useState()
  const [employees, setEmployees] = useState()
  const [intentions, setIntentions] = useState()
  const [sectorComment, setSectorComment] = useState()
  const [propsInit, setPropsInit] = useState(false)

  const sectorOptions = optionService.sectors
  const countryOptions = optionService.countries
  const regionOptions = optionService.regions

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [tags, sectors, locations, regions, foundingYear, employees, intentions]
    properties.forEach(p => {
      if (p && p !== '') newScore += 100/properties.length
    })

    setScore(Math.round(newScore))
  }, [tags, sectors, locations, regions, foundingYear, employees, intentions])

  useEffect(() => {
    if (props.seller._id && !propsInit) {
      setTags(props.seller.tags.map(t => ({ label: t, value: t })))
      setSectors(props.seller.sectors.map(t => ({ label: t, value: t })))
      setLocations(props.seller.locations.map(t => ({ label: t, value: t })))
      setRegions(props.seller.regions.map(t => ({ label: t, value: t })))
      setFoundingYear(props.seller.foundingYear)
      setEmployees(props.seller.employees)
      setIntentions(props.seller.intentions)
      setSectorComment(props.seller.sectorComment)

      setPropsInit(true)
    }

    updateScore()
  }, [props.seller, updateScore, tags.length, propsInit])

  function universalSetter(property, setter, newValue) {
    let updatePayload = {}
    updatePayload[property] = newValue

    props.patchSeller(updatePayload)

    updateScore()

    setter(newValue)
  }

  function universalSelectSetter(property, setter, newValue) {
    let updatePayload = {}
    updatePayload[property] = newValue.map(v => v.value)

    props.patchSeller(updatePayload)

    updateScore()

    setter(newValue)
  }

  return (
    <Accordion
      title="Quickfacts"
      toggleState={props.toggleState}
      body="Bitte gib wichtige Informationen zu deinem Unternehmen an. Diese sensiblen Daten werden von uns absolut diskret behandelt."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
    >
      <div className="Settings-fields Settings-fields-c4">
        <div className="form-field">
          <label>Gründungsjahr</label>
          <input
            type="number"
            value={foundingYear}
            onChange={e => universalSetter('foundingYear', setFoundingYear, e.target.value)}
            />
        </div>

        <div className="form-field">
          <label>Mitarbeiter</label>
          <input
            type="number"
            value={employees}
            onChange={e => universalSetter('employees', setEmployees, e.target.value)}
            />
        </div>

        <div className="form-field form-field-second-half">
          <label>Grund für potenzielle Gespräche mit Investoren</label>
          <input
            type="text"
            value={intentions}
            onChange={e => universalSetter('intentions', setIntentions, e.target.value)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c2">
        <div className="form-field">
          <label>Branchen <span className="text-muted">(eigene Definition möglich)</span></label>
          <CustomCreatable
            isMulti
            placeholder={`"${sectorOptions[0]}"`}
            classNamePrefix="react-select"
            options={sectorOptions.map(o => ({ label: o, value: o }))}
            value={sectors}
            onChange={v => universalSelectSetter('sectors', setSectors, v)}
            />
        </div>

        <div className="form-field">
          <label>Kommentar zu Branchen <span className="text-muted">- optional</span></label>
          <input
            type="text"
            value={sectorComment}
            onChange={e => universalSetter('sectorComment', setSectorComment, e.target.value)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c2">
        <div className="form-field">
          <label>Standorte</label>
          <CustomSelectable
            isMulti
            placeholder='Bitte wählen ...'
            classNamePrefix="react-select"
            options={countryOptions.map(o => ({ label: o, value: o }))}
            value={locations}
            onChange={v => universalSelectSetter('locations', setLocations, v)}
            />
        </div>

        <div className="form-field">
          <label>Regionen <span className="text-muted">- optional</span></label>
          <CustomSelectable
            isMulti
            placeholder='Bitte wählen ...'
            classNamePrefix="react-select"
            options={locations.map(c => ({
              label: c.label,
              options: c.label ? regionOptions[c.label].map(e => ({ label: e, value: e })) : []
            }))}
            value={regions}
            onChange={v => universalSelectSetter('regions', setRegions, v)}
            />
        </div>
      </div>

      <div className="Settings-fields Settings-fields-c1">
        <div className="form-field">
          <label>Tags</label>
          <CustomCreatable
            isMulti
            placeholder='"IT Security"'
            classNamePrefix="react-select"
            noOptionsMessage={() => 'Definiere eigene Tags und bestätige diese mit Enter'}
            value={tags}
            onChange={v => universalSelectSetter('tags', setTags, v)}
            />
          <small className="form-field-info">
            Tags helfen der Speer Technologie, neue und passende Opportunitäten anhand von inhaltlichen Analysen für dich zu identifizieren. Bitte gib hier individuelle "Tags" an, unter welchen du in der Hub-Suche von Unternehmern und Investoren gefunden werden willst.
          </small>
        </div>
      </div>
    </Accordion>
  )
}