import ReactCountryFlag from 'react-country-flag';

import Tooltip from '../../components/Tooltip';

import optionService from '../../services/OptionService';

export default function ProjectRow(props) {
  const countryOptions = optionService.countryCodes

  const phaseNames = [
    'Unverbindlicher Austausch',
    'Informationsaustausch',
    'Due Diligence',
    'Signing & Closing'
  ]

  function trimValue(v, threshold) {
    if (v.length > threshold) {
      return (
        <Tooltip text={v}>
          {v.slice(0, threshold) + '...'}
        </Tooltip>
      )
    } else {
      return v
    }
  }

  let statusBadge;
  if (props.p.archived) {
    statusBadge = <span className="badge">Archiviert</span>
  } else {
    statusBadge = <span className="badge badge-success badge-bullet">Live</span>
  }

  let partnerColumns;
  if (props.user.isAdmin) {
    partnerColumns = [
      <td style={{ minWidth: '150px' }}><a href={`/buyer-profile/${props.p.buyer._id}`}>{trimValue(props.p.buyer.name, 15)}</a></td>,
      <td style={{ minWidth: '150px' }} className="flex-cell">
        <ReactCountryFlag
          countryCode={countryOptions[props.p.seller.country]}
          svg
          cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
          cdnSuffix="svg"
          title={countryOptions[props.p.seller.country]}
          style={{ width: '1em', height: '1em', }}
          />
        <a href={`/seller-profile/${props.p.seller._id}`}>{trimValue(props.p.seller.name, 15)}</a>
      </td>
    ]
  } else {
    if (props.isSeller) {
      partnerColumns = [
        <td style={{ minWidth: '150px' }}><a href={`/seller-profile/${props.p.seller._id}`}>{trimValue(props.p.seller.name, 15)}</a></td>,
        <td style={{ minWidth: '150px' }} className={props.p.buyer.blurred ? 'flex-cell blurred' : 'flex-cell'}>
          <ReactCountryFlag
            countryCode={countryOptions[props.p.buyer.country]}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={countryOptions[props.p.buyer.country]}
            style={{ width: '1em', height: '1em', }}
            />
          <a href={`/buyer-profile/${props.p.buyer._id}`}>{trimValue(props.p.buyer.name, 15)}</a>
        </td>
      ]
    } else {
      partnerColumns = [
        <td style={{ minWidth: '150px' }}><a href={`/buyer-profile/${props.p.buyer._id}`}>{trimValue(props.p.buyer.name, 15)}</a></td>,
        <td style={{ minWidth: '150px' }} className={props.p.seller.blurred ? 'flex-cell blurred' : 'flex-cell'}>
          <ReactCountryFlag
            countryCode={countryOptions[props.p.seller.country]}
            svg
            cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
            cdnSuffix="svg"
            title={countryOptions[props.p.seller.country]}
            style={{ width: '1em', height: '1em', }}
            />
          <a href={`/seller-profile/${props.p.seller._id}`}>{trimValue(props.p.seller.name, 15)}</a>
        </td>
      ]
    }
  }

  let phaseName = phaseNames[props.p.step]
  if (phaseName.length > 20) {
    phaseName = (
      <Tooltip text={phaseNames[props.p.step]}>
        {phaseName.slice(0, 15) + '...'}
      </Tooltip>
    )
  }

  return (
    <tr>
      <td style={{ minWidth: '100px' }}><a href={`/projects/${props.p._id}`}>{trimValue(props.p.name, 15)}</a></td>
      <td style={{ minWidth: '80px' }}>{statusBadge}</td>
      <td style={{ minWidth: '80px' }}>{Math.round((props.p.step/phaseNames.length)*100)}%</td>
      <td style={{ minWidth: '150px' }}>{trimValue(phaseNames[props.p.step], 15)}</td>
      {partnerColumns}
    </tr>
  )
}