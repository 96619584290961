import CreatableSelect from 'react-select/creatable';

export default function CustomCreatable(props) {
  const customSelectStyles = {
    menuPortal: (provided, state) => ({
      ...provided,
      zIndex: '999'
    }),
    container: (provided, state) => ({
      ...provided,
      width: '100%',
      border: '1px solid #F1F1F3',
      borderColor: '#F1F1F3',
      borderRadius: '6px',
      boxShadow: 'none'
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      borderRadius: '6px',
      borderColor: '#F1F1F3',
      boxShadow: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '6px',
      borderColor: '#F1F1F3',
      boxShadow: 'none'
    }),
    indicatorsContainer: (provided, state) => {
      return {
        ...provided,
        display: 'none'
      }
    },
    multiValue: (provided, state) => ({
      ...provided,
      background: '#6877B2',
      borderRadius: '6px',
      padding: '6px 12px',
      marginRight: '6px'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      background: '#FDFDFF',
      color: '#203052',
      borderRadius: '6px',
      padding: '6px 12px',
      marginRight: '6px',
      fontWeight: 'normal'
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px',
      color: '#FDFDFF'
    }),
    multiValueRemove: (provided, state) => ({
      ...provided,
      background: 'none'
    })
  }

  return (
    <CreatableSelect
      isMulti={props.isMulti}
      placeholder={`z.B ${props.placeholder}`}
      classNamePrefix={props.classNamePrefix}
      noOptionsMessage={() => props.noOptionsMessage || "Neuen Eintrag erstellen"}
      options={props.options}
      styles={customSelectStyles}
      value={props.value}
      onChange={props.onChange}
      formatCreateLabel={v => `"${v}" erstellen`}
      />
  )
}