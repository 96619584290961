import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import Accordion from '../../../components/Accordion';
import FileCard from '../../../components/FileCard';
import NumberInput from '../../../components/NumberInput';

import axios from 'axios';
import Cookies from 'universal-cookie'

import Env from '../../../Environments';

export default function InvestmentPreferences(props) {
  const [score, setScore] = useState(0)
  const [initializeValues, setInitializeValues] = useState(false)

  const [successRate, setSuccessRate] = useState()
  const [minAmount, setMinAmount] = useState()
  const [maxAmount, setMaxAmount] = useState()

  const [customTermsAsset, setCustomTermsAsset] = useState()
  const [menuTarget, setMenuTarget] = useState()

  const cookies = useMemo(() => new Cookies(), [])

  const fileUpload = useRef();

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [successRate, minAmount, maxAmount]
    properties.forEach(p => {
      if (p && p !== '') newScore += 100/properties.length
    })

    setScore(Math.round(newScore))
  }, [successRate, minAmount, maxAmount])

  useEffect(() => {
    async function fetchCustomTerms() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assets/${props.buyer.customTerms}`
      })

      setCustomTermsAsset(response.data)
    }

    if (props.buyer._id && !initializeValues) {
      setSuccessRate(props.buyer.successRate)
      setMinAmount(props.buyer.minAmount)
      setMaxAmount(props.buyer.maxAmount)

      if (props.buyer.customTerms) fetchCustomTerms()

      setInitializeValues(true)
    }

    updateScore()
  }, [props.buyer, updateScore, initializeValues, cookies])

  function universalSetter(property, setter, newValue) {
    let updatePayload = {}
    updatePayload[property] = newValue

    props.patchBuyer(updatePayload)

    updateScore()

    setter(newValue)
  }

  async function handleFileUpload(e) {
    const fileList = Array.from(e.target.files)

    const data = new FormData()
    data.append('vfile', fileList[0])
    data.append('path', `users/${props.buyer.user._id}/${props.buyer._id}/customTerms`)
    data.append('owner', props.buyer.user._id)

    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/assets`
    })

    setCustomTermsAsset(response.data)

    // update buyer accordingly
    await props.patchBuyer({ customTerms: response.data._id })
  }

  async function patchFile(a, newData) {
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: newData,
      url: `${Env.url}/assets/${a._id}`
    })

    setCustomTermsAsset()
    setInitializeValues(false)
  }

  async function deleteFile(a) {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/assets/${a._id}`
    })

    setCustomTermsAsset()
    setInitializeValues(false)

    // update buyer accordingly
    await props.patchBuyer({ customTerms: undefined })
  }

  let customTerms;
  if (customTermsAsset) {
    customTerms = (
      <div className="settings-subsection">
        <div className="Settings-fields Settings-fields-c2">
          <FileCard
            user={props.buyer.user}
            asset={customTermsAsset}
            menuTarget={menuTarget}
            setMenuTarget={setMenuTarget}
            patchFile={newData => patchFile(customTermsAsset, newData)}
            deleteFile={() => deleteFile(customTermsAsset)}
            />
        </div>
      </div>
    )
  } else if (props.user.isAdmin) {
    customTerms = (
      <div className="settings-subsection">
        <small className="text-muted">Du kannst für diesen Account auch ein <span className="link-style" onClick={() => fileUpload.current.click()}>individuelles Agreement hochladen</span>.</small>
        <input type="file" ref={fileUpload} className="invisible" onChange={e => handleFileUpload(e)} />
      </div>
    )
  }

  return (
    <Accordion
      title="Speer Agreement"
      toggleState={props.toggleState}
      body="Hier findest du alle Informationen hinsichtlich deiner individuellen Fees zur Nutzung des Speers. Deine Einstellungen können nur durch Speer Administratoren angepasst werden."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
      >
      <div className="Settings-fields Settings-fields-c3">
        <div className="form-field">
          <label>Erfolgshonorar (vom Transaktionswert)</label>
          <NumberInput
            restrictAccess={!!props.user.isAdmin}
            suffix={'%'}
            value={successRate}
            onChange={e => universalSetter('successRate', setSuccessRate, e)}
            />
        </div>
        <div className="form-field">
          <label>Mindestbetrag</label>
          <NumberInput
            restrictAccess={!!props.user.isAdmin}
            suffix={props.buyer.currency}
            value={minAmount}
            onChange={e => universalSetter('minAmount', setMinAmount, e)}
            />
        </div>
        <div className="form-field">
          <label>Höchstbetrag</label>
          <NumberInput
            restrictAccess={!!props.user.isAdmin}
            suffix={props.buyer.currency}
            value={maxAmount}
            onChange={e => universalSetter('maxAmount', setMaxAmount, e)}
            />
        </div>
      </div>

      <div className="text-muted">
        <small>Der Transaktionswert  umfasst  den  zwischen  dem  Investitionspartner  und  dem Zielunternehmen  vereinbarten  Transaktionspreis  inklusive variablen Kaufpreiskomponenten und  exklusive  der  anfallenden  gesetzlichen  Umsatzsteuer.  Der Transaktionswert  erhöht  sich  um  die  Summe  aller  durch  den  Investitionspartner  im Zusammenhang  mit  der  M&A  Transaktion  erbrachten  oder  zugesagten  Zahlungen oder geldwerten Leistungen, einschliesslich der übernommenen Nettofinanzverbindlichkeiten.  Der Anspruch des Speer auf Zahlung der Erfolgsprovision entsteht mit dem Abschluss des schuldrechtlichen Vertrages oder des massgeblichen gesellschaftsrechtlichen Geschäfts zu einer M&A Transaktion.</small>
      </div>

      {customTerms}
    </Accordion>
  )
}