import { useEffect, useState, useMemo } from 'react';

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../../Environments';

import AdminActions from './AdminActions';
import ApprovalCard from './ApprovalCard';

import EventScheduler from '../../../components/EventScheduler';

export default function Stage4(props) {
  const [transactionProofCompleted, setTransactionProofCompleted] = useState(false)
  const [fetchedTransactionProof, setFetchedTransactionProof] = useState(false)

  const cookies = useMemo(() => new Cookies(), [])

  useEffect(() => {
    async function fetchTransactionProof() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assessments/${props.project.transactionProof}`
      })

      const q = response.data.questions
      setTransactionProofCompleted((q.filter(q => q.completed).length === q.length) && q.length > 0)
    }

    if (!fetchedTransactionProof) {
      fetchTransactionProof()
      setFetchedTransactionProof(true)
    }
  }, [fetchedTransactionProof, cookies, props.project.transactionProof])

  let adminOptions = []
  if (props.user.isAdmin) {
    const signingEvents = props.project.meetingRequests.filter(mr => mr.reason === 'Signing')
    let signingEventApproved = false;
    for (let se of signingEvents) {
      if (se.approvedDate) { signingEventApproved = true; break; }
    }
    adminOptions.push(['Signing-Termin', signingEventApproved, signingEventApproved])

    const closingEvents = props.project.meetingRequests.filter(mr => mr.reason === 'Vollzug')
    let closingEventApproved = false;
    for (let ce of closingEvents) {
      if (ce.approvedDate) { closingEventApproved = true; break; }
    }
    adminOptions.push(['Vollzugs-Termin', closingEventApproved, closingEventApproved])

    adminOptions.push(['Transaktionsnachweise', transactionProofCompleted, transactionProofCompleted])

    adminOptions.push(['Fortschritt', props.project.approvedBySeller, props.project.approvedByBuyer])
  }

  return (
    <div className="ProjectCards">
      <EventScheduler
        title="Terminabstimmung: Signing"
        description="Stimme hier bitte rechtzeitig einen gemeinsamen Termin zur Unterzeichnung der finalen rechtlichen Dokumente ab."
        meetingName="Signing"
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={d => props.patchProject(d)}
      />

      <EventScheduler
        title="Terminabstimmung: Closing"
        description="Sollte das Closing nicht gleichzeitig mit dem Signing stattfinden, so stimme hier bitte rechtzeitig einen gemeinsamen Termin zum Vollzug der Transaktion ab."
        meetingName="Vollzug"
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={d => props.patchProject(d)}
      />

      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">Rechtliche Dokumente</h3>
        <p className="ActionCard-body">Erfülle alle Anfragen, die dein Partner an dich stellt. Alle Angaben werden vor ihrer Freigabe durch Speer geprüft.</p>
        <div className="ActionCard-actions">
          <a href={`/assessments/${props.project.legalDocs}`} className="button ActionCard-action secondary small">Liste öffnen</a>
        </div>
      </div>

      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">Vollzugsbedingungen</h3>
        <p className="ActionCard-body">Erfülle alle Anfragen, die dein Partner an dich stellt. Alle Angaben werden vor ihrer Freigabe durch Speer geprüft.</p>
        <div className="ActionCard-actions">
          <a href={`/assessments/${props.project.closingConditions}`} className="button ActionCard-action secondary small">Liste öffnen</a>
        </div>
      </div>

      <div className="ProjectCard ActionCard">
        <h3 className="ActionCard-title">Transaktionsnachweise</h3>
        <p className="ActionCard-body">Erfülle alle Anfragen, die dein Partner an dich stellt. Alle Angaben werden vor ihrer Freigabe durch Speer geprüft.</p>
        <div className="ActionCard-actions">
          <a href={`/assessments/${props.project.transactionProof}`} className="button ActionCard-action secondary small">Liste öffnen</a>
        </div>
      </div>

      <ApprovalCard
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={e => props.patchProject(e)}
        approved={props.isSeller ? props.project.approvedBySeller : props.project.approvedByBuyer}
        customTitle='Transaktion archivieren'
        customBody='Mit deiner Freigabe signalisierst du deine Bereitschaft. Dein Partner und Speer werden darüber informiert.'
        customCTALabelDefault='Transaktion archivieren'
        customCTALabelSuccess='Transaktion abgeschlossen'
        />

      <AdminActions
        isFinal
        user={props.user}
        project={props.project}
        patchProject={d => props.patchProject(d)}
        options={adminOptions}
      />
    </div>
  )
}