import React, { useState, useEffect } from 'react';

import { ChevronDown } from 'react-feather';

import './style.css';

export default function Accordion(props) {
  const [collapsed, setCollapsed] = useState(true)

  useEffect(() => {
    if (props.toggleState !== undefined) setCollapsed(!props.toggleState)
  }, [ props.toggleState ])

  let conditionalBody;
  if (!collapsed) {
    conditionalBody = (
      <div className="Accordion-body fade-in-down">
        <p className="Accordion-intro">{props.body}</p>

        {props.children}
      </div>
    )
  }
  return (
    <div className="Accordion" style={ collapsed ? {} : { position: 'relative', zIndex: 1 }}>
      <div className="Accordion-header" onClick={e => setCollapsed(!collapsed)}>
        <h3 className="Accordion-title">{props.title}</h3>
        <div className="Accordion-action">
          <div className={`badge ${props.badgeStyle}`}>{props.badgeLabel}</div>
          <ChevronDown size={16} color='#959ABA' className={collapsed ? '' : 'rotated'} />
        </div>
      </div>

      {conditionalBody}
    </div>
  )
}