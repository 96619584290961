import React, { useState, useEffect, useMemo, useRef } from 'react';

import Accordion from '../../../components/Accordion';
import FileCard from '../../../components/FileCard';

import axios from 'axios';
import Cookies from 'universal-cookie'

import Env from '../../../Environments';

export default function DocumentSettings(props) {
  const [score, setScore] = useState(0)
  const [fieldsSet, setFieldsSet] = useState(false)
  const [assets, setAssets] = useState([])
  const [menuTarget, setMenuTarget] = useState()

  const cookies = useMemo(() => new Cookies(), [])

  const fileUpload = useRef();

  useEffect(() => {
    async function fetchAssets() {
      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: { owner: props.buyer.user },
        url: `${Env.url}/assets/search`
      })

      let buyerAssets = []
      // filter these assets
      for (let asset of response.data) {
        if (!asset.project) buyerAssets.push(asset)
      }
      setAssets(buyerAssets)
    }

    if (props.buyer._id && !fieldsSet) {
      fetchAssets()
      setFieldsSet(true)
    }

    setScore(assets.length ? 100 : 0)
  }, [props.buyer, fieldsSet, cookies, assets])

  async function handleFileUpload(e) {
    const fileList = Array.from(e.target.files)

    const data = new FormData()
    data.append('vfile', fileList[0])
    data.append('path', `users/${props.buyer.user._id}/buyers/${props.buyer._id}/presentation`)
    data.append('owner', props.buyer.user._id)

    const response = await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/assets`
    })

    setAssets([...assets, response.data])
  }

  async function patchFile(a, newData) {
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: newData,
      url: `${Env.url}/assets/${a._id}`
    })

    setAssets()
    setFieldsSet(false)
  }

  async function deleteFile(a) {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/assets/${a._id}`
    })

    setAssets()
    setFieldsSet(false)
  }

  let fileCards = [];
  if (assets && assets.length) {
    for (let asset of assets) {
      // exclude any existing logos
      if (!asset.path.endsWith('logo') && asset.path.includes(props.buyer._id.toString())) {
        fileCards.push(
          <FileCard
            user={props.user}
            asset={asset}
            menuTarget={menuTarget}
            setMenuTarget={setMenuTarget}
            patchFile={newData => patchFile(asset, newData)}
            deleteFile={() => deleteFile(asset)}
          />
        )
      }
    }
  }

  return (
    <Accordion
      title="Dokumente"
      toggleState={props.toggleState}
      body="Lade hier gern Erfolgsgeschichten und/oder Presseartikel hoch, die Unternehmen aus deinem Portfolio vorstellen. Diese Informationen werden verifizierten Besuchern deines Investorenprofils angezeigt."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
    >
      <button className="button secondary small" style={{ marginTop: '12px' }} onClick={() => fileUpload.current.click()}>Neue Datei hochladen</button>
      <input type="file" ref={fileUpload} className="invisible" onChange={e => handleFileUpload(e)} />

      <div className="settings-subsection">
        <div className="Settings-fields Settings-fields-c2">
          {fileCards}
        </div>
      </div>
    </Accordion>
  )
}