import { useState } from "react";

export default function TransactionLog(props) {
  const [activeStage, setActiveStage] = useState(props.project.step)

  return (
    <div className="TransactionLog fade-in-up">
      <div className="TableHeader">
        <div className="TableControl">
          <div className="toggle-switch">
            <div
              className={`toggle-option ${activeStage === 0 ? 'active' : ''}`}
              onClick={() => setActiveStage(0)}
              >
                Phase 1
              </div>
            <div
              className={`toggle-option ${activeStage === 1 ? 'active' : ''}`}
              onClick={() => setActiveStage(1)}
              >
                Phase 2
              </div>
            <div
              className={`toggle-option ${activeStage === 2 ? 'active' : ''}`}
              onClick={() => setActiveStage(2)}
              >
                Phase 3
              </div>
            <div
              className={`toggle-option ${activeStage === 3 ? 'active' : ''}`}
              onClick={() => setActiveStage(3)}
              >
                Phase 4
              </div>
            <div
              className={`toggle-option ${activeStage === 4 ? 'active' : ''}`}
              onClick={() => setActiveStage(4)}
              >
                Phase 5
              </div>
          </div>
        </div>
        <div className="TableAction">
          <span className="TableAction-action">
            <button className="search">Aktion oder Akteur suchen ...</button>
          </span>
        </div>
      </div>

      <table className="DataTable DataTable-left-aligned">
        <thead>
          <tr>
            <th>Akteur</th>
            <th>Aktion</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td><a href="/">Investor #1 AG</a></td>
            <td><span className="text-muted">hat das Dokument <a href="/">Speer NDA V3.docx</a> in den Bereich <i>Transaktions-NDA</i> hochgeladen.</span></td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}