import { useState, useEffect } from "react";

import Makro from "../../Makro";

export default function ProjectBuyerFeed(props) {
  const [actionFeed, setActionFeed] = useState([])

  useEffect(() => {
    let newActionFeed = []

    if (props.project) {
      switch (props.project.step) {
        case 0:
          if (props.project.buyerOptOut || props.project.buyerOptOut === undefined) {
            newActionFeed.push(
              <Makro
                actionLabel="Ja, bin ich!"
                label="Bist du interessiert?"
                targetAction={() => props.patchProject({ buyerOptOut: false })}
              />
            )
          }

          newActionFeed.push(
            <Makro
              iconAction
              label="Unternehmerprofil"
              target={`/seller-profile/${props.project.seller._id}`}
              />
          )
          break;
        case 1:
          newActionFeed.push(
            <Makro
              iconAction
              label="Unternehmerprofil"
              target={`/seller-profile/${props.project.seller._id}`}
              />
          )
          break;
        case 2:
          newActionFeed.push(
            <Makro
              actionLabel="Aufrufen"
              label="Contact Sheet"
              targetAction={() => props.updateActiveScope('Partner')}
              />
          )
          break;
        default:
          break;
      }
  
      if (!props.project.approvedByBuyer && props.project.step < 3) {
        newActionFeed.push(
          <Makro
            iconAction
            label="Fortschritt freigeben"
            targetAction={() => props.patchProject({ approvedByBuyer: !props.project.approvedByBuyer })}
            />
        )
      }
  
      if (!props.project.approvedByBuyer && props.project.step >= 3) {
        newActionFeed.push(
          <Makro
            iconAction
            label="Projekt archivieren"
            targetAction={() => props.patchProject({ approvedByBuyer: !props.project.approvedByBuyer })}
            />
        )
      }
    }

    if (newActionFeed.length) setActionFeed(newActionFeed)
  }, [ props ])

  return actionFeed;
}