import React, { useState, useEffect, useCallback } from 'react';

import Accordion from '../../../components/Accordion';
import NumberInput from '../../../components/NumberInput';

export default function InvestmentPreferences(props) {
  const [score, setScore] = useState(0)
  const [initializeValues, setInitializeValues] = useState(false)

  const [minRevenuePlatform, setMinRevenuePlatform] = useState()
  const [minEbitdaPlatform, setMinEbitdaPlatform] = useState()
  const [minEbitPlatform, setMinEbitPlatform] = useState()
  const [minRevenueAddon, setMinRevenueAddon] = useState()
  const [minEbitdaAddon, setMinEbitdaAddon] = useState()
  const [minEbitAddon, setMinEbitAddon] = useState()

  const updateScore = useCallback(() => {
    let newScore = 0
    const properties = [minRevenuePlatform, minEbitdaPlatform, minEbitPlatform, minRevenueAddon, minEbitdaAddon, minEbitAddon]
    properties.forEach(p => {
      if (p && p !== '') newScore += 100/properties.length
    })

    setScore(Math.round(newScore))
  }, [minRevenuePlatform, minEbitdaPlatform, minEbitPlatform, minRevenueAddon, minEbitdaAddon, minEbitAddon])

  useEffect(() => {
    if (props.buyer._id && !initializeValues) {
      setMinRevenuePlatform(props.buyer.minRevenuePlatform)
      setMinEbitdaPlatform(props.buyer.minEbitdaPlatform)
      setMinEbitPlatform(props.buyer.minEbitPlatform)
      setMinRevenueAddon(props.buyer.minRevenueAddon)
      setMinEbitdaAddon(props.buyer.minEbitdaAddon)
      setMinEbitAddon(props.buyer.minEbitAddon)

      setInitializeValues(true)
    }

    updateScore()
  }, [props.buyer, updateScore, minRevenuePlatform, initializeValues])

  function universalSetter(property, setter, newValue) {
    let updatePayload = {}
    updatePayload[property] = newValue

    props.patchBuyer(updatePayload)

    updateScore()

    setter(newValue)
  }

  return (
    <Accordion
      title="Investitionskriterien"
      toggleState={props.toggleState}
      body="Lege wirtschaftliche Zielwerte für Plattformen und Add-Ons fest, die als Mindestvoraussetzung für ein potenzielles Investment gesehen werden können."
      badgeLabel={`${score}% ausgefüllt`}
      badgeStyle={score === 100 ? 'badge-success' : ''}
      >
        <h5>Plattform</h5>
        <div className="Settings-fields Settings-fields-c3">

        <div className="form-field">
          <label>Umsatz</label>
          <NumberInput
            suffix={props.buyer.currency}
            value={minRevenuePlatform}
            onChange={e => universalSetter('minRevenuePlatform', setMinRevenuePlatform, e)}
            />
        </div>
        <div className="form-field">
          <label>EBITDA</label>
          <NumberInput
            suffix={props.buyer.currency}
            value={minEbitdaPlatform}
            onChange={e => universalSetter('minEbitdaPlatform', setMinEbitdaPlatform, e)}
            />
        </div>
        <div className="form-field">
          <label>EBIT</label>
          <NumberInput
            suffix={props.buyer.currency}
            value={minEbitPlatform}
            onChange={e => universalSetter('minEbitPlatform', setMinEbitPlatform, e)}
            />
        </div>
      </div>

      <h5>Add-On</h5>
      <div className="Settings-fields Settings-fields-c3">
        <div className="form-field">
          <label>Umsatz</label>
          <NumberInput
            suffix={props.buyer.currency}
            value={minRevenueAddon}
            onChange={e => universalSetter('minRevenueAddon', setMinRevenueAddon, e)}
            />
        </div>
        <div className="form-field">
          <label>EBITDA</label>
          <NumberInput
            suffix={props.buyer.currency}
            value={minEbitdaAddon}
            onChange={e => universalSetter('minEbitdaAddon', setMinEbitdaAddon, e)}
            />
        </div>
        <div className="form-field">
          <label>EBIT</label>
          <NumberInput
            suffix={props.buyer.currency}
            value={minEbitAddon}
            onChange={e => universalSetter('minEbitAddon', setMinEbitAddon, e)}
            />
        </div>
      </div>
    </Accordion>
  )
}