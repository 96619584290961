import { useState } from 'react';

import speerLogo from '../../assets/logo.svg';
import speerLogoLight from '../../assets/logo_light.svg';

import { Search, Grid, Box, Briefcase, LogOut, Sliders, Menu } from 'react-feather';

import './style.css';

export default function MenuBar(props) {
  const [showMenu, setShowMenu] = useState()

  let settingsLink;
  if (!props.user.isAdmin) {
    settingsLink = (
      <div
        className={props.scope === 'settings' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
        onClick={() => document.location = '/settings' }
        >
        <div className="Sidebar-link-icon"><Sliders size={15} color='#656FA4' /></div>
        <div className="Sidebar-link-label">Einstellungen</div>
      </div>
    )
  }

  return (
    <div className="MenuBar">
      <div className="MenuBar-left">
        <img src={props.darkMode ? speerLogoLight : speerLogo} alt="Speer Logo" className="fade-in" />
      </div>
      <div className="MenuBar-right">
        <Menu size={18} color='#203052' onClick={() => setShowMenu(!showMenu)} />
      </div>

      <div className={showMenu ? 'MenuBar-main active' : 'MenuBar-main'}>
        <div className="MenuBar-Sidebar">
          <div className="Sidebar-section Sidebar-section--narrow">
            <img src={props.darkMode ? speerLogoLight : speerLogo} alt="Speer Logo" className="fade-in" />
          </div>
          <div className="Sidebar-section Sidebar-section--narrow">
            <div
              className={props.scope === 'dashboard' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/' }
              >
              <div className="Sidebar-link-icon">
                <Grid size={15} color='#656FA4' />
                </div>
              <div className="Sidebar-link-label">Dashboard</div>
            </div>
          </div>

          <div className="Sidebar-section">
            <div className="Sidebar-section-header">
              <span className="Sidebar-section-title">Meine Profile</span>
            </div>
            <div
              className={props.scope === 'buyer-profiles' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/buyer-profiles' }
              >
              <div className="Sidebar-link-icon"><Box size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Investorenprofile</div>
            </div>
            <div
              className={props.scope === 'seller-profiles' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/seller-profiles' }
              >
              <div className="Sidebar-link-icon"><Briefcase size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Unternehmerprofile</div>
            </div>
          </div>

          <div className="Sidebar-section">
            <div className="Sidebar-section-header">
              <span className="Sidebar-section-title">Projekte</span>
            </div>
            <div
              className={props.scope === 'buyer-projects' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/buyer-projects' }
              >
              <div className="Sidebar-link-icon"><Box size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">{props.user.isAdmin ? 'Nach Investoren' : 'Als Investor'}</div>
            </div>
            <div
              className={props.scope === 'seller-projects' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/seller-projects' }
              >
              <div className="Sidebar-link-icon"><Briefcase size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">{props.user.isAdmin ? 'Nach Unternehmen' : 'Als Unternehmer'}</div>
            </div>
          </div>

          <div className="Sidebar-section">
            <div className="Sidebar-section-header">
              <span className="Sidebar-section-title">Suche</span>
            </div>
            <div
              className={props.scope === 'search' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/search/new' }
              >
              <div className="Sidebar-link-icon"><Search size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Quick Search</div>
            </div>
            <div
              className={props.scope === 'buyer-subscriptions' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/subscriptions' }
              >
              <div className="Sidebar-link-icon"><Box size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Speer Scouts™ Investoren</div>
            </div>
            <div
              className={props.scope === 'seller-subscriptions' ? 'Sidebar-link Sidebar-link--active' : 'Sidebar-link'}
              onClick={() => document.location = '/subscriptions' }
              >
              <div className="Sidebar-link-icon"><Briefcase size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Speer Scouts™ Unternehmen</div>
            </div>
          </div>

          <div className="Sidebar-section">
            {settingsLink}
            <div className="Sidebar-link" onClick={() => props.signOutUser()}>
              <div className="Sidebar-link-icon"><LogOut size={15} color='#656FA4' /></div>
              <div className="Sidebar-link-label">Abmelden</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}