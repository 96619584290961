import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

export default function CustomPhoneInput(props) {
  return (
    <PhoneInput
      inputStyle={{
        width: '100%',
        backgroundColor: '#fff',
        border: '1px solid #F1F1F3',
        boxSizing: 'border-box',
        borderRadius: '6px',
        boxShadow: 'none',
        outline: 'none',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '0.02em',
        color: '#282E3E',
        padding: '20px 18px 22px 48px'
      }}
      country={props.country}
      value={props.value}
      onChange={v => props.onChange(v)}
    />
  )
}