import { useState } from 'react';

import Accordion from '../../../components/Accordion';

import { X } from 'react-feather';

export default function ProfileDeletion(props) {
  const [showDeletionConfirmation, setShowDeletionConfirmation] = useState(false)

  let conditionalDeletionButton;
  if (props.buyer.projects && props.buyer.projects.length) {
    conditionalDeletionButton = (
      <button className="button danger small disabled" style={{ marginTop: '12px' }} disabled>Profil löschen</button>
    )
  } else {
    conditionalDeletionButton = (
      <button className="button danger small" style={{ marginTop: '12px' }} onClick={() => setShowDeletionConfirmation(true)}>Profil löschen</button>
    )
  }

  let conditionalDeletionConfirmation;
  if (showDeletionConfirmation) {
    conditionalDeletionConfirmation = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={() => setShowDeletionConfirmation(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Bist du sicher?</h3>
              <p className="overlay-intro">
                Diese Aktion kann nicht rückgängig gemacht werden und du verlierst sofort all deine Profildaten.
              </p>
            </div>

            <button className="button danger full-width margin-top" onClick={() => props.deleteBuyer()}>Ja, wirklich löschen</button>
            <button className="button primary full-width margin-top" onClick={() => setShowDeletionConfirmation(false)}>Nein, schon gut</button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Accordion
        title="Profil löschen"
        toggleState={props.toggleState}
        body="Wenn du dieses Investorenprofil nicht mehr im Hub verwenden möchtest, kannst du es löschen. Bitte beachte, dass diese Option nicht mehr zur Verfügung steht, sobald du eine Transaktion in deinem Account hinterlegt hast."
        badgeLabel='Irreversibel'
        badgeStyle='badge-danger'
      >
        {conditionalDeletionButton}
      </Accordion>

      {conditionalDeletionConfirmation}
    </>
  )
}