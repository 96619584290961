import axios from 'axios';

import Env from '../Environments';

const activityLogger = {
  log: async (options) => {
    await axios({
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      data: options,
      url: `${Env.url}/activities`
    })
  }
}

export default activityLogger;
