import React, { useState, useEffect } from 'react';

import Accordion from '../../../components/Accordion';

import { X } from 'react-feather';

export default function DocumentRequirements(props) {
  const [score, setScore] = useState(0)
  const [showOverlay, setShowOverlay] = useState(false)
  const [newReqName, setNewReqName] = useState()

  useEffect(() => {
    setScore((props.buyer.documentRequirements || []).length ? 100 : 0)
  }, [props.buyer])

  async function deleteDocumentRequirement(dr) {
    await props.patchBuyer({
      documentRequirements: props.buyer.documentRequirements.filter(d => d !== dr)
    })
  }

  async function submitRequirement(evt) {
    evt.preventDefault()

    await props.patchBuyer({
      documentRequirements: [...props.buyer.documentRequirements, {
        name: newReqName
      }]
    })

    setNewReqName('')
    setShowOverlay(false)
  }

  let reqCards = [
    <div className="file-card-container">
      <div className="file-card">
        <div className="file-card-label">
          <span className="file-card-title">Jahresabschlüsse/Bilanzen der letzten 3 Jahre</span>
        </div>
      </div>
    </div>,
    <div className="file-card-container">
      <div className="file-card">
        <div className="file-card-label">
          <span className="file-card-title">Historische Investitionen der letzten 3 Jahre</span>
        </div>
      </div>
    </div>,
    <div className="file-card-container">
      <div className="file-card">
        <div className="file-card-label">
          <span className="file-card-title">Geplante Investitionen der nächsten 2 Jahre</span>
        </div>
      </div>
    </div>,
    <div className="file-card-container">
      <div className="file-card">
        <div className="file-card-label">
          <span className="file-card-title">Normalisierungen / Sondereffekte der letzten 3 Jahre</span>
        </div>
      </div>
    </div>,
    <div className="file-card-container">
      <div className="file-card">
        <div className="file-card-label">
          <span className="file-card-title">Budget für das aktuelle und folgende Jahr</span>
        </div>
      </div>
    </div>,
    <div className="file-card-container">
      <div className="file-card">
        <div className="file-card-label">
          <span className="file-card-title">Feste und variable Geschäftsleitungsvergütung</span>
        </div>
      </div>
    </div>
  ];

  if (props.buyer._id && props.buyer.documentRequirements) {
    for (let dr of props.buyer.documentRequirements) {
      reqCards.push(
        <div className="file-card-container">
          <div className="file-card">
          <div className="file-card-label">
            <span className="file-card-title">{dr.name}</span>
          </div>

          <div className="file-card-actions">
            <button className="file-card-action button circle secondary small" onClick={() => deleteDocumentRequirement(dr)}>
              <X size={18} />
            </button>
          </div>
          </div>
        </div>
      )
    }
  }

  let conditionalOverlay;
  if (showOverlay) {
    conditionalOverlay = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowOverlay(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Neue Anforderung</h3>
              <p className="overlay-intro">
                Benenne das Dokument, die du von potenziellen Partnern in jeder Transaktion anfordern möchtest.
              </p>
            </div>

            <form className="overlay-body" onSubmit={e => submitRequirement(e)}>
              <div className="form-field">
                <label>Neues Dokument</label>
                <input type="text" value={newReqName} onChange={e => setNewReqName(e.target.value)} />
              </div>

              <button type="submit"
                style={{ marginTop: '12px' }}
                className="button primary full-width"
                >
                  Anforderung hinterlegen
              </button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  return (
    <>
      <Accordion
        title="Standard-Dokumente Anforderungen"
        toggleState={props.toggleState}
        body="Bitte definiere deine persönlichen Anforderungen an potentielle Unternehmer und Unternehmen, welche du zur Erstellung eines indikativen sowie unverbindlichen Angebotes in jedem Fall benötigst. Zu den Standard-Anforderungen, die Speer in jedem Fall erfasst, gehören Jahresabschlüsse/Bilanzen der letzten 3 Jahre, die geplante Entwicklung der nächsten 2 Jahre, historische (wesentliche) Investitionen sowie Sondereffekte der letzten 3 Jahre."
        badgeLabel={`${score}% ausgefüllt`}
        badgeStyle={score === 100 ? 'badge-success' : ''}
      >
        <button
          className="button secondary small"
          style={{ marginTop: '12px' }}
          onClick={() => setShowOverlay(true)}
          >
          Dokument anfordern
        </button>

        <div className="settings-subsection">
          <div className="Settings-fields Settings-fields-c2">
            {reqCards}
          </div>
        </div>
      </Accordion>

      {conditionalOverlay}
    </>
  )
}