import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'universal-cookie'

import Env from '../../Environments';

import Aside from '../../components/Aside';
import WidgetFeed from '../../components/WidgetFeed';

import BaseData from './components/BaseData';
import QuickFacts from './components/QuickFacts';
import InvestmentPreferences from './components/InvestmentPreferences';
import DocumentSettings from './components/DocumentSettings';
import DocumentRequirements from './components/DocumentRequirements';
import InvestorTerms from './components/InvestorTerms';
import NDASettings from './components/NDASettings';
import PartnerSettings from './components/PartnerSettings';
import ProfileDeletion from './components/ProfileDeletion';

export default function BuyerSettings(props) {
  const [buyer, setBuyer] = useState({})
  const [showScope, setShowScope] = useState('')

  const cookies = useMemo(() => new Cookies(), [])

  const { bid } = useParams()

  useEffect(() => {
    const hrefSplit = document.location.href.split('#')
    if (hrefSplit.length > 1) setShowScope(hrefSplit[1])
  }, [showScope])

  async function patchBuyer(data) {
    const response = await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/buyers/${bid}`
    })

    setBuyer(response.data)
  }

  async function deleteBuyer() {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/buyers/${bid}`
    })

    document.location = '/buyer-profiles'
  }

  useEffect(() => {
    async function fetchBuyer() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/buyers/${bid}`
      })

      setBuyer(response.data)
    }

    if (!buyer._id) fetchBuyer()
  }, [cookies, bid, buyer._id])

  return (
    <div className="BuyerSettings">
      <Aside darkMode={props.darkMode} user={props.user} scope="settings" signOutUser={() => props.signOutUser()}>
        <div className="ContentPanel">
          <div className="ContentPanel-main">
            <div className="PanelHeader">
              <h1 className="PanelHeader-title"><strong>Firmeneinstellungen</strong> für {buyer.name} {buyer.type}</h1>
              <h5 className="PanelHeader-subline">Unternehmensdaten, Projektvorbereitung & Partnerverwaltung</h5>
            </div>

            <BaseData buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'base-data'} />
            <QuickFacts buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'quick-facts'} />
            <InvestmentPreferences user={props.user} buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'investment-preferences'} />
            <DocumentSettings user={props.user} buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'document-settings'} />
            <DocumentRequirements user={props.user} buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'document-requirements'} />
            <InvestorTerms user={props.user} buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'investor-terms'} />
            <NDASettings user={props.user} buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'nda-settings'} />
            <PartnerSettings buyer={buyer} patchBuyer={patchBuyer} toggleState={showScope === 'partner-settings'} />
            <ProfileDeletion buyer={buyer} deleteBuyer={deleteBuyer} toggleState={showScope === 'profile-deletion'} />
          </div>

          <div className="ContentPanel-sidebar">
            <WidgetFeed scope="buyer-settings" />
          </div>
        </div>
      </Aside>
    </div>
  )
}