import { useState, useEffect, useMemo, useRef } from "react";

import axios from 'axios';
import Cookies from 'universal-cookie';

import Env from '../../../Environments';

import Checkbox from '../../../components/Checkbox';
import EventScheduler from '../../../components/EventScheduler';
import FileCard from '../../../components/FileCard';
import StatusBadge from '../../../components/StatusBadge';
import Tooltip from '../../../components/Tooltip';

import ApprovalCard from './ApprovalCard';

import AdminActions from './AdminActions';

import { X, HelpCircle } from 'react-feather';

export default function Stage1(props) {
  const [fetchedAssets, setFetchedAssets] = useState(false)
  const [optedOut, setOptedOut] = useState()
  const [assets, setAssets] = useState([])
  const [menuTarget, setMenuTarget] = useState()
  const [showConditionalApprovalModal, setShowConditionalApprovalModal] = useState(false)
  const [showChannelPicker, setShowChannelPicker] = useState(false)
  const [phaseAppointment, setPhaseAppointment] = useState(false)
  const [customTerms, setCustomTerms] = useState()

  const cookies = useMemo(() => new Cookies(), [])

  const fileUpload = useRef()

  useEffect(() => {
    async function fetchCustomTerms() {
      const response = await axios({
        method: 'GET',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        url: `${Env.url}/assets/${props.buyer.customTerms}`
      })

      console.log(response.data)
      setCustomTerms(response.data)
    }

    async function fetchAssets() {
      const response = await axios({
        method: 'POST',
        headers: {
          'content-type': 'application/json',
          'Authorization': cookies.get('p4itoken')
        },
        data: {
          path: `projects/${props.project._id}/${props.phaseNames[props.project.step]}`
        },
        url: `${Env.url}/assets/search`
      })

      setAssets(response.data)
    }

    if (props.project._id && !fetchedAssets) {
      fetchAssets()
      setFetchedAssets(true)
    }

    if (props.project) {
      // find the meeting request
      for (let i = 0; i < props.project.meetingRequests.length; i++) {
        const mr = props.project.meetingRequests[i]
        if (mr.reason === 'Erstes Kennenlernen') setPhaseAppointment(mr)
      }
    }

    // initialize opt out
    if (props.isSeller) {
      setOptedOut(props.project.sellerOptOut)
    } else {
      setOptedOut(props.project.buyerOptOut)
    }

    if (props.buyer && props.buyer.customTerms && !customTerms) fetchCustomTerms()
  }, [props.project, props.phaseNames, fetchedAssets, cookies, props.isSeller, props.buyer, customTerms])

  const sellerComPreferences = props.project.sellerPrefersEmail ||
  props.project.sellerPrefersPhone || props.project.sellerPrefersMobile ||
  props.project.sellerPrefersVideo

  const buyerComPreferences = props.project.buyerPrefersEmail ||
  props.project.buyerPrefersPhone || props.project.buyerPrefersMobile ||
  props.project.buyerPrefersVideo

  async function deleteFile(asset) {
    await axios({
      method: 'DELETE',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      url: `${Env.url}/assets/${asset._id}`
    })

    setFetchedAssets(false)
  }

  async function patchFile(asset, newData) {
    await axios({
      method: 'PATCH',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: newData,
      url: `${Env.url}/assets/${asset._id}`
    })

    setFetchedAssets(false)
  }

  let fileCards = [];
  for (let asset of assets) {
    let additionalUsers;
    if (props.isAdmin) {
      additionalUsers = [{ _id: props.project.buyer.user }, { _id: props.project.seller.user }]
    } else {
      additionalUsers = props.isSeller ? [{ _id: props.project.buyer.user }] : [{ _id: props.project.seller.user }]
    }

    fileCards.push(
      <FileCard
        user={props.user}
        additionalUsers={additionalUsers}
        asset={asset}
        menuTarget={menuTarget}
        setMenuTarget={setMenuTarget}
        deleteFile={() => deleteFile(asset)}
        patchFile={newData => patchFile(asset, newData)}
        />
    )
  }

  async function setInterest(newValue) {
    setOptedOut(newValue)

    if (props.isSeller) {
      await props.patchProject({ sellerOptOut: newValue })
    } else {
      await props.patchProject({ buyerOptOut: newValue })
    }
  }

  async function handleFileUpload(e) {
    const fileList = Array.from(e.target.files)

    const data = new FormData()
    data.append('vfile', fileList[0])
    data.append('path', `projects/${props.project._id}/${props.phaseNames[props.project.step]}`)
    data.append('owner', props.user._id)

    await axios({
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        'Authorization': cookies.get('p4itoken')
      },
      data: data,
      url: `${Env.url}/assets`
    })

    if (props.isSeller) {
      await props.patchProject({ sellerNDASigned: true })
    } else {
      await props.patchProject({ buyerNDASigned: true })
    }

    setFetchedAssets(false)
  }

  let interestCard;
  if (!props.user.isAdmin) {
    interestCard = (
      <div className="ProjectCard ActionCard fade-in-up">
        <StatusBadge baseValue={optedOut !== undefined ? !optedOut : undefined} />

        <h3 className="ActionCard-title">Interesse an der Opportunität</h3>
        <p className="ActionCard-body">Um mit dieser Opportunität fortzufahren, benötigen wir hiermit die Bestätigung deines Interesses an einem ersten persönlichen und unverbindlichen Austausch.</p>
        <div className="ActionCard-actions">
          <div className="toggle-switch button-sized">
            <div
              className={`toggle-option ${optedOut === false ? 'active' : ''}`}
              onClick={() => setInterest(false)}
              >
                Interessiert
              </div>
            <div
              className={`toggle-option ${optedOut === true ? 'active' : ''}`}
              onClick={() => setInterest(true)}
              >
                Nicht interessiert
              </div>
          </div>
        </div>
      </div>
    )
  }

  let conditionalTermsDL;
  if (customTerms) {
    conditionalTermsDL = (
      <p className="text-muted">Du kannst deine spezifischen Speer Agreements <a href={`/settings/buyers/${props.project.buyer._id}#investor-terms`}>hier</a> einsehen und herunterladen</p>
    )
  }

  

  let conditionalApprovalModal;
  if (showConditionalApprovalModal) {
    const b = props.project.buyer;
    conditionalApprovalModal = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowConditionalApprovalModal(false)} />
            <div className="overlay-header no-border">
              <h3 className="overlay-title">Finder's Fee</h3>
              <p className="overlay-intro">
                Durch die Erklärung deiner Bereitschaft mit dem Fortschritt dieser Transaktion erklärst du dich mit den festgelegten Rahmenbedingungen für deinen Investor-Account einverstanden und akzeptierst im Falle eines erfolgreichen Abschlusses die entsprechend durch Speer Partners in Rechnung gestellte Finder's Fee.
              </p>

              {conditionalTermsDL}
            </div>

            <div className="overlay-body">
              <table className="DataTable margin-bottom">
                <thead>
                  <tr>
                    <th>Kondition</th>
                    <th>Betrag</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Erfolgshonorar</td>
                    <td>{b.successRate}%</td>
                  </tr>
                  <tr>
                    <td>Mindestbetrag</td>
                    <td>{b.minAmount.toLocaleString('ch-CH', { currency: b.currency })}</td>
                  </tr>
                  <tr>
                    <td>Höchstbetrag</td>
                    <td>{b.maxAmount.toLocaleString('ch-CH', { currency: b.currency })}</td>
                  </tr>
                </tbody>
              </table>

              {customTerms}

              <div className="overlay-actions">
                <button
                  style={{ marginTop: '12px' }}
                  className="button small primary"
                  onClick={() => approveAndClose()}
                  >
                  Akzeptieren und Fortfahren
                </button>

                <Tooltip text="Klärung erforderlich">
                  <a
                    style={{ marginTop: '12px', marginLeft: '24px' }}
                    href="mailto:support@p4i-partners.com"
                    >
                    <HelpCircle />
                  </a>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function approvalOrExecution(e) {
    if (props.isSeller || props.project.approvedByBuyer) {
      props.patchProject(e)
    } else {
      setShowConditionalApprovalModal(true)
    }
  }

  async function approveAndClose() {
    await props.patchProject({ approvedByBuyer: !props.project.approvedByBuyer })
    setShowConditionalApprovalModal(false)
  }

  let ndaCard;
  if (props.isSeller || props.project.approvedByBuyer || props.user.isAdmin) {
    ndaCard = (
      <div className="ProjectCard ActionCard ProjectCard-full fade-in-up delay-100 ActionCard-file">
        <div className="ProjectCard-info">
          <h3 className="ActionCard-title">Non-disclosure agreement (NDA)</h3>
          <p className="ActionCard-body">
            Wir benötigen ein unterschriebenes NDA zu jeder Transaktion. Eine Vorlage kannst du <a href="https://storage.googleapis.com/p4ihub/p4idocs/220726_Geheimhaltungsvereinbarung%20P4i%20Erstgespra%CC%88ch%20.docx" target="_blank" rel="noopener noreferrer">hier herunterladen</a>.
          </p>
          <div className="ActionCard-actions">
            <button className="ActionCard-action secondary small" onClick={() => fileUpload.current.click()}>Unterzeichnetes NDA hochladen</button>
            <input type="file" ref={fileUpload} className="invisible" onChange={e => handleFileUpload(e)} />
          </div>
        </div>

        <div className="ProjectCard-surface">
          {fileCards}
        </div>
      </div>
    )
  }

  const comCard = (
    <div className="ProjectCard ActionCard fade-in-up">
      <StatusBadge baseValue={props.isSeller ? sellerComPreferences : buyerComPreferences} />

      <h3 className="ActionCard-title">Bevorzugte Kommunikationsmethode</h3>
      <p className="ActionCard-body">Lege hier deine bevorzugten Kommunikationskanäle fest, um Benachrichtigungen via den Hub absolut diskret zu erhalten.</p>
      <div className="ActionCard-actions">
        <button className="ActionCard-action secondary small" onClick={() => setShowChannelPicker(true)}>Kanäle auswählen</button>
      </div>
    </div>
  )

  let eventCard = (
    <EventScheduler
        title="Terminabstimmung: Ersten Kennenlernen"
        description="Stimme einen Termin für einen ersten unverbindlichen Austausch ab, um so alle Beteiligten der Opportunität persönlich kennenzulernen."
        meetingName="Erstes Kennenlernen"
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={d => props.patchProject(d)}
      />
  )

  const customBodySeller = 'Mit deiner Freigabe signalisierst du deine Bereitschaft weitere vertrauliche Informationen auszutauschen und eine mögliche Strukturierung der potentiellen Zusammenarbeit zu vertiefen. Dein Partner und Speer werden entsprechend informiert.'
  const customBodyBuyer = 'Mit deiner Freigabe signalisierst du hiermit deine Bereitschaft einen Kennenlerntermin mit deinem potentiellen Partner zu fixieren. Dein Partner und Speer werden daher entsprechend informiert.'

  let userApprovalCard;
  if (!props.isSeller && !props.user.isAdmin) {
    userApprovalCard = (
      <ApprovalCard
        user={props.user}
        project={props.project}
        isSeller={props.isSeller}
        patchProject={e => approvalOrExecution(e)}
        approved={props.isSeller ? props.project.approvedBySeller : props.project.approvedByBuyer}
        customBody={props.isSeller ? customBodySeller : customBodyBuyer}
        />
    )
  }

  let userCards;
  if (props.user && !props.user.isAdmin && props.project._id) {
    userCards = [ interestCard, comCard ]

    if (props.isSeller) {
      userCards = [
        ...userCards,
        ndaCard,
        eventCard,
        userApprovalCard
      ]
    } else if (!props.user.isAdmin) {
      if (props.project.approvedByBuyer) {
        userCards = [
          ...userCards,
          ndaCard,
          eventCard,
          userApprovalCard
        ]
      } else {
        userCards = [
          ...userCards,
          userApprovalCard
        ]
      }
    }
  }

  async function setPreference(sellerProp, buyerProp, newValue) {
    let patchData = {}
    if (props.isSeller) {
      patchData[sellerProp] = newValue
    } else {
      patchData[buyerProp] = newValue
    }

    await props.patchProject(patchData)
  }

  let conditionalChannelPicker;
  if (showChannelPicker) {
    let currentEmail;
    let currentPhoneNumber;
    let currentMobileNumber;
    if (props.isSeller) {
      currentEmail = props.project.seller.contactEmail || 'deiner primären E-Mail'

      currentPhoneNumber = 'deiner primären Telefonnummer'
      if (props.project.seller.contactPhone) currentPhoneNumber = `+${props.project.seller.contactPhone}`

      currentMobileNumber = 'deiner sekundären Telefonnummer'
      if (props.project.seller.contactPhone2) currentMobileNumber = `+${props.project.seller.contactPhone2}`
    } else {
      currentEmail = props.project.buyer.contactEmail || 'deiner primären E-Mail'

      currentPhoneNumber = 'deiner primären Telefonnummer'
      if (props.project.buyer.contactPhone) currentPhoneNumber = `+${props.project.buyer.contactPhone}`

      currentMobileNumber = 'deiner sekundären Telefonnummer'
      if (props.project.buyer.contactPhone2) currentMobileNumber = `+${props.project.buyer.contactPhone2}`
    }

    conditionalChannelPicker = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowChannelPicker(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Kommunikation</h3>
              <p className="overlay-intro">
                Definiere deine Vorlieben für die Kommunikation in Speer
              </p>
            </div>

            <form className="overlay-body">
              <Checkbox
                user={props.user}
                label='E-Mail'
                checked={props.isSeller ? props.project.sellerPrefersEmail : props.project.buyerPrefersEmail}
                toggleOption={v => setPreference('sellerPrefersEmail', 'buyerPrefersEmail', v)}
                info={<span>Wir benachrichten dich unter {currentEmail}.</span>}
                />

              <Checkbox
                user={props.user}
                label='Telefon'
                checked={props.isSeller ? props.project.sellerPrefersPhone : props.project.buyerPrefersPhone}
                toggleOption={v => setPreference('sellerPrefersPhone', 'buyerPrefersPhone', v)}
                info={<span>Wir benachrichten dich unter {currentPhoneNumber}.</span>}
                />

              <Checkbox
                user={props.user}
                label='Mobil'
                checked={props.isSeller ? props.project.sellerPrefersMobile : props.project.buyerPrefersMobile}
                toggleOption={v => setPreference('sellerPrefersMobile', 'buyerPrefersMobile', v)}
                info={<span>Wir benachrichten dich unter {currentMobileNumber}.</span>}
                />
            </form>
          </div>
        </div>
      </div>
    )
  }

  let adminCards;
  if (props.user.isAdmin) {
    adminCards = [
      ndaCard,
      eventCard,
      <AdminActions
        user={props.user}
        project={props.project}
        patchProject={d => props.patchProject(d)}
        options={[
          [ 'Interesse', !props.project.sellerOptOut, !props.project.buyerOptOut ],
          [ 'NDA', props.project.sellerNDASigned, props.project.buyerNDASigned ],
          [ 'Kommunikationskanäle', sellerComPreferences, buyerComPreferences ],
          [ 'Terminvorschläge', phaseAppointment.approvedDate, phaseAppointment.approvedDate ],
          [ 'Fortschritt', true, props.project.approvedByBuyer ]
        ]}
      />
    ]

    const sellerComs = []
    if (props.project.sellerPrefersEmail) sellerComs.push('E-Mail')
    if (props.project.sellerPrefersPhone) sellerComs.push('Telefon')
    if (props.project.sellerPrefersMobile) sellerComs.push('Mobil')
    if (props.project.sellerPrefersVideo) sellerComs.push('Video')

    const buyerComs = []
    if (props.project.buyerPrefersEmail) buyerComs.push('E-Mail')
    if (props.project.buyerPrefersPhone) buyerComs.push('Telefon')
    if (props.project.buyerPrefersMobile) buyerComs.push('Mobil')
    if (props.project.buyerPrefersVideo) buyerComs.push('Video')

    adminCards = [
      <div className="ProjectCard ActionCard fade-in-up">
        <StatusBadge baseValue={sellerComPreferences} />

        <h3 className="ActionCard-title">Unternehmenskommunikation</h3>
        <p className="ActionCard-body">
          Kommunikationsvorlieben von {props.project.seller.name}:
        </p>
        <p className="text-muted"><i>{sellerComs.join(', ') || 'keine'}</i></p>
      </div>,
      <div className="ProjectCard ActionCard fade-in-up">
        <StatusBadge baseValue={buyerComPreferences} />

        <h3 className="ActionCard-title">Investorkommunikation</h3>
        <p className="ActionCard-body">
          Kommunikationsvorlieben von {props.project.buyer.name}:
        </p>
        <p className="text-muted"><i>{buyerComs.join(', ') || 'keine'}</i></p>
      </div>,
      ...adminCards
    ]
  }

  return (
    <div className="ProjectCards">
      {userCards}
      {adminCards}

      {conditionalApprovalModal}
      {conditionalChannelPicker}
    </div>
  )
}