import React, { useState } from 'react';

import { ArrowRight } from 'react-feather';

import '../Makro/style.css';
import './style.css';

export default function MakroSelect(props) {
  const [showMenu, setShowMenu] = useState(false)

  function selectOption(val) {
    props.onSelect(val)
    setShowMenu(false)
  }

  let optionRows = props.options.map((opt, idx) => (
    <div
      value={opt.value}
      className="Makro-menu-item"
      key={`${idx}-${opt.value}`}
      onClick={() => selectOption(opt.value)}
      >
        {opt.label}
    </div>
  ))

  return (
    <div className={`Makro-container Makro-container--${props.direction || 'down'}`}>
      <div className={`Makro ${showMenu ? 'Makro--open' : ''}`}>
        <div className="Makro-label">{props.label}</div>
        <div className="Makro-action">
          <div className={`Makro-action-link ${props.iconAction ? 'Makro-action-link--icon' : ''}`} onClick={() => setShowMenu(!showMenu)}>
            { props.iconAction ? <ArrowRight color='white' size='14' /> : props.actionLabel }
          </div>
        </div>
      </div>

      <div className={`Makro-menu ${showMenu ? 'Makro-menu--open' : ''}`}>
        {optionRows}
      </div>
    </div>
  )
}