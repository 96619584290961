import Makro from '../../Makro';

export default function SubscriptionsFeed(props) {
  return (
    <Makro
      label="Neuen Scout erstellen"
      actionLabel="Quick Search"
      target={`/search/new`}
    />
  );
}