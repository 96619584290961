import { useState, useEffect } from 'react';

import CustomDatePicker from '../CustomDatePicker';
import CustomCreatable from '../CustomCreatable';

import { X, MapPin, Link, PhoneCall, Users } from 'react-feather';

import './style.css';

export default function EventScheduler(props) {
  const [targetAppointment, setTargetAppointment] = useState(false)
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)

  const [location, setLocation] = useState('')
  const [meetingLink, setMeetingLink] = useState('')
  const [dialIn, setDialIn] = useState('')
  const [participants, setParticipants] = useState([])
  const [participantSuggestions, setParticipantSuggestions] = useState([])

  useEffect(() => {
    if (props.project) {
      // find the meeting request
      for (let i = 0; i < props.project.meetingRequests.length; i++) {
        const mr = props.project.meetingRequests[i]
        if (mr.reason === props.meetingName) {
          setTargetAppointment(mr)
          setLocation(mr.location)
          setMeetingLink(mr.meetingLink)
          setDialIn(mr.dialIn)
          setParticipants(mr.participants)
        }
      }
    }

    // add all project partners to participants
    let participantProposals = [];
    if (props.project.invites && props.project.invites.length) {
      participantProposals = [...participantProposals, ...props.project.invites.map(i => `${i.firstName} ${i.lastName}`)]
    }

    setParticipantSuggestions(participantProposals)
  }, [props.project, props.meetingName, targetAppointment])

  async function addTimes(newSlots) {
    let proposedDates = newSlots.map(s => ({
      date: s,
      approvedByBuyer: props.isSeller ? undefined : true,
      approvedBySeller: props.isSeller ? true : undefined
    }))

    let newMeetingRequests = [];
    let updated = false;
    for (let mr of props.project.meetingRequests) {
      if (mr.reason === targetAppointment.reason) {
        // this is the one we're updating

        const updatedRequest = JSON.parse(JSON.stringify(mr))
        updatedRequest.proposedDates = [...updatedRequest.proposedDates, ...proposedDates]
        newMeetingRequests.push(updatedRequest)
        setTargetAppointment(updatedRequest)

        updated = true
      } else {
        newMeetingRequests.push(mr)
      }
    }

    if (!updated) {
      // no meeting request has been updated ...
      const newRequest = {
        reason: props.meetingName,
        notes: '',
        author: props.user._id,
        proposedDates: proposedDates
      }

      newMeetingRequests.push(newRequest)
      setTargetAppointment(newRequest)
    }

    await props.patchProject({
      meetingRequests: newMeetingRequests
    })

    setShowDatePicker(false)
  }

  function toggleDateApproval(meetingRequest, proposedDate, approvalAttr) {
    const newProposedDates = []
    for (let pd of meetingRequest.proposedDates) {
      if (pd === proposedDate) {
        let newProposedDate = Object.assign(proposedDate)
        newProposedDate[approvalAttr] = !proposedDate[approvalAttr]
        newProposedDates.push(newProposedDate)
      } else {
        newProposedDates.push(pd)
      }
    }

    let newMeetingRequests = [];
    for (let mr of props.project.meetingRequests) {
      if (mr.reason === meetingRequest.reason) {
        let newMeetingRequest = Object.assign(meetingRequest)
        newMeetingRequest.proposedDates = newProposedDates
        newMeetingRequests.push(newMeetingRequest)
      } else {
        newMeetingRequests.push(mr)
      }
    }

    props.patchProject({ meetingRequests: newMeetingRequests })
  }

  async function saveEventMeta(evt) {
    evt.preventDefault()

    let newMeetingRequests = [];
    let updated = false;
    for (let mr of props.project.meetingRequests) {
      if (mr.reason === targetAppointment.reason) {
        // this is the one we're updating

        const updatedRequest = JSON.parse(JSON.stringify(mr))
        updatedRequest.location = location
        updatedRequest.meetingLink = meetingLink
        updatedRequest.dialIn = dialIn
        updatedRequest.participants = participants

        newMeetingRequests.push(updatedRequest)
        setTargetAppointment(updatedRequest)

        updated = true
      } else {
        newMeetingRequests.push(mr)
      }
    }

    if (!updated) {
      // no meeting request has been updated ... create one
      const newRequest = {
        reason: props.meetingName,
        author: props.user._id,
        proposedDates: [],
        location: location,
        meetingLink: meetingLink,
        dialIn: dialIn,
        participants: participants
      }

      newMeetingRequests.push(newRequest)
      setTargetAppointment(newRequest)
    }

    await props.patchProject({
      meetingRequests: newMeetingRequests
    })

    setShowInfoModal(false)
  }

  let scheduledDates;
  if (targetAppointment) {
    let tableRows = []
    // sort dates
    let sortedDates = targetAppointment.proposedDates.sort((a, b) => {
      return new Date(a.date) > new Date(b.date)
    })
    for (let date of sortedDates) {
      function dateApprovalBadge(meetingRequest, proposedDate, approvalAttr, canChange) {
        let badgeInner;
        if (proposedDate[approvalAttr]) {
          badgeInner = <span className={`badge badge-success ${canChange ? 'badge-cursor' : ''}`}>Passt</span>
        } else if (proposedDate[approvalAttr] === undefined) {
          badgeInner = <span className={`badge ${canChange ? 'badge-cursor' : ''}`}>Unklar</span>
        } else {
          badgeInner = <span className={`badge badge-danger ${canChange ? 'badge-cursor' : ''}`}>Passt nicht</span>
        }

        if (canChange) {
          return <span onClick={() => toggleDateApproval(meetingRequest, proposedDate, approvalAttr)}>{badgeInner}</span>
        } else {
          return <span className="dimmed">{badgeInner}</span>
        }
      }

      tableRows.push(
        <tr className={(date.date === targetAppointment.approvedDate) ? 'highlight' : ''}>
          <td>{new Date(date.date).toLocaleString('de-DE', { timeStyle: 'short', dateStyle: 'medium' })} Uhr</td>
          <td>{dateApprovalBadge(targetAppointment, date, 'approvedBySeller', props.isSeller)}</td>
          <td>{dateApprovalBadge(targetAppointment, date, 'approvedByBuyer', !props.isSeller)}</td>
        </tr>
      )
    }

    const isBlurred = props.project.step < 1
    let sellerName;
    if (props.isSeller && !props.user.isAdmin) {
      sellerName = 'du'
    } else if (isBlurred && !props.user.isAdmin) {
      sellerName = <span className="blurred">{props.project.seller.name}</span>
    } else {
      sellerName = props.project.seller.name
    }

    let buyerName;
    if (!props.isSeller && !props.user.isAdmin) {
      buyerName = 'du'
    } else if (isBlurred && !props.user.isAdmin) {
      buyerName = <span className="blurred">{props.project.buyer.name}</span>
    } else {
      buyerName = props.project.buyer.name
    }

    scheduledDates = (
      <table className="DataTable">
        <thead>
          <tr>
            <td>Terminvorschlag</td>
            <td>{sellerName}</td>
            <td>{buyerName}</td>
          </tr>
        </thead>
        <tbody>
          {tableRows}
        </tbody>
      </table>
    )
  } else {
    scheduledDates = (
      <p className="text-muted centered">Keine Terminvorschläge</p>
    )
  }

  let conditionalAppointmentAction;
  if (targetAppointment.approvedDate) {
    conditionalAppointmentAction = (
      <div className="appointment-badge">
        <p className="appointment-badge-headline">{new Date(targetAppointment.approvedDate).toLocaleString('de-DE', { dateStyle: 'long' })}</p>
        <p className="appointment-badge-subline">um {new Date(targetAppointment.approvedDate).toLocaleString('de-DE', { timeStyle: 'short' })} Uhr</p>
      </div>
    )
  } else {
    conditionalAppointmentAction = [
      <button className="ActionCard-action secondary small" onClick={() => setShowDatePicker(true)}>Termin vorschlagen</button>,
      <button className="ActionCard-action secondary small" onClick={() => setShowInfoModal(true)}>Info bearbeiten</button>
    ]
  }

  let conditionalDatePicker;
  if (showDatePicker) {
    conditionalDatePicker = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowDatePicker(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Neuer Terminvorschlag</h3>
              <p className="overlay-intro">
                Je mehr Vorschläge du machen kannst, desto einfacher wird die Abstimmung für deine Partner.
              </p>
            </div>

            <form className="overlay-body">
              <CustomDatePicker onSave={v => addTimes(v)} />
            </form>
          </div>
        </div>
      </div>
    )
  }

  let conditionalInfoModal;
  if (showInfoModal) {
    conditionalInfoModal = (
      <div className="overlay-container">
        <div className="overlay">
          <div className="overlay-inner">
            <X className="overlay-dismiss" onClick={e => setShowInfoModal(false)} />
            <div className="overlay-header">
              <h3 className="overlay-title">Termin anpassen</h3>
              <p className="overlay-intro">
                Lege einen Ort, digitale Teilnahmeoptionen sowie ggf. weitere Teilnehmer für diesen Termin fest.
              </p>
            </div>

            <form className="overlay-body" onSubmit={e => saveEventMeta(e)}>
              <div className="form-field">
                <label>Meeting Link</label>
                <input type="text" value={meetingLink} onChange={e => setMeetingLink(e.target.value)} />
              </div>

              <div className="form-field">
                <label>Dial-In <span className="text-muted">(optional)</span></label>
                <input type="text" value={dialIn} onChange={e => setDialIn(e.target.value)} />
              </div>

              <div className="form-field">
                <label>Adresse <span className="text-muted">(optional)</span></label>
                <input type="text" value={location} onChange={e => setLocation(e.target.value)} />
              </div>

              <div className="form-field">
                <label>Weitere Teilnehmer <span className="text-muted">(optional)</span></label>
                <CustomCreatable
                  isMulti
                  placeholder='externe Berater'
                  classNamePrefix="react-select"
                  options={participantSuggestions.map(p => ({ value: p, label: p }))}
                  value={(participants || []).map(p => ({ value: p, label: p }))}
                  onChange={v => setParticipants(v.map(v => v.value))}
                  />
              </div>

              <button type="submit" className="button primary small full-width">Speichern</button>
            </form>
          </div>
        </div>
      </div>
    )
  }

  let activeLocation;
  if (targetAppointment && targetAppointment.location) {
    activeLocation = (
      <tr>
        <td><MapPin size={18} color='#8185A0' /></td>
        <td>{targetAppointment.location || 'N/A'}</td>
      </tr>
    )
  }

  let activeMeetingLink;
  if (targetAppointment && targetAppointment.meetingLink) {
    activeMeetingLink = (
      <a href={targetAppointment.meetingLink}>{targetAppointment.meetingLink}</a>
    )
  } else {
    activeMeetingLink = 'N/A'
  }

  let activeDialIn;
  if (targetAppointment && targetAppointment.dialIn) {
    activeDialIn = (
      <a href={`tel:${targetAppointment.dialIn}`}>{targetAppointment.dialIn}</a>
    )
  } else {
    activeDialIn = 'N/A'
  }

  let activeParticipants;
  if (targetAppointment && targetAppointment.participants && targetAppointment.participants.length) {
    activeParticipants = (
      <tr>
        <td><Users size={18} color='#8185A0' /></td>
        <td>{targetAppointment.participants.join(', ')}</td>
      </tr>
    )
  }

  return (
    <div className="ProjectCard ActionCard ProjectCard-full">
      <div className="ProjectCard-info">
        <h3 className="ActionCard-title">{props.title}</h3>
        <p className="ActionCard-body">{props.description}</p>

        <table className="DataTable EventStats">
          <tbody>
            {activeLocation}
            <tr>
              <td><Link size={18} color='#8185A0' /></td>
              <td>{activeMeetingLink}</td>
            </tr>
            <tr>
              <td><PhoneCall size={18} color='#8185A0' /></td>
              <td>{activeDialIn}</td>
            </tr>
            {activeParticipants}
          </tbody>
        </table>

        <div className="ActionCard-actions">
          {conditionalAppointmentAction}
        </div>

        {conditionalDatePicker}
        {conditionalInfoModal}
      </div>

      <div className="ProjectCard-surface">
        {scheduledDates}
      </div>
    </div>
  )
}