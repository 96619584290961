import Makro from '../../Makro';

export default function SellerProjectsFeed(props) {
  return [
    <Makro
      actionLabel="Quick Search"
      label="Weitere Partner finden"
      target='/search/buyers'
      />
  ]
}