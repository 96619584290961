import { useEffect, useState, useMemo } from 'react';

import { ChevronDown, CheckCircle, XCircle } from 'react-feather';

export default function AdminActions(props) {
  const [currentStage, setCurrentStage] = useState()
  const [showAdminAction, setShowAdminAction] = useState(false)

  const stageOptions = useMemo(() => [
    'Unverbindlicher Austausch',
    'Informationsaustausch',
    'Due Diligence',
    'Signing & Closing'
  ], [])

  useEffect(() => {
    setCurrentStage(stageOptions.indexOf(props.project.step))
  }, [props.project, stageOptions])

  async function updateCurrentStage(newValue) {
    await props.patchProject({
      step: stageOptions.indexOf(newValue),
      approvedBySeller: false,
      approvedByBuyer: false
    })
    setShowAdminAction(false)
  }

  async function completeTransaction() {
    await props.patchProject({ archived: true })
    setShowAdminAction(false)
  }

  let projectStageOptions = [];
  for (let po of stageOptions) {
    projectStageOptions.push(
      <div
        className={`Dropdown-menu-item ${currentStage === po ? 'active' : ''}`}
        onClick={() => updateCurrentStage(po)}
        >
        {stageOptions.indexOf(po)+1}: {po}
      </div>
    )
  }

  let tableRows = []
  for (let option of props.options) {
    let statusIcon1 = option[1] ? <CheckCircle size={16} color="limegreen" /> : <XCircle size={16} color="tomato" />
    let statusIcon2 = option[2] ? <CheckCircle size={16} color="limegreen" /> : <XCircle size={16} color="tomato" />

    tableRows.push(
      <tr>
        <td>{option[0]}</td>
        <td>{statusIcon1}</td>
        <td>{statusIcon2}</td>
      </tr>
    )
  }

  if (props.user.isAdmin) {
    return (
      <div className="ProjectCard ActionCard ProjectCard-full fade-in-up delay-200">
        <div className="ProjectCard-info">
          <h3 className="ActionCard-title">Fortschritt freigeben</h3>
          <p className="ActionCard-body">
            Wenn alle Bedingungen erfüllt sind, kannst du das Projekt in die nächste Phase übersetzen.
          </p>
          <div className="ActionCard-actions">
            <div className={`Dropdown Dropdown-margin-bottom ${showAdminAction ? 'active' : ''}`}>
              <button className="Dropdown-trigger" onClick={() => setShowAdminAction(!showAdminAction)}>
                <span className="Dropdown-trigger-label">{stageOptions[props.project.step]}</span>
                <ChevronDown size={18} />
              </button>
              <div className={`Dropdown-menu ${showAdminAction ? '' : 'hidden'}`}>
                {projectStageOptions}
              </div>
            </div>

            <button className="button secondary small" onClick={() => completeTransaction()}>
              {props.isFinal ? 'Transaktion abschließen' : 'Transaktion abbrechen'}
            </button>
          </div>
        </div>

        <div className="ProjectCard-surface">
          <table className="DataTable admin-actions">
            <thead>
              <tr>
                <th>Todo</th>
                <th>{props.project.seller.name}</th>
                <th>{props.project.buyer.name}</th>
              </tr>
            </thead>
            <tbody>{tableRows}</tbody>
          </table>
        </div>
      </div>
    )
  } else {
    return <div />
  }
}